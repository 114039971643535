import React from "react";
import { Table, Image, Tag, Typography } from "antd";
import {
    EyeOutlined,
} from '@ant-design/icons';
import moment from "moment-timezone";
import { Link } from "react-router-dom";

const RentalsTable = ({data}) => {
    const buyColumns = [
        {
            title: 'Order Number',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => (
                <span>
                    {moment.utc(createdAt).format('LLL')}
                </span>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => (
                <Tag
                    color={
                        status === "PENDING" 
                        ? "blue" 
                        : (status === "FOR_PAYMENT" || status === "FOR_FULL_PAYMENT")
                        ?  "geekblue"
                        : (status === "PREPARING" || status === "FOR_RETURN")
                        ?  "orange"
                        : (status === "TO_SHIP" || status === "FOR_PICK_UP") 
                        ?  "lime"
                        : status === "OUT_FOR_DELIVERY" 
                        ?  "cyan"
                        : (status === "DELIVERED" || status === "COMPLETED") 
                        ?  "green"
                        : "red"
                    }
                >
                    {status}
                </Tag>
            )
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (id) => (
                <Link type="primary" to={`/orders/rentals/${id}`}><EyeOutlined /> View</Link>
            )
        },
    ]

    return (
        <Table columns={buyColumns} dataSource={data} pagination={false}/>
    )
}

export default RentalsTable