import React, { useEffect } from "react";
import { Image, Divider, Layout, Row, Descriptions, Tag, Button } from "antd";
import { useHistory, useParams } from 'react-router-dom';

import SideNav from "../../../../../components/SideNav";
import Navbar from "../../../../../components/Navbar";

import "./style.scss"
import { inject, observer } from "mobx-react";

const ViewProduct = ({productStore}) => {
    const { id } = useParams();

    const { Content } = Layout;
    const navigation = useHistory()

    useEffect(() => {
        productStore.getProduct(id)
    },[])

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="admin-warehouse-bin-product-view">
                        <Row justify="space-between">
                          <h3>Product Details</h3>
                        </Row>
                        <Divider/>
                        {Object.keys(productStore.product).length > 0 ? (
                            <div className="content-container">
                                <h4>Product</h4>
                                <Descriptions column={2} bordered>
                                    <Descriptions.Item label="Product Image" span={2}>
                                        <Image className="product-image" src={`https://api.alwaysavailabledme.com/${productStore.product.image}`}/>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Product Name" span={1}>{productStore.product.name}</Descriptions.Item>
                                    <Descriptions.Item label="Type" span={1}>
                                        <Tag 
                                            color={
                                            productStore.product.type === "FOR_RENT"
                                            ? "cyan"
                                            : "blue"
                                            }
                                        >{productStore.product.type}</Tag>
                                    </Descriptions.Item>
                                    {productStore.product.variants.length === 0 ? (
                                        <>
                                            <Descriptions.Item label="Price" span={1}>${parseFloat(productStore.product.price).toFixed(2)}</Descriptions.Item>
                                            <Descriptions.Item label="Damage / Lost Fee" span={1}>${parseFloat(productStore.product.damageCharges).toFixed(2)}</Descriptions.Item>
                                        </>
                                    ):<></>}
                                    <Descriptions.Item label="Description" span={2}>{productStore.product.description}</Descriptions.Item>
                                </Descriptions>
                                {productStore.product.variants.length > 0 ? (
                                    <>
                                        <Divider/>
                                        <h4>Variants List</h4>
                                        {productStore.product.variants.map((variant, index) => (
                                            <>
                                                <Descriptions column={2} bordered>
                                                    <Descriptions.Item label="Variant Name" span={2}>{variant.name}</Descriptions.Item>
                                                    {variant.subVariants.length === 0 ? (
                                                        <>
                                                            <Descriptions.Item label="Price" span={1}>${parseFloat(variant.price).toFixed(2)}</Descriptions.Item>
                                                            <Descriptions.Item label="Damage / Lost Fee" span={1}>${parseFloat(variant.damageCharges).toFixed(2)}</Descriptions.Item>
                                                            {productStore.product.type === "FOR_RENT" ? (
                                                                <>
                                                                    <Descriptions.Item label="Total Quantity" span={2}>{variant.variantQuantity || 0}</Descriptions.Item>
                                                                    <Descriptions.Item label="Available" span={1}>{variant.variantAvailableQuantity || 0}</Descriptions.Item>
                                                                    <Descriptions.Item label="Rented" span={1}>{variant.variantRentedQuantity || 0}</Descriptions.Item>
                                                                    <Descriptions.Item label="Total Damaged Products" span={1}>{variant.variantDamagedQuantity || 0}</Descriptions.Item>
                                                                    <Descriptions.Item label="Total Lost Products" span={1}>{variant.variantSoldQuantity || 0}</Descriptions.Item>
                                                                </>
                                                            ): (
                                                                <>
                                                                    <Descriptions.Item label="Total Quantity" span={1}>{variant.variantQuantity}</Descriptions.Item>
                                                                    <Descriptions.Item label="Available" span={1}>{variant.variantAvailableQuantity}</Descriptions.Item>
                                                                    <Descriptions.Item label="Sold" span={1}>{variant.variantSoldQuantity}</Descriptions.Item>
                                                                </>
                                                            )}
                                                        </>
                                                    ):<></>}
                                                </Descriptions>
                                                {index !== (productStore.product.variants.length - 1) ? (
                                                    <Divider/>
                                                ):<></>}

                                                {variant.subVariants.length > 0 ? (
                                                    <>
                                                        <Divider/>
                                                        <h4>Sub Variants List</h4>
                                                        {variant.subVariants.map((subVariant, index) => (
                                                            <>
                                                                <Descriptions column={2} bordered>
                                                                    <Descriptions.Item label="Variant Name" span={2}>{subVariant.name}</Descriptions.Item>
                                                                    <Descriptions.Item label="Price" span={1}>${parseFloat(subVariant.price).toFixed(2)}</Descriptions.Item>
                                                                    <Descriptions.Item label="Damage / Lost Fee" span={1}>${parseFloat(subVariant.damageCharges).toFixed(2)}</Descriptions.Item>
                                                                    {productStore.product.type === "FOR_RENT" ? (
                                                                        <>
                                                                            <Descriptions.Item label="Total Quantity" span={2}>{subVariant.subVariantQuantity || 0}</Descriptions.Item>
                                                                            <Descriptions.Item label="Available" span={1}>{subVariant.subVariantAvailableQuantity || 0}</Descriptions.Item>
                                                                            <Descriptions.Item label="Rented" span={1}>{subVariant.subVariantRentedQuantity || 0}</Descriptions.Item>
                                                                            <Descriptions.Item label="Total Damaged Products" span={1}>{subVariant.subVariantDamagedQuantity || 0}</Descriptions.Item>
                                                                            <Descriptions.Item label="Total Lost Products" span={1}>{subVariant.subVariantSoldQuantity || 0}</Descriptions.Item>
                                                                        </>
                                                                    ): (
                                                                        <>
                                                                            <Descriptions.Item label="Total Quantity" span={1}>{subVariant.subVariantQuantity}</Descriptions.Item>
                                                                            <Descriptions.Item label="Available" span={1}>{subVariant.subVariantAvailableQuantity}</Descriptions.Item>
                                                                            <Descriptions.Item label="Sold" span={1}>{subVariant.subVariantSoldQuantity}</Descriptions.Item>
                                                                        </>
                                                                    )}
                                                                </Descriptions>
                                                                {index !== (variant.subVariants.length - 1) ? (
                                                                    <Divider/>
                                                                ):<></>}
                                                            </>
                                                        ))}
                                                        {index !== (productStore.product.variants.length - 1) ? (
                                                            <Divider/>
                                                        ):<></>}
                                                        
                                                    </>
                                                ):<></>}
                                            </>
                                        ))}
                                        
                                    </>
                                ):(
                                    <>
                                        <Divider/>
                                        <h4>Quantity</h4>
                                        {productStore.product.type === "FOR_RENT" ? (
                                            <Descriptions column={2} bordered>
                                                <Descriptions.Item label="Total Quantity" span={2}>{productStore.product.quantity || 0}</Descriptions.Item>
                                                <Descriptions.Item label="Available" span={1}>{productStore.product.availableQuantity || 0}</Descriptions.Item>
                                                <Descriptions.Item label="Rented" span={1}>{productStore.product.rentedQuantity || 0}</Descriptions.Item>
                                                <Descriptions.Item label="Total Damaged Products" span={1}>{productStore.product.damagedQuantity || 0}</Descriptions.Item>
                                                <Descriptions.Item label="Total Lost Products" span={1}>{productStore.product.soldQuantity || 0}</Descriptions.Item>
                                            </Descriptions>
                                        ): (
                                            <Descriptions column={3} bordered>
                                                <Descriptions.Item label="Total Quantity" span={1}>{productStore.product.quantity}</Descriptions.Item>
                                                <Descriptions.Item label="Available" span={1}>{productStore.product.availableQuantity}</Descriptions.Item>
                                                <Descriptions.Item label="Sold" span={1}>{productStore.product.soldQuantity}</Descriptions.Item>
                                            </Descriptions>
                                        )}
                                    </>
                                )}
                            </div>
                        ):<></>}
                        <Divider/>
                        <Row justify="end">
                            <Button onClick={() => navigation.goBack()}>Back</Button>
                        </Row>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject("productStore")(observer(ViewProduct));