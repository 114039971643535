import { observable, action } from "mobx";
import axios from "axios";

class CartStore {
  @observable cartProductsRent = [];
  @observable cartProductsBuy = [];
  @observable cartProduct = {};

  @observable loading = false;
  @observable isSuccess = false;

  @observable onError = false;
  @observable onErrorMess = "";

  @action
  createCart = (values) => {
    this.loading = true;

    axios
      .post(`/cart/create.php`, values)
      .then((response) => {
        if (response.status === 200) {
            this.isSuccess = true;
        }
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        this.loading = false;
        // this.onError = false;
      });
  };

  @action
  getCartProductsByType = (userID, type) => {
    this.loading = true;

    axios
      .get(`/cart/get_all.php?userID=${userID}&type=${type}`)
      .then((response) => {
        if (response.status === 200) {
            if(type === "RENT") {
                this.cartProductsRent = response.data.data;
            } else if(type === "BUY") {
                this.cartProductsBuy = response.data.data;
            }
        }
      })
      .catch((error) => {
        console.log(error.response.data);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };

  @action
  deleteCart = (cartID) => {
    this.loading = true;

    axios
      .delete(`/cart/delete.php?id=${cartID}`)
      .then((response) => {
          if(response.status === 200) {
              this.isSuccess = true;
          }
      })
      .catch((error) => {
          console.log(error.response.data);
      })
      .finally(() => {
          this.loading = false;
          this.onError = false;
      });
  };
}

export default CartStore;
