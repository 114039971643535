import React, { useEffect } from "react";
import { Tabs, Divider, Layout, Tag, Table } from "antd";
import { Link } from "react-router-dom";
import {
    EyeOutlined,
} from '@ant-design/icons';
import moment from "moment-timezone";

import SideNav from "../../../components/SideNav";
import Navbar from "../../../components/Navbar";

import "./style.scss"

import { inject, observer } from "mobx-react";
import { cloneDeep } from 'lodash';

const Payments = ({paymentStore}) => {
    const { Content } = Layout;
    const { TabPane } = Tabs;

    useEffect(() => {
        paymentStore.getPayments("PENDING")
    },[])

    const buyColumns = [
        {
            title: 'Order Number',
            dataIndex: 'order',
            key: 'orderNumber',
            render: (order) => (
                <span>{order.orderNumber}</span>
            )
        },
        {
            title: 'Customer ID',
            dataIndex: 'customer',
            key: 'idNumber',
            render: (customer) => (
                <span>{customer.idNumber}</span>
            )
        },
        {
            title: 'Customer Name',
            dataIndex: 'customer',
            key: 'name',
            render: (customer) => (
                <span>{`${customer.firstName} ${customer.middleName || ""} ${customer.lastName} ${customer.suffix || ""}`}</span>
            )
        },
        {
            title: 'Total Order Payment',
            dataIndex: 'totalOrderPayment',
            key: 'totalOrderPayment',
            render: (totalOrderPayment) => (
                <span>${parseFloat(totalOrderPayment).toFixed(2)}</span>
            )
        },
        {
            title: 'Type',
            dataIndex: 'order',
            key: 'type',
            render: (order) => (
                <Tag
                    color={
                        order.type === "BUY"
                        ? "#108ee9"
                        : "#08979c"
                    }
                >
                    {order.type === "BUY" ? "PURCHASED" : "RENT"}
                </Tag>
            )
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => (
                <span>{moment.utc(createdAt).format("LL")}</span>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => (
                <Tag 
                    color={
                        status === "PENDING"
                        ? "blue"
                        : status === "INITIAL_PAYMENT"
                        ? "lime"
                        : "green"
                }>{status}</Tag>
            )
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (id) => (
                <Link type="primary" to={`/admin/payments/${id}`}><EyeOutlined /> View</Link>
            )
        },
    ]

    const tabs = [
        {
            tab: "Pending",
            key: "PENDING"
        },
        {
            tab: "Partial Paid",
            key: "INITIAL_PAYMENT"
        },
        {
            tab: "For Full Payment",
            key: "FOR_FULL_PAYMENT"
        },
        {
            tab: "Paid",
            key: "PAID"
        },
        
    ];

    const onChangeTabs = (key) => {
        paymentStore.getPayments(key)
    }

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="admin-payments">
                        <h3>Payments</h3>
                        <Divider/>
                        <Tabs
                            defaultActiveKey="ALL"
                            type="card"
                            onChange={onChangeTabs}
                        >
                            {tabs.map((tab) => (
                                <TabPane tab={tab.tab} key={tab.key}>
                                    <Table columns={buyColumns} dataSource={cloneDeep(paymentStore.payments)} pagination={false}/>
                                </TabPane>
                            ))}
                        </Tabs>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject("paymentStore")(observer(Payments));