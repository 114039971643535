import React from "react";

//NEW
import Banner1 from "../../assets/img/backgroundhome/banner-img.jpg"
import LogoMed from "../../assets/img/backgroundhome/logo-med.png"

import tradeanal from "../../assets/img/homeimagemission.jpg";

import med1 from "../../assets/img/medequipments/med1.jpg";
import med2 from "../../assets/img/medequipments/med2.jpg";
import med3 from "../../assets/img/medequipments/med3.jpg";
import med4 from "../../assets/img/medequipments/med4.jpg";
import med5 from "../../assets/img/medequipments/med5.jpg";
import med6 from "../../assets/img/medequipments/med6.jpg";

import NavbarHome from "../../components/NavbarHome";
import Particles from "react-tsparticles";

import { Row, Col, Tag } from "antd";
import {
  ShoppingCartOutlined,
  TagsOutlined,
  BarChartOutlined,
  CarOutlined,
  ShopOutlined,
  DropboxOutlined
} from "@ant-design/icons";
import "./style.scss";

const Home = () => {
  return (
    <div className="home">
      <NavbarHome />
      <div className="home-section-1">
        <div className='background-overlay'></div>
        <div className="home-intro">
          <div className="blueback">
            <img src={LogoMed} alt="home-logo" />
            <p className="sub-title">
              Discover a wide range of durable medical equipment designed to support your daily activities and enhance your quality of life. From mobility aids to home healthcare essentials, we have everything you need to maintain your independence and stay confident in your abilities.
            </p>
          </div>
        </div>
        <Particles
          id="tsparticles"
          options={{
            background: {
              color: {
                value: "#fffffff",
              },
            },
            fpsLimit: 200,
            interactivity: {
              events: {
                onClick: {
                  enable: false,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                bubble: {
                  distance: 600,
                  duration: 1,
                  opacity: 0.6,
                  size: 2,
                },
                push: {
                  quantity: 2,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: "#ffffff",
              },
              links: {
                color: "#f1f1f1",
                distance: 300,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              collisions: {
                enable: false,
              },
              move: {
                direction: "none",
                enable: true,
                outMode: "bounce",
                random: true,
                speed: 2,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  value_area: 800,
                },
                value: 20,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: "polygon",
              },
              size: {
                random: true,
                value: 2,
              },
            },
            detectRetina: true,
          }}
        />
      </div>
      <div className="home-section-2" id={"platform"}>
        <div className="container">
          <div className="main-content">
            <div className="left">
              <img src={Banner1} alt="trade-left" />
            </div>
            <div className="content">
              <p className="title">Our <span>Vision</span></p>
              <p className="sub-title">
                To become industry leaders in the quality of support we provide our patients' essential needs and their individualized continuity of care.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='home-section-3'>
        <p className='home-section-3-title'>Order Online</p>
        <Row gutter={25} justify="center">
          <Col xs={{flex: "auto", span: 24}} xl={5}>
            <div className='card card-colored'>
              <ShoppingCartOutlined />
              <h3>Seamless Ordering Experience</h3>
              <p>Easily navigate through our extensive catalog and find the durable medical equipment you need in just few clicks.</p>
            </div>
          </Col>
          <Col xs={{flex: "auto", span: 24}} xl={5}>
            <div className='card'>
              <TagsOutlined />
              <h3>Detailed Product Information</h3>
              <p>Make informed decisions with comprehensive product descriptions, specifications, and images.</p>
            </div>
          </Col>
          <Col xs={{flex: "auto", span: 24}} xl={5}>
            <div className='card'>
              <CarOutlined />
              <h3>Fast and Reliable Shipping</h3>
              <p>With our diligent efforts, you can start benefiting from your equipment without any unnecessary delays.</p>
            </div>
          </Col>
        </Row>
      </div>
      <div className="home-section-3-mission" id={"vision"}>
        <div className="container">
          <div className="main-content">
            <div className="left">
              <img src={tradeanal} alt="trade-section-3" />
            </div>
            <div className="content">
              <p className="title">Our <span>Mission</span></p>
              <p className="sub-title">
                To create an accessible and informative one-stop-shop for all your medical needs, with a priority on delivering top-tier service to those transitioning from a medical care setting.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="home-section-new-1" id={"mission"}>
        <div className="container">
          <div className="main-content">
            <div className="main-title">
              <p>Order Processing Steps</p>
            </div>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <div className="new-section-data">
                  <div className="icon">
                    <ShopOutlined />
                  </div>
                  <p className="title">
                    <Tag color="cyan">1.</Tag>Order Placement
                  </p>
                  <p className="sub">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <div className="new-section-data">
                  <div className="icon">
                    <BarChartOutlined />
                  </div>
                  <p className="title">
                    <Tag color="yellow">2.</Tag>Sorting
                  </p>
                  <p className="sub">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <div className="new-section-data">
                  <div className="icon">
                    <DropboxOutlined />
                  </div>
                  <p className="title">
                    <Tag color="purple">3.</Tag>Packing
                  </p>
                  <p className="sub">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <div className="new-section-data">
                  <div className="icon">
                    <CarOutlined />
                  </div>
                  <p className="title">
                    <Tag color="green">4.</Tag>Shipping
                  </p>
                  <p className="sub">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="home-section-new-2" id={"mission"}>
        <div className='background-overlay' />
        <div className="container">
          <div className="main-content">
            <div className="main-title">
              <p className="title"><span>Self Protection Kit</span> for your own safety.</p>
              <p className="sub">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div className="new-section-data">
                  <div className="icon-img">
                    <img src={med1} alt="med1" />
                  </div>
                  <p className="title">Gloves</p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div className="new-section-data">
                  <div className="icon-img">
                    <img src={med2} alt="med2" />
                  </div>
                  <p className="title">Sphygmomanometer</p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div className="new-section-data">
                  <div className="icon-img">
                    <img src={med3} alt="med3" />
                  </div>
                  <p className="title">Digital Stethoscope</p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div className="new-section-data">
                  <div className="icon-img">
                    <img src={med4} alt="med4" />
                  </div>
                  <p className="title">Thermometer</p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div className="new-section-data">
                  <div className="icon-img">
                    <img src={med5} alt="med5" />
                  </div>
                  <p className="title">Non-contact Thermometer</p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div className="new-section-data">
                  <div className="icon-img">
                    <img src={med6} alt="med6" />
                  </div>
                  <p className="title">Glass Mask</p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <div className="home-section-new-5" id={"#about"}>
        <div className="container">
          <div className="main-content">
            <div className="main-title">
              <p className="title">About the Company</p>
              {/* <p className="sub">
                Reaching our goals with High Standard of Commitment and Unique
                Perspective.
              </p> */}
            </div>
            <div className="content">
              <div className="new-section-data">
                <p className="sub">
                  Durable Medical Equipment RENT OR PURCHASE to deliver excellence in every service that we offer.
                </p>
                <p className="sub">
                  Our technicians travel day and night across the Bay Area delivering medical equipment that is essential to provide comfort for our clients. We are a proud partner to many healthcare providers who have entrusted and incorporated our services as part of their patient plan of care.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer>
        <p>© 2023 DME. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default Home;
