import React from "react";

import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";

import ProtectedRoutes from "../config/ProtectedRoutes";

import Home from "./Home";
import Login from "./Login";
import Register from "./Register";
import MemberDashboard from "./Member/MemberDashboard";
import MemberProductDetails from "./Member/MemberProductDetails";
import MemberCart from "./Member/MemberCart";
import MemberOrdersPurchased from "./Member/MemberOrdersPurchased";
import MemberOrdersRentals from "./Member/MemberOrdersRentals";
import ViewRentalDetails from "./Member/MemberOrdersRentals/ViewRentalDetails";
import Users from "./Admin/Users";
import Employees from "./Admin/Employees";
import WarehouseBins from "./Admin/WarehouseBins";
import WarehouseBinsProducts from "./Admin/WarehouseBins/Products";
import Stocks from "./Admin/WarehouseBins/Products/Stocks";
import ViewProduct from "./Admin/WarehouseBins/Products/ViewProduct";
import ViewUsers from "./Admin/Users/ViewUsers";
import ViewEmployee from "./Admin/Employees/ViewEmployee";
import AddEmployee from "./Admin/Employees/AddEmployee";
import AddWarehouseBin from "./Admin/WarehouseBins/AddWarehouseBin";
import AddProduct from "./Admin/WarehouseBins/Products/AddProduct";
import OrdersPurchased from "./Admin/Orders/OrdersPurchased";
import OrdersRentals from "./Admin/Orders/OrdersRentals";
import OngoingRentals from "./Admin/OngoingRentals";
import Payments from "./Admin/Payments";
import EditEmployee from "./Admin/Employees/EditEmployee";
import EditWarehouseBin from "./Admin/WarehouseBins/EditWarehouseBin";
import EditProduct from "./Admin/WarehouseBins/Products/EditProduct";
import WarehouseBinStocks from "./Admin/WarehouseBins/WarehouseBinStocks";
import ViewPurchasedDetails from "./Admin/Orders/OrdersPurchased/ViewPurchasedDetails";
import ViewPaymentDetails from "./Admin/Payments/ViewPaymentDetails";
import ForDelivery from "./Admin/ForDelivery";
import ViewForDelivery from "./Admin/ForDelivery/ViewForDelivery";
import ViewRentalDetailsAdmin from "./Admin/Orders/OrdersRentals/ViewRentalDetails";
import ViewOngoingRentalDetails from "./Admin/OngoingRentals/ViewOngoingRentalDetails";
import ForPickUp from "./Admin/ForPickUp";
import ViewForPickUp from "./Admin/ForPickUp/ViewForPickUp";
import ViewMemberPurchasedDetails from "./Member/MemberOrdersPurchased/ViewMemberPurchasedDetails";
import Reports from "./Admin/Reports";
import StocksVariant from "./Admin/WarehouseBins/Products/StocksVariant";
import StocksSubVariant from "./Admin/WarehouseBins/Products/StocksSubVariant";
import MemberBulkRentalOrder from "./Member/MemberBulkRentalOrder";
import MemberBulkPurchaseOrder from "./Member/MemberBulkPurchaseOrder";

// import ForgotPassword from './ForgotPassword'

//Member Components

export default function Pages() {
  const login = JSON.parse(localStorage.getItem("userStore"))
  const userType = login ? login.userType : "";
  const isLoggedIn = login ? login.loginStatus : false

  return (
    <Router>
      <>
        <Switch>
          {/* PUBLIC */}
          {isLoggedIn ? (
            <>
              {userType === "CUSTOMER" ? (
                <>
                  <Route exact path={"/dashboard"} component={MemberDashboard} />
                  <Route exact path={"/product-description/:id"} component={MemberProductDetails} />
                  <Route exact path={"/cart"} component={MemberCart} />
                  <Route exact path={"/bulk-order-rental"} component={MemberBulkRentalOrder} />
                  <Route exact path={"/bulk-order-purchased"} component={MemberBulkPurchaseOrder} />
                  <Route exact path={"/orders/purchased"} component={MemberOrdersPurchased} />
                  <Route exact path={"/orders/purchased/:id"} component={ViewMemberPurchasedDetails} />
                  <Route exact path={"/orders/rentals"} component={MemberOrdersRentals} />
                  <Route exact path={"/orders/rentals/:id"} component={ViewRentalDetails} />
                </>
              ):(
                userType === "SUPERADMIN" ? (
                  <>
                    <Route exact path={"/admin/users"} component={Users} />
                    <Route exact path={"/admin/users/:id"} component={ViewUsers} />
                    <Route exact path={"/admin/employees"} component={Employees} />
                    <Route exact path={"/admin/employees/add"} component={AddEmployee} />
                    <Route exact path={"/admin/employees/:id/edit"} component={EditEmployee} />
                    <Route exact path={"/admin/employees/:id"} component={ViewEmployee} />
                    <Route exact path={"/admin/warehouse-bins"} component={WarehouseBins} />
                    <Route exact path={"/admin/warehouse-bins/add"} component={AddWarehouseBin} />
                    <Route exact path={"/admin/warehouse-bins/:id/edit"} component={EditWarehouseBin} />
                    <Route exact path={"/admin/warehouse-bins/:id/stocks"} component={WarehouseBinStocks} />
                    <Route exact path={"/admin/products"} component={WarehouseBinsProducts} />
                    <Route exact path={"/admin/products/add"} component={AddProduct} />
                    <Route exact path={"/admin/products/:id/stocks"} component={Stocks} />
                    <Route exact path={"/admin/products/:id/stocks-variant/:variantID/sub-variant/:subVariantID"} component={StocksSubVariant} />
                    <Route exact path={"/admin/products/:id/stocks-variant/:variantID"} component={StocksVariant} />
                    <Route exact path={"/admin/products/:id/edit"} component={EditProduct} />
                    <Route exact path={"/admin/products/:id"} component={ViewProduct} />
                    <Route exact path={"/admin/orders/purchased"} component={OrdersPurchased} />
                    <Route exact path={"/admin/orders/purchased/:id"} component={ViewPurchasedDetails} />
                    <Route exact path={"/admin/orders/rentals"} component={OrdersRentals} />
                    <Route exact path={"/admin/orders/rentals/:id"} component={ViewRentalDetailsAdmin} />
                    <Route exact path={"/admin/ongoing-rentals"} component={OngoingRentals} />
                    <Route exact path={"/admin/ongoing-rentals/:id"} component={ViewOngoingRentalDetails} />
                    <Route exact path={"/admin/payments"} component={Payments} />
                    <Route exact path={"/admin/payments/:id"} component={ViewPaymentDetails} />
                    <Route exact path={"/admin/for-delivery"} component={ForDelivery} />
                    <Route exact path={"/admin/for-delivery/:id"} component={ViewForDelivery} />
                    <Route exact path={"/admin/for-pickup"} component={ForPickUp} />
                    <Route exact path={"/admin/for-pickup/:id"} component={ViewForPickUp} />
                    <Route exact path={"/admin/reports"} component={Reports} />
                  </>
                ): (
                  (userType === "WAREHOUSE_ADMIN" | userType === "WAREHOUSE_STAFF") ? (
                    <>
                      <Route exact path={"/admin/warehouse-bins"} component={WarehouseBins} />
                      <Route exact path={"/admin/warehouse-bins/add"} component={AddWarehouseBin} />
                      <Route exact path={"/admin/warehouse-bins/:id/edit"} component={EditWarehouseBin} />
                      <Route exact path={"/admin/warehouse-bins/:id/stocks"} component={WarehouseBinStocks} />
                      <Route exact path={"/admin/products"} component={WarehouseBinsProducts} />
                      <Route exact path={"/admin/products/add"} component={AddProduct} />
                      <Route exact path={"/admin/products/:id/stocks"} component={Stocks} />
                      <Route exact path={"/admin/products/:id/stocks-variant/:variantID"} component={Stocks} />
                      <Route exact path={"/admin/products/:id/edit"} component={EditProduct} />
                      <Route exact path={"/admin/products/:id"} component={ViewProduct} />
                      <Route exact path={"/admin/orders/purchased"} component={OrdersPurchased} />
                      <Route exact path={"/admin/orders/purchased/:id"} component={ViewPurchasedDetails} />
                      <Route exact path={"/admin/orders/rentals"} component={OrdersRentals} />
                      <Route exact path={"/admin/orders/rentals/:id"} component={ViewRentalDetailsAdmin} />
                      <Route exact path={"/admin/ongoing-rentals"} component={OngoingRentals} />
                      <Route exact path={"/admin/ongoing-rentals/:id"} component={ViewOngoingRentalDetails} />
                    </>
                  ): userType === "ACCOUNTING_ADMIN" ? (
                    <>
                      <Route exact path={"/admin/payments"} component={Payments} />
                      <Route exact path={"/admin/payments/:id"} component={ViewPaymentDetails} />
                    </>
                  ) : userType === "DRIVER" ? (
                    <>
                      <Route exact path={"/admin/for-delivery"} component={ForDelivery} />
                      <Route exact path={"/admin/for-delivery/:id"} component={ViewForDelivery} />
                      <Route exact path={"/admin/for-pickup"} component={ForPickUp} />
                      <Route exact path={"/admin/for-pickup/:id"} component={ViewForPickUp} />
                    </>
                  ):<></>
                )
              )}
            </>
          ): (
            <>
              <Route exact path={"/"} component={Home} />
              <Route exact path={"/login"} component={Login} />
              <Route exact path={"/about"} component={Home} />
              <Route exact path={"/register"} component={Register} />
            </>
          )}
          {/* <ProtectedRoutes
            exact
            path={"/dashboard"}
            component={MemberDashboard}
          /> */}
          {/* PUBLIC */}
        </Switch>
      </>
    </Router>
  );
}
