import { observable, action } from "mobx";
import axios from "axios";

class StockStore {
  @observable stocks = [];
  @observable stock = {};

  @observable loading = false;
  @observable isSuccess = false;

  @observable onError = false;
  @observable onErrorMess = "";

  @action
  createStock = (values) => {
    this.loading = true;

    axios
      .post(`/stocks/create.php`, values)
      .then((response) => {
        if (response.status === 200) {
            this.isSuccess = true;
        }
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        this.loading = false;
        // this.onError = false;
      });
  };

  @action
  getStocks = () => {
    this.loading = true;

    axios
      .get(`/stocks/get_all.php`)
      .then((response) => {
        if (response.status === 200) {
          this.stocks = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.data);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };
  
  @action
  getStocksByProductID = (productID) => {
    this.loading = true;

    axios
      .get(`/stocks/get_all.php?productID=${productID}`)
      .then((response) => {
        if (response.status === 200) {
          this.stocks = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.data);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };

  @action
  getStocksVariantByProductID = (productID, productVariantID) => {
    this.loading = true;

    axios
      .get(`/stocks/get_all.php?productID=${productID}&productVariantID=${productVariantID}`)
      .then((response) => {
        if (response.status === 200) {
          this.stocks = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.data);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };

  @action
  getStocksSubVariantByProductID = (productID, productVariantID, productSubVariantID) => {
    this.loading = true;

    axios
      .get(`/stocks/get_all.php?productID=${productID}&productVariantID=${productVariantID}&productSubVariantID=${productSubVariantID}`)
      .then((response) => {
        if (response.status === 200) {
          this.stocks = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.data);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };

  @action
  getStocksByWarehouseBinID = (warehouseBinID) => {
    this.loading = true;

    axios
      .get(`/stocks/get_all.php?warehouseBinID=${warehouseBinID}`)
      .then((response) => {
        if (response.status === 200) {
          this.stocks = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.data);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };

  @action
  getStock = (stockID) => {
    this.loading = true;

    axios
      .get(`/stocks/get.php?id=${stockID}`)
      .then((response) => {
        if (response.status === 200) {
          this.stock = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };

  @action
  updateStock = (stockID, data) => {
    this.loading = true;

    axios
      .patch(`/stocks/update.php?id=${stockID}`, data)
      .then((response) => {
          if(response.status === 201) {
              this.isSuccess = true;
          }
      })
      .catch((error) => {
          console.log(error.response.data);
          this.onError = true;
          this.onErrorMess = error.response.data.message
      })
      .finally(() => {
          this.loading = false;
          this.onError = false;
      });
  };

  @action
  deleteStock = (stockID) => {
    this.loading = true;

    axios
      .delete(`/stocks/delete.php?id=${stockID}`)
      .then((response) => {
          if(response.status === 200) {
              this.isSuccess = true;
          }
      })
      .catch((error) => {
          console.log(error.response.data);
      })
      .finally(() => {
          this.loading = false;
          this.onError = false;
      });
  };
}

export default StockStore;
