import React, { useEffect } from "react";
import { Divider, Layout, Row, Col, Button, Form, Input, notification } from "antd";
import { useHistory, useParams } from 'react-router-dom';

import SideNav from "../../../../components/SideNav";
import Navbar from "../../../../components/Navbar";

import "./style.scss"
import { inject, observer } from "mobx-react";

const EditWarehouseBin = ({warehouseBinStore}) => {
    const {id} = useParams()

    const { Content } = Layout;
    const { TextArea } = Input;
    const navigation = useHistory()

    const [form] = Form.useForm();

    useEffect(() => {
        warehouseBinStore.isSuccess = false;
        warehouseBinStore.onError = false;
        warehouseBinStore.onErrorMess = "";
        warehouseBinStore.getWarehouseBin(id)
    },[])

    useEffect(() => {
        if(Object.keys(warehouseBinStore.warehouseBin).length > 0) {
            form.setFieldsValue(warehouseBinStore.warehouseBin)
        }
    },[warehouseBinStore.warehouseBin])

    useEffect(() => {
        if(warehouseBinStore.isSuccess){
            notification.success({
                top: 120,
                message: 'Warehouse Bin created successfully!',
            });

            setTimeout(() => {
                navigation.goBack()
            }, 1000)
        }
    }, [warehouseBinStore.isSuccess]);

    useEffect(() => {
        if(warehouseBinStore.onError){
            notification.error({
                top: 120,
                message: 'Create Warehouse Bin Error',
                description: warehouseBinStore.onErrorMess
            });
        }
    }, [warehouseBinStore.onError]);

    const onFinish = (values) => {
        warehouseBinStore.updateWarehouseBin(id, values)
    }

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="admin-warehouse-bins-add">
                        <Row justify="space-between">
                          <h3>Add New Bin</h3>
                        </Row>
                        <Divider/>
                        <div className="content-container">
                            <Form
                                form={form}
                                onFinish={onFinish}
                                layout="vertical"
                            >
                                <Form.Item label="Bin Name" name="name" rules={[{ required: true, message: 'Please input Bin Name!' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Please input Description!' }]}>
                                    <TextArea rows={2}/>
                                </Form.Item>
                            </Form>
                        </div>
                        <Divider/>
                        <Row justify="end" gutter={15}>
                            <Col>
                                <Button type="primary" htmlType="submit" onClick={() => form.submit()}>Update</Button>
                            </Col>
                            <Col>
                                <Button type="default" onClick={() => navigation.goBack()}>Back</Button>
                            </Col>
                        </Row>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject("warehouseBinStore")(observer(EditWarehouseBin));