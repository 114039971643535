import React, { useEffect, useState } from "react";
import { Table, Divider, Layout, Row, Button, Tag, Modal, Form, InputNumber, Alert, Space, Typography, Select, notification } from "antd";
import {
  PlusOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { QRCode } from 'react-qrcode-logo';
import html2canvas from 'html2canvas';
import moment from 'moment-timezone';

import SideNav from "../../../../components/SideNav";
import Navbar from "../../../../components/Navbar";

import LOGO from "../../../../assets/img/backgroundhome/logo-med-1.png"

import "./style.scss"

import { inject, observer } from "mobx-react";
import { cloneDeep } from 'lodash';

const WarehouseBinStocks = ({stockStore, warehouseBinStore}) => {
    const {id} = useParams();
    const { Content } = Layout;
    const navigation = useHistory();

    const [form] = Form.useForm();

    const [isModalOpen, setIsModalOpen] = useState(false)

    useEffect(() => {
      stockStore.getStocksByWarehouseBinID(id)
    },[])

    const downloadQRCode = async(recordID) => {
      const divElement = document.getElementById('qrCodeEl' + recordID);
      divElement.style.display = 'flex';
      divElement.style.flexDirection = 'column';
      divElement.style.alignItems = 'center';

      try {
        const canvas = await html2canvas(divElement);
        const qrCodeURL = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');

        const aEl = document.createElement('a');
        aEl.href = qrCodeURL;
        aEl.download = 'QR_Code.png';
        document.body.appendChild(aEl);
        aEl.click();
        document.body.removeChild(aEl);

        divElement.style.display = 'none';

      } catch (error) {
        console.error('Error generating QR code:', error);
      }
    }

    const columns = [
      {
        title: 'Serial Code',
        dataIndex: 'serialCode',
        key: 'serialCode',
      },
      {
        title: 'Warehouse Bin',
        dataIndex: 'warehouseBin',
        key: 'warehouseBin',
        render: (warehouseBin) => (
          <span>{warehouseBin.name}</span>
        )
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => (
          <Tag
            color={
              status === "AVAILABLE"
              ? "green"
              : status === "RENTED"
              ? "magenta"
              : status === "DAMAGED"
              ? "volcano"
              : status === "SOLD"
              ? "gold"
              : "red"

            }>{status}</Tag>
        )
      },
      {
        title: 'Inventory Type',
        dataIndex: 'inventoryType',
        key: 'inventoryType',
        render: (inventoryType) => (
          <Tag
            color={
              inventoryType === "IN"
              ? "lime"
              : "red"

            }>{inventoryType}</Tag>
        )
      },
      {
        title: 'Date of Receipt',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt) => (
          <span>{moment.utc(createdAt).format('LLL')}</span>
        )
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (id, record) => (
            <Space size={'middle'}>
              <div id={`qrCodeEl${id}`} className="qr-container" style={{display: 'none'}}>
                <QRCode
                  value={id}
                  logoImage={LOGO}
                  logoWidth={35}
                  logoHeight={50}
                  logoOpacity={1}
                  logoPadding={0}
                />
                <span>{record.serialCode}</span>
              </div>
              
              <Typography.Link onClick={() => downloadQRCode(id)}><DownloadOutlined /> Download QR</Typography.Link>
            </Space>
        )
      },
    ];

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="admin-warehouse-bin-product-serial-code-list">
                        <Row justify="space-between">
                          <h3>Warehouse Bin Stock List</h3>
                        </Row>
                        <Divider/>
                        <Table dataSource={cloneDeep(stockStore.stocks)} columns={columns} />
                        <Divider/>
                        <Row justify="end">
                          <Button onClick={() => navigation.goBack()}>Back</Button>
                        </Row>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject("stockStore","warehouseBinStore")(observer(WarehouseBinStocks));