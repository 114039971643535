import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
    page: {
        backgroundColor: '#ffffff',
        padding: "50px 0",
        position: 'relative',
        zIndex: 1,
        width:'100%',
        height:'100%',
        fontFamily: "Poppins"
    },
    watermark: {
        position: 'absolute',
        top: '40%',
        left: 35,
        width: '110%',
        height: 'auto',
        opacity: 0.8,
        transform: 'rotate(-20deg)',
        zIndex: 'auto'
    },
    attachments: {
        position: 'absolute',
        top: '40%',
        left: 35,
        width: '100%',
        height: 'auto',
        zIndex: 99999,
    },
    headerContainer: {
        marginLeft: 15
        // display: 'flex',
        // flexDirection: 'row',
        // alignItems: 'flex-start',
        // marginBottom: 30,
        // justifyContent: 'space-between'
    },
    headerLogoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    logo: {
        width: 70,
        height: 50,
    },
    textHeader: {
        fontSize: 14,
        marginLeft: 15,
        fontFamily: "Poppins",
        fontWeight: 800
    },
    body: {
        fontSize: 11,
        fontWeight: 100,
        padding: "0 30px"
    },
    text: {
        fontSize: 10,
        fontWeight: 100,
        marginBottom: 10,
        lineHeight: 2
    },
    textBold: {
        fontWeight: 800,
    },
    image: {
        width: 100,
        height: 100,
        marginBottom: 10
    },
    space: {
        marginTop: 30,
    },
    table: {
        display: 'table',
        width: 'auto',
        marginTop: 10,
        borderStyle: 'solid',
    },
    tableHeader: {
        flexDirection: 'row',
        backgroundColor: '#548ca3',
    },
    tableHeaderCell: {
        margin: 5,
        fontSize: 10,
        fontWeight: 'bold',
        padding: 3,
        flex: 1,
        color: "white"
    },
    tableRow: {
        display: "flex",
        flexDirection: "row",
        borderBottom: "0.1px solid #7393B3"
    },
    tableCell: {
        margin: 5,
        fontSize: 8,
        padding: 5,
        flex: 1
    },
    divider: {
        borderBottom: '1.5px solid #000',
        margin: '10px 0'
    },
    agreementHeader:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent:'center'
    },
    textHeaderAgreement: {
        fontSize: 20,
        letterSpacing: 5,
    },
    underline: {
        textDecoration: 'underline',
        padding: '0 10px'
    },
    textAgreement: {
        fontSize: 10,
        fontWeight: 100,
        marginBottom: 10,
        lineHeight: 2,
        textAlign: 'justify'
    },
    rowSignature: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    signatureUnderline: {
        borderBottom: '0.5px solid #000',
        display: 'flex',
        flex: 1,
        margin: '10px 0'
    },
    centerSignature: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
})



