import React, { useEffect, useState } from "react";
import { Divider, Layout, Row, Col, Button, Form, Input, Select, InputNumber, Upload, Modal, message, notification, Checkbox, Tooltip, Tag, Space, theme, Typography } from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory  } from 'react-router-dom';

import SideNav from "../../../../../components/SideNav";
import Navbar from "../../../../../components/Navbar";

import "./style.scss"

import { inject, observer } from "mobx-react";
import { cloneDeep } from 'lodash';
import { useRef } from "react";

const AddProduct= ({productStore, attachmentStore}) => {
    const { Content } = Layout;
    const { TextArea } = Input;
    const navigation = useHistory()

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [productImage, setProductImage] = useState();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    
    // state for variants  
    const [hasVariants, setHasVariants] = useState(false);
    const [variants, setVariants] = useState([]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const inputRef = useRef(null);
    const editInputRef = useRef(null);

    // state for sub variants  
    const [hasSubVariants, setHasSubVariants] = useState(false);
    const [subVariants, setSubVariants] = useState([]);
    const [inputVisibleSub, setInputVisibleSub] = useState(false);
    const [inputValueSub, setInputValueSub] = useState('');
    const [editInputIndexSub, setEditInputIndexSub] = useState(-1);
    const [editInputValueSub, setEditInputValueSub] = useState('');
    const inputRefSub = useRef(null);
    const editInputRefSub = useRef(null);

    useEffect(() => {
        attachmentStore.images = [];
        productStore.isSuccess = false;
        productStore.onError = false;
        productStore.onErrorMess = "";
    },[])

    useEffect(() => {
        if(productStore.isSuccess){
            notification.success({
                top: 120,
                message: 'Product created successfully!',
            });

            setTimeout(() => {
                navigation.goBack()
            }, 1000)
        }
    }, [productStore.isSuccess]);

    useEffect(() => {
        if(productStore.onError){
            notification.error({
                top: 120,
                message: 'Create Product Error',
                description: productStore.onErrorMess
            });
        }
    }, [productStore.onError]);

    useEffect(() => {
        if (inputVisible) {
        inputRef.current?.focus();
        }
    }, [inputVisible]);

    useEffect(() => {
        editInputRef.current?.focus();
    }, [editInputValue]);

    useEffect(() => {
        if (inputVisibleSub) {
            inputRefSub.current?.focus();
        }
    }, [inputVisibleSub]);

    useEffect(() => {
        editInputRefSub.current?.focus();
    }, [editInputValueSub]);

    const handleClose = (removedTag) => {
        const newTags = variants.filter((tag) => tag !== removedTag);
        setVariants(newTags);
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue && variants.indexOf(inputValue) === -1) {
        setVariants([...variants, inputValue]);
        }
        setInputVisible(false);
        setInputValue('');
    };

    const handleEditInputChange = (e) => {
        setEditInputValue(e.target.value);
    };

    const handleEditInputConfirm = () => {
        const newTags = [...variants];
        newTags[editInputIndex] = editInputValue;
        setVariants(newTags);
        setEditInputIndex(-1);
        setEditInputValue('');
    };

    // 
    const handleCloseSub = (removedTag) => {
        const newTags = subVariants.filter((tag) => tag !== removedTag);
        setSubVariants(newTags);
    };

    const showInputSub = () => {
        setInputVisibleSub(true);
    };

    const handleInputChangeSub = (e) => {
        setInputValueSub(e.target.value);
    };

    const handleInputConfirmSub = () => {
        if (inputValueSub && subVariants.indexOf(inputValueSub) === -1) {
        setSubVariants([...subVariants, inputValueSub]);
        }
        setInputVisibleSub(false);
        setInputValueSub('');
    };

    const handleEditInputChangeSub = (e) => {
        setEditInputValueSub(e.target.value);
    };

    const handleEditInputConfirmSub = () => {
        const newTags = [...subVariants];
        newTags[editInputIndexSub] = editInputValueSub;
        setSubVariants(newTags);
        setEditInputIndexSub(-1);
        setEditInputValueSub('');
    };


    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

    const props = {
        beforeUpload: (file) => {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJPG) {
                    message.error('You can only upload JPG or PNG file!');
                    return false;
                } else {
                    return true;
                }
        },
        customRequest: async (info) => {
            let formData = new FormData();
            formData.append('image', info.file)
            await attachmentStore.uploadImage(formData)
            setTimeout(() => {
                if(attachmentStore.isSuccess) {
                    info.onSuccess("ok")
                    setTimeout(() => {
                        attachmentStore.isSuccess = false
                    }, 500)
                } else {
                    info.onError("error")
                }
            }, 1000);

        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log("uploading")
            }
            if (status === 'done') {
                console.log("done")
            } else if (status === 'error') {
                console.log("error")
            }
        },
        onDrop(e) {
            console.log("drop")
        },
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const onFinish = (values) => {
        let dataVariants = []


        if(subVariants.length > 0) {
            variants.forEach((variant, varIndex) => {
                let dataSubVariants = []

                subVariants.forEach((subVariant, index) => {
                    dataSubVariants = [
                        ...dataSubVariants,
                        {
                            image: "",
                            name: subVariant,
                            price: `${values[`price${varIndex}${index}`]}`,
                            damageCharges: `${values[`damageCharges${varIndex}${index}`]}`,
                        }
                    ]
                })

                dataVariants = [
                    ...dataVariants,
                    {
                        image: "",
                        name: variant,
                        price: 0.0,
                        damageCharges: 0.0,
                        subVariants: dataSubVariants
                    }
                ]
            })
        } else if(variants.length > 0) {
            variants.forEach((variant, index) => {
                dataVariants = [
                    ...dataVariants,
                    {
                        image: "",
                        name: variant,
                        price: `${values[`price${index}`]}`,
                        damageCharges: `${values[`damageCharges${index}`]}`,
                    }
                ]
            })
        }    

        if(attachmentStore.images.length > 0) {
            productStore.createProduct({
                brand: values.brand,
                name: values.name,
                description: values.description,
                type: values.type,
                image: attachmentStore.images[attachmentStore.images.length -1]?.imagePath,
                price: values.price || "",
                damageCharges: values.damageCharges || "",
                variants: dataVariants,
            })
        } else {
            message.error("Please upload image")
        }
    }

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="admin-warehouse-bins-products-add">
                        <Row justify="space-between">
                          <h3>Add New Product</h3>
                        </Row>
                        <Divider/>
                        <div className="content-container">
                            <Form
                                form={form}
                                onFinish={onFinish}
                                layout="vertical"
                            >
                                <Row gutter={25}>
                                    <Col span={24}>
                                        <Form.Item label="Upload Product Image">
                                            <Upload
                                                name="image"
                                                listType="picture-card"
                                                className="avatar-uploader"
                                                multiple={false}
                                                maxCount={1}
                                                onPreview={handlePreview}
                                                {...props}
                                            >
                                                {attachmentStore.images.length === 1 ? null : uploadButton}
                                            </Upload>
                                            <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
                                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Brand Name"
                                            name="brand"
                                            rules={[{ required: true, message: 'Please input Brand Name!' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Product Name"
                                            name="name"
                                            rules={[{ required: true, message: 'Please input Product Name!' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Please input Description!' }]}>
                                            <TextArea rows={2}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Type" name="type" rules={[{ required: true, message: 'Please input Type!' }]}>
                                            <Select
                                                options={[
                                                    { value: 'FOR_RENT', label: 'For Rent' },
                                                    { value: 'FOR_SALE', label: 'For Sale' },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item>
                                            <Checkbox onChange={(e) => setHasVariants(e.target.checked)}>Has Variants ?</Checkbox>
                                        </Form.Item>
                                    </Col>
                                    {hasVariants ? (
                                        <>
                                            <Col span={24}>
                                                <Form.Item>
                                                    <Space size={[0, 8]} wrap>
                                                        <Space size={[0, 8]} wrap>
                                                            {variants.map((tag, index) => {
                                                            if (editInputIndex === index) {
                                                                return (
                                                                    <Input
                                                                        ref={editInputRef}
                                                                        key={tag}
                                                                        size="small"
                                                                        style={{
                                                                            width: 80,
                                                                            height: 22,
                                                                            marginInlineEnd: 8,
                                                                            verticalAlign: 'top',
                                                                        }}
                                                                        value={editInputValue}
                                                                        onChange={handleEditInputChange}
                                                                        onBlur={handleEditInputConfirm}
                                                                        onPressEnter={handleEditInputConfirm}
                                                                    />
                                                                );
                                                            }
                                                            const isLongTag = tag.length > 20;
                                                            const tagElem = (
                                                                <Tag
                                                                    key={tag}
                                                                    closable={true}
                                                                    style={{ userSelect: 'none' }}
                                                                    onClose={() => handleClose(tag)}
                                                                >
                                                                <span
                                                                    onDoubleClick={(e) => {
                                                                        if (index !== 0) {
                                                                            setEditInputIndex(index);
                                                                            setEditInputValue(tag);
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                >
                                                                    {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                                                </span>
                                                                </Tag>
                                                            );
                                                                return isLongTag ? (
                                                                    <Tooltip title={tag} key={tag}>
                                                                    {tagElem}
                                                                    </Tooltip>
                                                                ) : (
                                                                    tagElem
                                                                );
                                                            })}
                                                            {inputVisible ? (
                                                                <Input
                                                                    ref={inputRef}
                                                                    type="text"
                                                                    size="small"
                                                                    style={{
                                                                        width: 80,
                                                                        height: 22,
                                                                        marginInlineEnd: 8,
                                                                        verticalAlign: 'top',
                                                                    }}
                                                                    value={inputValue}
                                                                    onChange={handleInputChange}
                                                                    onBlur={handleInputConfirm}
                                                                    onPressEnter={handleInputConfirm}
                                                                />
                                                            ) : (
                                                                <Tag 
                                                                style={{
                                                                    height: 22,
                                                                    borderStyle: 'dashed',
                                                                }} 
                                                                onClick={showInput}>
                                                                    <PlusOutlined /> Add Variant
                                                                </Tag>
                                                            )}
                                                        </Space>
                                                    </Space>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item>
                                                    <Checkbox onChange={(e) => setHasSubVariants(e.target.checked)}>Has Sub Variants ?</Checkbox>
                                                </Form.Item>
                                            </Col>
                                            {hasSubVariants ? (
                                                <>
                                                    <Col span={24}>
                                                        <Form.Item>
                                                            <Space size={[0, 8]} wrap>
                                                                <Space size={[0, 8]} wrap>
                                                                    {subVariants.map((tag, index) => {
                                                                    if (editInputIndexSub === index) {
                                                                        return (
                                                                            <Input
                                                                                ref={editInputRefSub}
                                                                                key={tag}
                                                                                size="small"
                                                                                style={{
                                                                                    width: 80,
                                                                                    height: 22,
                                                                                    marginInlineEnd: 8,
                                                                                    verticalAlign: 'top',
                                                                                }}
                                                                                value={editInputValueSub}
                                                                                onChange={handleEditInputChangeSub}
                                                                                onBlur={handleEditInputConfirmSub}
                                                                                onPressEnter={handleEditInputConfirmSub}
                                                                            />
                                                                        );
                                                                    }
                                                                    const isLongTag = tag.length > 20;
                                                                    const tagElem = (
                                                                        <Tag
                                                                            key={tag}
                                                                            closable={true}
                                                                            style={{ userSelect: 'none' }}
                                                                            onClose={() => handleCloseSub(tag)}
                                                                        >
                                                                        <span
                                                                            onDoubleClick={(e) => {
                                                                                if (index !== 0) {
                                                                                    setEditInputIndexSub(index);
                                                                                    setEditInputValueSub(tag);
                                                                                    e.preventDefault();
                                                                                }
                                                                            }}
                                                                        >
                                                                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                                                        </span>
                                                                        </Tag>
                                                                    );
                                                                        return isLongTag ? (
                                                                            <Tooltip title={tag} key={tag}>
                                                                            {tagElem}
                                                                            </Tooltip>
                                                                        ) : (
                                                                            tagElem
                                                                        );
                                                                    })}
                                                                    {inputVisibleSub ? (
                                                                        <Input
                                                                            ref={inputRefSub}
                                                                            type="text"
                                                                            size="small"
                                                                            style={{
                                                                                width: 80,
                                                                                height: 22,
                                                                                marginInlineEnd: 8,
                                                                                verticalAlign: 'top',
                                                                            }}
                                                                            value={inputValueSub}
                                                                            onChange={handleInputChangeSub}
                                                                            onBlur={handleInputConfirmSub}
                                                                            onPressEnter={handleInputConfirmSub}
                                                                        />
                                                                    ) : (
                                                                        <Tag 
                                                                            style={{
                                                                                height: 22,
                                                                                borderStyle: 'dashed',
                                                                            }} 
                                                                            onClick={showInputSub}>
                                                                            <PlusOutlined /> Add Sub Variant
                                                                        </Tag>
                                                                    )}
                                                                </Space>
                                                            </Space>
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                            ):<></>}
                                            {subVariants.length > 0 ? (
                                                <Col span={24}>
                                                    {variants.map((variant, varIndex) => (
                                                        <>
                                                            <Divider/>
                                                            <Typography.Text>{variant}</Typography.Text>
                                                            {subVariants.map((subVariant, index) => (
                                                                <Row align={"middle"} gutter={25}>
                                                                    <Col span={8}>
                                                                        <Typography.Text>{subVariant}</Typography.Text>
                                                                    </Col>
                                                                    <Col span={8}>
                                                                        <Form.Item label="Price" name={`price${varIndex}${index}`} rules={[{ required: true, message: 'Please input Price!' }]}>
                                                                            <InputNumber style={{width:'100%'}}/>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={8}>
                                                                        <Form.Item label="Damage of Lost Fee" name={`damageCharges${varIndex}${index}`} rules={[{ required: true, message: 'Please input Damage of Lost Fee!' }]}>
                                                                            <InputNumber style={{width:'100%'}}/>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            ))}
                                                        </>
                                                    ))}
                                                </Col>
                                            ):(
                                                <Col span={24}>
                                                    {variants.map((variant, index) => (
                                                        <Row align={"middle"} gutter={25}>
                                                            <Divider/>
                                                            <Col span={8}>
                                                                <Typography.Text>{variant}</Typography.Text>
                                                            </Col>
                                                            <Col span={8}>
                                                                <Form.Item label="Price" name={`price${index}`} rules={[{ required: true, message: 'Please input Price!' }]}>
                                                                    <InputNumber style={{width:'100%'}}/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={8}>
                                                                <Form.Item label="Damage of Lost Fee" name={`damageCharges${index}`} rules={[{ required: true, message: 'Please input Damage of Lost Fee!' }]}>
                                                                    <InputNumber style={{width:'100%'}}/>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </Col>
                                            )}
                                            
                                        </>
                                    ):(
                                        <>
                                            <Col span={12}>
                                                <Form.Item label="Price" name="price" rules={[{ required: true, message: 'Please input Price!' }]}>
                                                    <InputNumber style={{width:'100%'}}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="Damage of Lost Fee" name="damageCharges" rules={[{ required: true, message: 'Please input Damage of Lost Fee!' }]}>
                                                    <InputNumber style={{width:'100%'}}/>
                                                </Form.Item>
                                            </Col>
                                        </>
                                    )}
                                    
                                </Row>
                            </Form>
                        </div>
                        <Divider/>
                        <Row justify="end" gutter={15}>
                            <Col>
                                <Button type="primary" htmlType="submit" onClick={() => form.submit()}>Submit</Button>
                            </Col>
                            <Col>
                                <Button type="default" onClick={() => navigation.goBack()}>Back</Button>
                            </Col>
                        </Row>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject("productStore","attachmentStore")(observer(AddProduct))