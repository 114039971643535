import React, { useEffect, useState } from "react";
import { Descriptions, Divider, Button, Image, Layout, Table, Tag, Row, Badge, List, Modal, Col, Spin, message, Typography } from "antd";
import {
    DownloadOutlined,
    QrcodeOutlined
} from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import moment from "moment-timezone";
import { QrReader } from 'react-qr-reader';

import SideNav from "../../../../components/SideNav";
import Navbar from "../../../../components/Navbar";

import "./style.scss"
import LOGO from "../../../../assets/img/backgroundhome/logo-med-1.png"

import { inject, observer } from "mobx-react";
import { cloneDeep } from 'lodash';
import { QRCode } from "react-qrcode-logo";
import html2canvas from "html2canvas";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PurchasedInvoicePDF from "../../../../components/PurchasedInvoicePDF";

const ViewMemberPurchasedDetails = ({orderStore, paymentStore}) => {
    const {id} = useParams();
    const navigation = useHistory()
    const { Content } = Layout;

    useEffect(() => {
        orderStore.getOrder(id)
        paymentStore.getPaymentByOrdeID(id)
    }, [])

    useEffect(() => {
        if(Object.keys(paymentStore.payment).length > 0) {
            paymentStore.getPaymentRecords(paymentStore.payment?.id)
        }
    }, [paymentStore.payment])

    const downloadQRCode = async() => {
        const divElement = document.getElementById('qrCodeEl');
        try {
          const canvas = await html2canvas(divElement);
          const qrCodeURL = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    
          const aEl = document.createElement('a');
          aEl.href = qrCodeURL;
          aEl.download = 'QR_Code.png';
          document.body.appendChild(aEl);
          aEl.click();
          document.body.removeChild(aEl);
    
          divElement.style.display = 'none';
    
        } catch (error) {
          console.error('Error generating QR code:', error);
        }
    }

    const columns = [
        {
            title: 'Product Image',
            dataIndex: 'productImage',
            key: 'productImage',
            render: (productImage) => (
                <Image className="product-image" src={`https://api.alwaysavailabledme.com/${productImage}`}/>
            )
        },
        {
            title: 'Product Name',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Price',
            dataIndex: 'productAmount',
            key: 'productAmount',
            render: (productAmount) => (
                <span>${parseFloat(productAmount).toFixed(2)}</span>
            )
        },
    ];

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="member-orders-rental-details">
                        <h3>Purchased Details</h3>
                        <Divider/>
                        {Object.keys(orderStore.order).length > 0 ? (
                            <div className="content-container">
                                <Descriptions column={3}>
                                    <Descriptions.Item label="Order Number" span={1}>{orderStore.order.orderNumber}</Descriptions.Item>
                                    <Descriptions.Item label="Date" span={1}>{moment.utc(orderStore.order.createdAt).format('LLL')}</Descriptions.Item>
                                    <Descriptions.Item label="Status" span={1}>
                                        <Tag 
                                            color={
                                                orderStore.order.status === "PENDING" 
                                                ? "blue" 
                                                : orderStore.order.status === "FOR_PAYMENT" 
                                                ?  "geekblue"
                                                : orderStore.order.status === "PREPARING" 
                                                ?  "orange"
                                                : orderStore.order.status === "TO_SHIP" 
                                                ?  "lime"
                                                : orderStore.order.status === "OUT_FOR_DELIVERY" 
                                                ?  "cyan"
                                                : orderStore.order.status === "DELIVERED" 
                                                ?  "green"
                                                : "red"
                                            }
                                        >{orderStore.order.status}</Tag>
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider/>
                                {(orderStore.order.status !== "PENDING" && orderStore.order.status !== "FOR_PAYMENT" && orderStore.order.status !== "PREPARING") ? (
                                    <>
                                        <div id={`qrCodeEl`} className="qr-container" style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "fit-content", marginBottom: "20px"}}>
                                            <QRCode
                                                value={JSON.stringify(
                                                    {
                                                        orderID: id,
                                                        orderNumber: orderStore.order.orderNumber
                                                    }
                                                )}
                                                logoImage={LOGO}
                                                logoWidth={35}
                                                logoHeight={50}
                                                logoOpacity={1}
                                                logoPadding={0}
                                            />
                                            <span>{orderStore.order.orderNumber}</span>
                                        </div>
                                        <Button type="primary" onClick={() => downloadQRCode()} icon={<DownloadOutlined />}> Download QR</Button>
                                        <Divider/>
                                    </>
                                ):<></>}
                                <h4>Order Tracker</h4>
                                <div className="order-tracker-container">
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={orderStore.order.orderTracker}
                                        renderItem={(item, index) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={index === (orderStore.order.orderTracker.length - 1) ? (item.status === "DELIVERED" ? <Badge status="success" /> : <Badge status="processing" />) : <Badge status="success" />}
                                                title={item.title}
                                                description={moment.utc(item.createdAt).format('LLL')}
                                            />
                                        </List.Item>
                                    )}
                                    />
                                </div>
                                <Divider/>
                                <h4>Shipping Information</h4>
                                <Descriptions column={1} layout="vertical" bordered>
                                    <Descriptions.Item label="Shipping Address">{`${orderStore.order.deliveryAddress} ${orderStore.order.deliveryCity} ${orderStore.order.deliveryState} ${orderStore.order.deliveryZipcode}`}</Descriptions.Item>
                                    <Descriptions.Item label="Note">{orderStore.order.note}</Descriptions.Item>
                                </Descriptions>
                                <Divider/>
                                <h4>Products Information</h4>
                                <Table
                                    columns={columns}
                                    // expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
                                    dataSource={cloneDeep(orderStore.order.products)}
                                    pagination={false}
                                />
                                <Divider/>
                                <h4>Payment Information</h4>
                                {(orderStore.order.status !== "PENDING" && orderStore.order.status !== "FOR_PAYMENT") ? (
                                    <>
                                        <Descriptions bordered column={2}>
                                            <Descriptions.Item label="Status" span={1}>
                                                <Tag 
                                                    color={
                                                        paymentStore.payment.status === "PENDING"
                                                        ? "blue"
                                                        : paymentStore.payment.status === "INITIAL_PAYMENT"
                                                        ? "lime"
                                                        : "green"
                                                }>{paymentStore.payment.status}</Tag>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Total Order Payment" span={1}>${parseFloat(orderStore.order.totalAmount).toFixed(2)}</Descriptions.Item>
                                        </Descriptions>
                                        {paymentStore.payment.status === "PAID" ? (
                                            paymentStore.payment_records.length > 0 ? (
                                                <>
                                                    <Divider/>
                                                    <Descriptions bordered column={2}>
                                                        <Descriptions.Item label="Payment Date" span={1}>{moment.utc(paymentStore.payment_records[0].createdAt).format("LLL")}</Descriptions.Item>
                                                        <Descriptions.Item label="Accommodated By" span={1}>{`${paymentStore.payment_records[0].employee?.firstName} ${paymentStore.payment_records[0].employee?.lastName}`}</Descriptions.Item>
                                                        <Descriptions.Item label="Invoice Number" span={1}>{paymentStore.payment_records[0].invoiceNumber}</Descriptions.Item>
                                                        <Descriptions.Item label="Receipt Number" span={1}>{paymentStore.payment_records[0].receiptNumber}</Descriptions.Item>
                                                        <Descriptions.Item label="Note" span={2}>{paymentStore.payment.note}</Descriptions.Item>
                                                        <Descriptions.Item label="Invoice Document" span={1}>
                                                            <PDFDownloadLink document={<PurchasedInvoicePDF order={orderStore.order} payment={paymentStore.payment} paymentRecords={paymentStore.payment_records} />} fileName="INVOICE.pdf">
                                                                <Button type="primary" icon={<DownloadOutlined />}>Download Invoice</Button>
                                                            </PDFDownloadLink>
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </>
                                            ):<></>
                                        ):<></>}
                                    </>
                                ): (
                                    <Descriptions bordered column={2}>
                                        <Descriptions.Item label="Status" span={1}>
                                            <Tag color="blue">PENDING</Tag>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Total Order Payment" span={1}>${parseFloat(orderStore.order.totalAmount).toFixed(2)}</Descriptions.Item>
                                    </Descriptions>
                                )}
                            </div>
                        ):<></>}
                        <Divider/>
                        <Row justify="end" gutter={10}>
                            <Col>
                                <Button onClick={() => navigation.goBack()}> Back</Button>
                            </Col>
                        </Row>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject("orderStore", "paymentStore")(observer(ViewMemberPurchasedDetails));