/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Input, Button, Row, Col, notification, Upload, Divider, DatePicker, Modal, message, Select } from 'antd';
import { Link, useLocation, useHistory } from "react-router-dom"
import { inject, observer } from 'mobx-react'
import { UserOutlined, LockOutlined, UserAddOutlined, PhoneOutlined, MailOutlined, EnvironmentOutlined, PlusOutlined} from '@ant-design/icons';
import LogoName from "../../assets/img/backgroundhome/logo-med.png"
import NavbarLogin from "../../components/NavbarLogin"
import "./style.scss"

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Register = ({ userStore, attachmentStore }) => {
    const navigation = useHistory()
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [userImage, setUserImage] = useState();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    useEffect(() => {
        attachmentStore.images = [];
        userStore.isSuccess = false;
        userStore.onError = false;
        userStore.onErrorMess = "";
    },[])

    useEffect(() => {
        if(attachmentStore.images.length > 0){
            setUserImage(attachmentStore.images[0])
        }
    },[attachmentStore.images])

    useEffect(() => {
        console.log(userStore.isSuccess)
        if(userStore.isSuccess){
            notification.success({
                top: 120,
                message: 'User created successfully!',
            });

            setTimeout(() => {
                navigation.push('/login')
            }, 1000)
        }
    }, [userStore.isSuccess]);

    useEffect(() => {
        if(userStore.onError){
            notification.error({
                top: 120,
                message: 'Create User Error',
                description: userStore.onErrorMess
            });
        }
    }, [userStore.onError]);


    const ageCalculator = (birthdate) => {
        const today = new Date();
        const birthDate = new Date(birthdate);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        if(!isNaN(age)) {
            return age;
        } else {
            return ""
        }
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

    const props = {
        beforeUpload: (file) => {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJPG) {
                    message.error('You can only upload JPG or PNG file!');
                    return false;
                } else {
                    return true;
                }
        },
        customRequest: async (info) => {
            let formData = new FormData();
            formData.append('image', info.file)
            await attachmentStore.uploadImage(formData)
            setTimeout(() => {
                if(attachmentStore.isSuccess) {
                    info.onSuccess("ok")
                    setTimeout(() => {
                        attachmentStore.isSuccess = false
                    }, 500)
                } else {
                    info.onError("error")
                }
            }, 1000);

        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log("uploading")
            }
            if (status === 'done') {
                console.log("done")
            } else if (status === 'error') {
                console.log("error")
            }
        },
        onDrop(e) {
            console.log("drop")
        },
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const onFinish = (values) => {
        if(attachmentStore.images.length > 0) {
            const data = {
                ...values,
                image: attachmentStore.images[attachmentStore.images.length -1]?.imagePath,
                middleName: values.middleName || "",
                suffix: values.suffix || "",
                status: "ACTIVE",
                userType: "CUSTOMER",
                position: ""
            }
            
            userStore.createUser(data)
        } else {
            message.error("Please upload image!")
        }
        
    }

    return (
        <div className="register-page">
            <NavbarLogin />
            <div className="register-page-form">
                <div className="register-logo">
                    <a className="brand-logo" href="/login">
                        <img src={LogoName} />
                    </a>
                </div>
                <p className="register-intro">Register</p>
                <p>Login Information</p>
                <Form
                    name="normal_register"
                    className="register-form"
                    initialValues={{
                        remember: true,
                    }}
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                        <Col span={24}>
                            <Form.Item name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Username!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                    placeholder="Username"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Password!',
                                    },
                                ]}
                            >
                                <Input.Password
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password" placeholder="Password"
                                />
                            </Form.Item>
                        </Col>
                        <Divider/>
                        <p className="form-title">Personal Information</p>
                        <Col span={24}>
                            <p>Upload Profile Image</p>
                            <Form.Item>
                                <Upload
                                    name="image"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    multiple={false}
                                    maxCount={1}
                                    onPreview={handlePreview}
                                    {...props}
                                >
                                    {attachmentStore.images.length === 1 ? null : uploadButton}
                                </Upload>
                                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
                                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="firstName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your First Name!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<UserAddOutlined className="site-form-item-icon" />}
                                    placeholder="First Name"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="middleName">
                                <Input
                                    prefix={<UserAddOutlined className="site-form-item-icon" />}
                                    placeholder="Middle Name"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="lastName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Last Name!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<UserAddOutlined className="site-form-item-icon" />}
                                    placeholder="Last Name"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="suffix">
                                <Input
                                    prefix={<UserAddOutlined className="site-form-item-icon" />}
                                    placeholder="Suffix"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="birthDate" rules={[{ required: true, message: 'Please input Date of Birth!' }]}>
                                <DatePicker 
                                placeholder="Date of Birth"
                                onChange={async(date,dateString) => {
                                    const age = await ageCalculator(dateString)
                                    form.setFieldsValue({
                                        'age': age
                                    })
                                }} style={{width:'100%'}} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="age" rules={[{ required: true, message: 'Please input Age!' }]}>
                                <Input prefix={<UserAddOutlined className="site-form-item-icon" />}
                                    placeholder="Age"
                                readOnly/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="gender" rules={[{ required: true, message: 'Please input Gender!' }]}>
                                <Select
                                    prefix={<UserAddOutlined className="site-form-item-icon" />}
                                    placeholder={"Gender"}
                                    options={[
                                        { value: 'MALE', label: 'Male' },
                                        { value: 'FEMALE', label: 'Female' },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        <Divider/>
                        <p className="form-title">Contact Information</p>
                        <Col span={24}>
                            <Form.Item name="contactNumber"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Contact Number!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<PhoneOutlined className="site-form-item-icon" />}
                                    placeholder="Contact Number"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Email!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<MailOutlined className="site-form-item-icon" />}
                                    placeholder="Email"
                                />
                            </Form.Item>
                        </Col>
                        <Divider/>
                        <p className="form-title">Address</p>
                        <Col span={24}>
                            <Form.Item name="address"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Address!',
                                    },
                                ]}
                            >
                                <Input
                                    maxLength={12}
                                    prefix={<EnvironmentOutlined className="site-form-item-icon" />}
                                    placeholder="Address"
                                />
                            </Form.Item>
                            <Form.Item name="city"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your City!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<EnvironmentOutlined className="site-form-item-icon" />}
                                    placeholder="City"
                                />
                            </Form.Item>
                            <Form.Item name="state"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your State!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<EnvironmentOutlined className="site-form-item-icon" />}
                                    placeholder="State"
                                />
                            </Form.Item>
                            <Form.Item name="zipcode"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Zipcode!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<EnvironmentOutlined className="site-form-item-icon" />}
                                    placeholder="Zipcode"
                                    minLength={6}
                                />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            loading={userStore.loading}
                            block>
                            Register
                        </Button>
                        <Link to="/login">
                            <Button type="default" htmlType="submit" className="register-form-button" block>
                                Already have an account? Sign-In.
                            </Button>
                        </Link>
                    </Form.Item>

                </Form>
            </div>

            <footer>
                <p>© 2023 DME. All Rights Reserved.</p>
            </footer>

        </div>
    )


}

export default inject('userStore','attachmentStore')(observer(Register))