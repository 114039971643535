import React, { useEffect, useState } from "react";
import { Descriptions, Divider, Button, Image, Layout, Table, Tag, Row, Badge, List, Modal, Col, Spin, message, Typography, Space, InputNumber, Form, Input, notification } from "antd";
import {
    DownloadOutlined,
    QrcodeOutlined,
    CheckOutlined
} from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import moment from "moment-timezone";
import { QrReader } from 'react-qr-reader';

import SideNav from "../../../../../components/SideNav";
import Navbar from "../../../../../components/Navbar";

import "./style.scss"
import LOGO from "../../../../../assets/img/backgroundhome/logo-med-1.png"

import { inject, observer } from "mobx-react";
import { cloneDeep } from 'lodash';
import { QRCode } from "react-qrcode-logo";
import html2canvas from "html2canvas";
import { PDFDownloadLink } from "@react-pdf/renderer";
import RentFullInvoicePDF from "../../../../../components/RentFullInvoicePDF";
import RentInitialInvoicePDF from "../../../../../components/RentInitialInvoicePDF";

const ViewRentalDetails = ({orderStore, paymentStore}) => {
    const {id} = useParams();
    const navigation = useHistory()
    const { Content } = Layout;
    const { TextArea } = Input;

    const [form] = Form.useForm()

    const [isScanningProduct, setIsScanningProduct] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState()
    const [scannedQRcodes, setScannedQRCodes] = useState([])
    const [currentQrCode, setCurrentQRCode] = useState()
    const [totalRemainingToScan, setTotalRemainingToScan] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [products, setProducts] = useState([])
    const [totalNumberOfProducts, setTotalNumberOfProducts] = useState(0)
    const [hasOverDue, setHasOverDue] = useState(false)
    const [hasNotReturnedProducts, setHasNotReturnedProducts] = useState(false)

    const [extraCharge, setExtraCharge] = useState(0)
    const [forFullPaymentModal, setIsForFullPaymentModal] = useState(false)
    const [toReturn, setToReturn] = useState([])
    const [deliveredDate, setDeliveredDate] = useState()

    const [requests, setRequests] = useState([])

    useEffect(() => {
        orderStore.isSuccess = false
        orderStore.getOrder(id)
        paymentStore.getPaymentByOrdeID(id)
        orderStore.getOrderRequests(id)
    }, [])

    useEffect(() => {
        if(orderStore.isSuccess) {
            orderStore.getOrder(id)
            paymentStore.getPaymentByOrdeID(id)
            orderStore.getOrderRequests(id)
            setTimeout(() => {
                orderStore.isSuccess = false
            }, 1000)
        } 
    }, [orderStore.isSuccess])

    useEffect(() => {
        if(Object.keys(paymentStore.payment).length > 0) {
            paymentStore.getPaymentRecords(paymentStore.payment?.id)
        }
    }, [paymentStore.payment])

    useEffect(() => {
        let requests = []
        if(orderStore.ordersRequests.length > 0){
            orderStore.ordersRequests.forEach((ordersRequest) => {
                ordersRequest.key = ordersRequest.id

                requests = [...requests, ordersRequest]
            });

        }

        setRequests(requests)
    }, [orderStore.ordersRequests])

    useEffect(() => {
        if(toReturn.length > 0) {
            notification.error({
                top: 120,
                message: 'Has to due products',
            });
        }
    }, [toReturn])

    useEffect(() => {
        if(Object.keys(orderStore.order).length > 0) {
            setProducts([
                ...orderStore.order.products.map((product, index) => {
                    if(orderStore.order.status === "DELIVERED") {
                        const toReturnProducts = orderStore.order.products?.filter((product) => (
                            moment().isSame(moment(deliveredDate).add(parseInt(product.noOfRentalDays), 'days')) 
                            || moment().isAfter(moment(deliveredDate).add(parseInt(product.noOfRentalDays), 'days'))))

                            setToReturn(toReturnProducts)
                    }
                    product.serialCodes?.forEach((serialCode) => {
                        if(serialCode.status !== "OK") {
                            setExtraCharge(extraCharge + product.productDamageCharges)
                        }
                        if(orderStore.order.status === "FOR_PICK_UP") {
                            if(serialCode.updatedAt === null && !hasNotReturnedProducts) {
                                setHasNotReturnedProducts(true)
                            }
                        }
                    })
                    product = {
                        key: index,
                        ...product
                    }
                    return product
                })
            ])
        }
    }, [orderStore.order])

    useEffect(() => {
        if(orderStore.order.orderTracker?.length > 0) {
            const isDelivered = orderStore.order.orderTracker.find((tracker) => tracker.status === "DELIVERED")
            if(isDelivered) {
                setDeliveredDate(isDelivered.createdAt)
            }
        }
    }, [orderStore.order.orderTracker])


    useEffect(() => {
        if(currentQrCode) {
            const checkExist = scannedQRcodes.find((value) => value.serialCode === currentQrCode.serialCode)
            if(checkExist) {
                message.error("Already scanned")
            } else {
                setScannedQRCodes([...scannedQRcodes, currentQrCode])
                setCurrentQRCode()
                setTotalRemainingToScan(totalRemainingToScan + 1)
            }
            setTimeout(() => {
                setIsLoading(false)
            }, 1000)
        }
    }, [currentQrCode])

    const scanner = (scannedQR) => {
        setIsLoading(true)
        setTimeout(() => {
            setCurrentQRCode(scannedQR)
        }, 1000)
    }

    const onOk = () => {
        setIsScanningProduct(false)
        setProducts([
            ...products.map((product) => {
                if(product.id  === selectedProduct.id) {
                    product.serialCodes = scannedQRcodes
                }
                return product
            })
        ])
        setTotalNumberOfProducts(totalNumberOfProducts + 1)
        setTotalRemainingToScan(0)
        setScannedQRCodes([])
    }

    const updateOrder = (status) => {
        const data = {
            customerID: orderStore.order.customerID,
            status: status,
            totalAmount: orderStore.order.totalAmount,
            products: products, 
            type: "RENT"
        }
        orderStore.updateOrder(id, data)
    }

    const updateForReturn = (status) => {
        const data = {
            status: status,
            type: "RENT"
        }
        orderStore.updateOrder(id, data)
    }

    const onFinish = (values) => {
        const data = {
            ...values,
            status: "FOR_FULL_PAYMENT",
            overallTotal: extraCharge + parseFloat(paymentStore.payment.totalOrderPayment),
            extraCharge: extraCharge,
            orderStatus: "FOR_FULL_PAYMENT",
            orderType: "RENT",
            orderID: id,
            note: values.note,
            dueDate: moment().add(1, 'days').format('L')
        }
        paymentStore.updatePayment(paymentStore.payment.id, data)
        setIsForFullPaymentModal(false)
    }

    const downloadQRCode = async() => {
        const divElement = document.getElementById('qrCodeEl');
        try {
          const canvas = await html2canvas(divElement);
          const qrCodeURL = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    
          const aEl = document.createElement('a');
          aEl.href = qrCodeURL;
          aEl.download = 'QR_Code.png';
          document.body.appendChild(aEl);
          aEl.click();
          document.body.removeChild(aEl);
    
          divElement.style.display = 'none';
    
        } catch (error) {
          console.error('Error generating QR code:', error);
        }
      }

      const receivedProduct = (id, status, serialCode, productID) => {
        orderStore.updateOrderProductSerial(
            id, 
            serialCode, 
            productID,
            { status: status }
        )
      }

    //   const rowSelection = {
    //     onChange: (selectedRowKeys, selectedRows) => {
    //       console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    //     },
    //     getCheckboxProps: (record) => ({
    //       disabled: record.name === 'Disabled User',
    //       // Column configuration not to be checked
    //       name: record.name,
    //     }),
    //   };

    const expandedRowRender = (row) => {
        const columns = [
          {
            title: 'Serial Code',
            dataIndex: 'serialCode',
            key: 'serialCode',
          },
        ];

        const columnsForPickup = [
            {
                title: 'Serial Code',
                dataIndex: 'serialCode',
                key: 'serialCode',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (status, record) => (
                    <span>
                        {record.updatedAt === null ? "-" : status}
                    </span>
                )
            },
            {
                title: 'Returned Date',
                dataIndex: 'updatedAt',
                key: 'updatedAt',
                render: (updatedAt) => (
                    <span>
                        {updatedAt ? moment.utc(updatedAt).format("LLL") : "-"}
                    </span>
                )
            },
            {
                title: 'Action',
                dataIndex: 'id',
                key: 'id',
                render: (id, record) => (
                    record.updatedAt === null ? (
                        <Space size="middle">
                            <Typography.Link onClick={() => receivedProduct(id, "OK", record.serialCode, record.productID)}>Mark as ok</Typography.Link>
                            <Typography.Link type="danger" onClick={() => receivedProduct(id, "LOST", record.serialCode, record.productID)}>Mark as Lost</Typography.Link>
                            <Typography.Link type="danger" onClick={() => receivedProduct(id, "DAMAGED", record.serialCode, record.productID)}>Mark as Damaged</Typography.Link>
                        </Space>
                    ): <Typography.Text type="success"><CheckOutlined /> RETURNED</Typography.Text>
                )
            },
        ];

        return <Table columns={orderStore.order.status === "FOR_PICK_UP" ? columnsForPickup : columns} dataSource={cloneDeep(row.serialCodes?.length > 0 ? row.serialCodes : [])} pagination={false} />;
    };

    const columnsPreparing = [
        {
            title: 'Product Image',
            dataIndex: 'productImage',
            key: 'productImage',
            render: (productImage) => (
                <Image className="product-image" src={`https://api.alwaysavailabledme.com/${productImage}`}/>
            )
        },
        {
            title: 'Product Name',
            dataIndex: 'productName',
            key: 'productName',
            render: (name, record) => (
                <span>
                    {name}
                    <br/>
                    {record.productSubVariantID != null ? (
                        <>
                            <Tag>{record.variant.name}</Tag>
                            <Tag>{record.subVariant.name}</Tag>
                        </>
                    ): record.productVariantID != null ? (
                        <Tag>{record.variant.name}</Tag>
                    ):<></>}
                </span>
            )
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Price',
            dataIndex: 'productAmount',
            key: 'productAmount',
            render: (price, record) => (
                <span>
                    {record.productSubVariantID != null ? (
                        `$${parseFloat(record.subVariant.price).toFixed(2)}`
                    ): record.productVariantID != null ? (
                        `$${parseFloat(record.variant.price).toFixed(2)}`
                    ):(`$${parseFloat(price).toFixed(2)}`)}
                </span>
            )
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (id, record) => (
                <Button 
                    type="primary" 
                    icon={<QrcodeOutlined />}
                    onClick={() => onCLickItem(record)}
                    disabled={record.serialCodes && record.serialCodes.length > 0}
                >Scan Items</Button>
            )
        },
    ];

    const onCLickItem = (record) => {
        setSelectedProduct(record)
        setIsScanningProduct(true)
    }

    const columns = [
        {
            title: 'Product Image',
            dataIndex: 'productImage',
            key: 'productImage',
            render: (productImage) => (
                <Image className="product-image" src={`https://api.alwaysavailabledme.com/${productImage}`}/>
            )
        },
        {
            title: 'Product Name',
            dataIndex: 'productName',
            key: 'productName',
             render: (name, record) => (
                <span>
                    {name}
                    <br/>
                    {record.productSubVariantID != null ? (
                        <>
                            <Tag>{record.variant.name}</Tag>
                            <Tag>{record.subVariant.name}</Tag>
                        </>
                    ): record.productVariantID != null ? (
                        <Tag>{record.variant.name}</Tag>
                    ):<></>}
                </span>
            )
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Price',
            dataIndex: 'productAmount',
            key: 'productAmount',
            render: (price, record) => (
                <span>
                    {record.productSubVariantID != null ? (
                        `$${parseFloat(record.subVariant.price).toFixed(2)}`
                    ): record.productVariantID != null ? (
                        `$${parseFloat(record.variant.price).toFixed(2)}`
                    ):(`$${parseFloat(price).toFixed(2)}`)}
                </span>
            )
        },
    ];

    const columnsPending = [
        {
            title: 'Product Image',
            dataIndex: 'productImage',
            key: 'productImage',
            render: (productImage) => (
                <Image className="product-image" src={`https://api.alwaysavailabledme.com/${productImage}`}/>
            )
        },
        {
            title: 'Product Name',
            dataIndex: 'productName',
            key: 'productName',
            render: (name, record) => (
                <span>
                    {name}
                    <br/>
                    {record.productSubVariantID != null ? (
                        <>
                            <Tag>{record.variant.name}</Tag>
                            <Tag>{record.subVariant.name}</Tag>
                        </>
                    ): record.productVariantID != null ? (
                        <Tag>{record.variant.name}</Tag>
                    ):<></>}
                </span>
            )
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Price',
            dataIndex: 'productAmount',
            key: 'productAmount',
            render: (price, record) => (
                <span>
                    {record.productSubVariantID != null ? (
                        `$${parseFloat(record.subVariant.price).toFixed(2)}`
                    ): record.productVariantID != null ? (
                        `$${parseFloat(record.variant.price).toFixed(2)}`
                    ):(`$${parseFloat(price).toFixed(2)}`)}
                </span>
            )
        },
    ];

    const columnsRequest = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => (
                <Tag
                    color={
                        status === "PENDING" 
                        ? "blue" 
                        : status === "ON_PROGRESS" 
                        ?  "orange"
                        : status === "DONE" 
                        ?  "green"
                        : "red"
                    }
                >
                    {status}
                </Tag>
            )
        },
        {
            title: 'Request Note',
            dataIndex: 'userNote',
            key: 'userNote',
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (id, record) => (
                record.status === "PENDING" ? (
                    <Button type="primary" onClick={() => {
                        const data = {
                            status: "ON_PROGRESS",
                            orderID: record.orderID
                        }
                        orderStore.updateOrderRequest(id, data)
                    }}>Mark as On Progress</Button>
                ) : record.status === "ON_PROGRESS" ? (
                    <Button type="primary" onClick={() => {
                        const data = {
                            status: "DONE",
                            orderID: record.orderID
                        }
                        orderStore.updateOrderRequest(id, data)
                    }}>Mark as Done</Button>
                ): <></>
            )
        },
    ];

    const expandedRowRenderRequest = (row) => {
        const columns = [
            {
                title: 'Product Image',
                dataIndex: 'product',
                key: 'productImage',
                render: (product) => (
                    <Image className="product-image" src={`https://api.alwaysavailabledme.com/${product.productImage}`}/>
                )
            },
            {
                title: 'Product Name',
                dataIndex: 'product',
                key: 'productName',
                render: (product, record) => (
                    <span>
                        {product.productName}
                        <br/>
                        {record.productSubVariantID != null ? (
                            <>
                                <Tag>{record.product.variant.name}</Tag>
                                <Tag>{record.product.subVariant.name}</Tag>
                            </>
                        ): record.product.productVariantID != null ? (
                            <Tag>{record.product.variant.name}</Tag>
                        ):<></>}
                    </span>
                )
            },
        ];

        return <Table columns={columns} dataSource={cloneDeep(row.products)} pagination={false} />;
    };

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="member-orders-rental-details">
                        <h3>Purchased Details</h3>
                        <Divider/>
                        {Object.keys(orderStore.order).length > 0 ? (
                            <div className="content-container">
                                <Descriptions column={3}>
                                    <Descriptions.Item label="Order Number" span={1}>{orderStore.order.orderNumber}</Descriptions.Item>
                                    <Descriptions.Item label="Date" span={1}>{moment.utc(orderStore.order.createdAt).format('LLL')}</Descriptions.Item>
                                    <Descriptions.Item label="Status" span={1}>
                                        <Tag 
                                            color={
                                                orderStore.order.status === "PENDING" 
                                                ? "blue" 
                                                : (orderStore.order.status === "FOR_PAYMENT" || orderStore.order.status === "FOR_FULL_PAYMENT")
                                                ?  "geekblue"
                                                : (orderStore.order.status === "PREPARING" || orderStore.order.status === "FOR_RETURN")
                                                ?  "orange"
                                                : (orderStore.order.status === "TO_SHIP" || orderStore.order.status === "FOR_PICK_UP") 
                                                ?  "lime"
                                                : orderStore.order.status === "OUT_FOR_DELIVERY" 
                                                ?  "cyan"
                                                : (orderStore.order.status === "DELIVERED" || orderStore.order.status === "COMPLETED") 
                                                ?  "green"
                                                : "red"
                                            }
                                        >{orderStore.order.status}</Tag>
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider/>
                                {(orderStore.order.status !== "PENDING" && orderStore.order.status !== "FOR_PAYMENT" && orderStore.order.status !== "PREPARING") ? (
                                    <>
                                        <div id={`qrCodeEl`} className="qr-container" style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "fit-content", marginBottom: "20px"}}>
                                            <QRCode
                                                value={JSON.stringify(
                                                    {
                                                        orderID: id,
                                                        orderNumber: orderStore.order.orderNumber
                                                    }
                                                )}
                                                logoImage={LOGO}
                                                logoWidth={35}
                                                logoHeight={50}
                                                logoOpacity={1}
                                                logoPadding={0}
                                            />
                                            <span>{orderStore.order.orderNumber}</span>
                                        </div>
                                        <Button type="primary" onClick={() => downloadQRCode()} icon={<DownloadOutlined />}> Download QR</Button>
                                        <Divider/>
                                    </>
                                ):<></>}
                                <h4>Order Tracker</h4>
                                <div className="order-tracker-container">
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={orderStore.order.orderTracker}
                                        renderItem={(item, index) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={index === (orderStore.order.orderTracker.length - 1) ? (item.status === "COMPLETED" ? <Badge status="success" /> : <Badge status="processing" />) : <Badge status="success" />}
                                                title={item.title}
                                                description={moment.utc(item.createdAt).format('LLL')}
                                            />
                                        </List.Item>
                                    )}
                                    />
                                </div>
                                <Divider/>
                                <h4>Customer Information</h4>
                                <Descriptions column={2} bordered>
                                    <Descriptions.Item label="Customer Number" span={2}>{orderStore.order.customer?.idNumber}</Descriptions.Item>
                                    <Descriptions.Item label="First Name" span={1}>{orderStore.order.customer?.firstName}</Descriptions.Item>
                                    <Descriptions.Item label="Middle Name" span={1}>{orderStore.order.customer?.middleName}</Descriptions.Item>
                                    <Descriptions.Item label="Last Name" span={1}>{orderStore.order.customer?.lastName}</Descriptions.Item>
                                    <Descriptions.Item label="Suffix" span={1}>{orderStore.order.customer?.suffix}</Descriptions.Item>
                                    <Descriptions.Item label="Contact Number" span={1}>{orderStore.order.customer?.contactNumber}</Descriptions.Item>
                                    <Descriptions.Item label="Email" span={1}>{orderStore.order.customer?.email}</Descriptions.Item>
                                </Descriptions>
                                <Divider/>
                                <h4>Shipping Information</h4>
                                <Descriptions column={1} layout="vertical" bordered>
                                    <Descriptions.Item label="Shipping Address">{`${orderStore.order.deliveryAddress} ${orderStore.order.deliveryCity} ${orderStore.order.deliveryState} ${orderStore.order.deliveryZipcode}`}</Descriptions.Item>
                                    <Descriptions.Item label="Note">{orderStore.order.note}</Descriptions.Item>
                                </Descriptions>
                                <Divider/>
                                <h4>Products Information</h4>
                                {(orderStore.order.status !== "PENDING" && orderStore.order.status !== "FOR_PAYMENT") ? (
                                    <>
                                        <Table
                                            columns={orderStore.order.status === "PREPARING" ? columnsPreparing : columns}
                                            expandable={{
                                                expandedRowRender: (row) => expandedRowRender(row)
                                            }}
                                            dataSource={cloneDeep(products)}
                                            pagination={false}
                                        />
                                        <Modal
                                            visible={isScanningProduct}
                                            title={`Scan ${selectedProduct?.productName}`}
                                            onCancel={() => setIsScanningProduct(false)}
                                            onOk={onOk}
                                            okButtonProps={{ disabled: totalRemainingToScan !== parseInt(selectedProduct?.quantity)}}
                                        >
                                            <h4>To scan products: {totalRemainingToScan} / {selectedProduct?.quantity}</h4>
                                            {totalRemainingToScan !== parseInt(selectedProduct?.quantity) ? (
                                                <>
                                                    <Row justify="center">
                                                        <Col span={12} align={"middle"}>
                                                            {isLoading ? (
                                                                <Spin tip="Loading..." spinning={isLoading}/>
                                                            ): (
                                                                <QrReader
                                                                    onResult={(result, error) => {
                                                                        if (!!result) {
                                                                            const scannedQR = JSON.parse(result.text)
                                                                            
                                                                            if(scannedQR.productID === selectedProduct.productID) {
                                                                                scanner(scannedQR)
                                                                            } else {
                                                                                message.error(`Scanned product is not ${selectedProduct?.productName}. Please make sure that you only scanned the type of product you selected.`)
                                                                            }
                                                                        }
                                                                    }}
                                                                    constraints={{
                                                                        facingMode: 'environment'
                                                                    }}
                                                                    style={{ width: '100%' }}
                                                                />
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <Divider/>

                                                </>
                                            ):<></>}
                                            <h4>Serial Codes</h4>
                                            <Table
                                                columns={[
                                                    {
                                                        title: 'Serial Code',
                                                        dataIndex: 'serialCode',
                                                        key: 'serialCode',
                                                    }
                                                ]}
                                                dataSource={cloneDeep(scannedQRcodes)}
                                            >
                                            </Table>
                                            
                                            
                                        </Modal>
                                    </>
                                ):(
                                    <Table
                                        columns={columnsPending}
                                        // expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
                                        dataSource={cloneDeep(orderStore.order.products)}
                                        pagination={false}
                                    />
                                )}
                                <Divider/>
                                <h4>Payment Information</h4>
                                {(orderStore.order.status !== "PENDING" && orderStore.order.status !== "FOR_PAYMENT") ? (
                                    <>
                                        <Descriptions bordered column={2}>
                                            <Descriptions.Item label="Status" span={1}>
                                                <Tag 
                                                    color={
                                                        paymentStore.payment.status === "PENDING"
                                                        ? "blue"
                                                        : paymentStore.payment.status === "INITIAL_PAYMENT"
                                                        ? "lime"
                                                        : "green"
                                                }>{paymentStore.payment.status}</Tag>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Total Order Payment" span={1}>${parseFloat(orderStore.order.totalAmount).toFixed(2)}</Descriptions.Item>
                                        </Descriptions>
                                        {paymentStore.payment.status !== "PENDING" ? (
                                            paymentStore.payment_records.length > 0 ? (
                                                <>
                                                    <Divider/>
                                                    <Descriptions bordered column={2}>
                                                        <Descriptions.Item label="Accommodated By" span={2}>{`${paymentStore.payment_records[0].employee?.firstName} ${paymentStore.payment_records[0].employee?.lastName}`}</Descriptions.Item>
                                                        <Descriptions.Item label="Payment Date" span={1}>{moment.utc(paymentStore.payment_records[0].createdAt).format("LLL")}</Descriptions.Item>
                                                        <Descriptions.Item label="Payment Amount" span={1}>${parseFloat(paymentStore.payment.totalPartialPayment).toFixed(2)}</Descriptions.Item>
                                                        <Descriptions.Item label="Invoice Number" span={1}>{paymentStore.payment_records[0].invoiceNumber}</Descriptions.Item>
                                                        <Descriptions.Item label="Receipt Number" span={1}>{paymentStore.payment_records[0].receiptNumber}</Descriptions.Item>
                                                        <Descriptions.Item label="Note" span={2}>{paymentStore.payment.note}</Descriptions.Item>
                                                        <Descriptions.Item label="Invoice Document" span={1}>
                                                            <PDFDownloadLink document={<RentInitialInvoicePDF order={orderStore.order} payment={paymentStore.payment} paymentRecords={paymentStore.payment_records} />} fileName="INITIAL_PAYMENT_INVOICE.pdf">
                                                                <Button type="primary" icon={<DownloadOutlined />}>Download Invoice</Button>
                                                            </PDFDownloadLink>
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    {(paymentStore.payment.status === "FOR_FULL_PAYMENT" | paymentStore.payment.status === "PAID") ? (
                                                        <>
                                                            <Divider/>
                                                            <Descriptions bordered column={3}>
                                                                <Descriptions.Item label="Due Date" span={3}>{moment.utc(paymentStore.payment.dueDate).format("LL")}</Descriptions.Item>
                                                                <Descriptions.Item label="Total Extra charges" span={3}>${parseFloat(paymentStore.payment.extraCharges).toFixed(2)}</Descriptions.Item>
                                                                <Descriptions.Item label="OverAll Total Payment" span={3}>${parseFloat(paymentStore.payment.overallTotal).toFixed(2)}</Descriptions.Item>
                                                                <Descriptions.Item label="Balance" span={3}>${((parseFloat(paymentStore.payment.totalOrderPayment) - parseFloat(paymentStore.payment.totalPartialPayment)) + parseFloat(paymentStore.payment.extraCharges)).toFixed(2)}</Descriptions.Item>
                                                            </Descriptions>
                                                            {paymentStore.payment_records.length === 2 ? (
                                                                <>
                                                                    <Divider/>
                                                                    <Descriptions bordered column={2}>
                                                                    
                                                                        <Descriptions.Item label="Accommodated By" span={2}>{`${paymentStore.payment_records[1].employee?.firstName} ${paymentStore.payment_records[1].employee?.lastName}`}</Descriptions.Item>
                                                                        <Descriptions.Item label="Payment Date" span={1}>{moment.utc(paymentStore.payment_records[1].createdAt).format("LLL")}</Descriptions.Item>
                                                                        <Descriptions.Item label="Payment Amount" span={1}>${((parseFloat(paymentStore.payment.totalOrderPayment) - parseFloat(paymentStore.payment.totalPartialPayment)) + parseFloat(paymentStore.payment.extraCharges)).toFixed(2)}</Descriptions.Item>
                                                                        <Descriptions.Item label="Invoice Number" span={1}>{paymentStore.payment_records[1].invoiceNumber}</Descriptions.Item>
                                                                        <Descriptions.Item label="Receipt Number" span={1}>{paymentStore.payment_records[1].receiptNumber}</Descriptions.Item>
                                                                        <Descriptions.Item label="Note" span={2}>{paymentStore.payment.noteForFull}</Descriptions.Item>
                                                                        <Descriptions.Item label="Invoice Document" span={1}>
                                                                            <PDFDownloadLink document={<RentFullInvoicePDF order={orderStore.order} payment={paymentStore.payment} paymentRecords={paymentStore.payment_records} />} fileName="FULL_PAYMENT_INVOICE.pdf">
                                                                                <Button type="primary" icon={<DownloadOutlined />}>Download Invoice</Button>
                                                                            </PDFDownloadLink>
                                                                        </Descriptions.Item>
                                                                    </Descriptions>
                                                                </>
                                                            ):<></>}
                                                        </>
                                                    ):<></>}
                                                </>
                                            ):<></>
                                        ):<></>}
                                    </>
                                ): (
                                    orderStore.order.status === "PENDING" ? (
                                        <Descriptions bordered column={2}>
                                            <Descriptions.Item label="Status" span={1}>
                                                <Tag color="blue">PENDING</Tag>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Total Order Payment" span={1}>${parseFloat(orderStore.order.totalAmount).toFixed(2)}</Descriptions.Item>
                                        </Descriptions>
                                    ): (
                                        <>
                                            <Descriptions bordered column={2}>
                                                <Descriptions.Item label="Status" span={1}>
                                                    <Tag color="blue">PENDING</Tag>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Total Order Payment" span={1}>${parseFloat(orderStore.order.totalAmount).toFixed(2)}</Descriptions.Item>
                                            </Descriptions>
                                            <Divider/>
                                            <Descriptions bordered column={2}>
                                                <Descriptions.Item label="Partial Payment Order Payment" span={1}>${parseFloat(paymentStore.payment.totalPartialPayment).toFixed(2)}</Descriptions.Item>
                                                <Descriptions.Item label="Partial Payment Status" span={1}>
                                                    <Tag color="blue">UNPAID</Tag>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Partial Payment Date" span={1}>
                                                    -
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </>
                                    )
                                )}
                                {/* {(orderStore.order.status !== "PENDING" && orderStore.order.status !== "FOR_PAYMENT") ? (
                                    <>
                                        <Divider/>
                                            <Descriptions bordered column={3}>
                                                <Descriptions.Item label="Partial Payment Order Payment" span={1.5}>$200.00</Descriptions.Item>
                                                <Descriptions.Item label="Partial Payment Status" span={1.5}>
                                                    <Tag color="blue">UNPAID</Tag>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Partial Payment Date" span={1.5}>
                                                    -
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Download Partial Payment Invoice" span={1.5}>
                                                    <Button type="primary" icon={<DownloadOutlined />}>Download</Button>
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider/>
                                            <Descriptions bordered column={3}>
                                                <Descriptions.Item label="Due Date" span={1.5}>July 15, 2023</Descriptions.Item>
                                                <Descriptions.Item label="Full Payment Status" span={3}>
                                                    <Tag color="blue">UNPAID</Tag>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Total Over Due Months" span={1.5}>0</Descriptions.Item>
                                                <Descriptions.Item label="Total Over Due Amount" span={1.5}>$0.00</Descriptions.Item>
                                                <Descriptions.Item label="Total Damaged/Lost Products" span={1.5}>0</Descriptions.Item>
                                                <Descriptions.Item label="Total Damaged/Lost Products Amount" span={1.5}>$0.00</Descriptions.Item>
                                                <Descriptions.Item label="Total Extra charges" span={3}>$0.00</Descriptions.Item>
                                                <Descriptions.Item label="Balance" span={3}>$200.00</Descriptions.Item>
                                                <Descriptions.Item label="OverAll Total Payment" span={3}>$200.00</Descriptions.Item>
                                                <Descriptions.Item label="Full Payment Date" span={1.5}>
                                                    -
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Download Full Payment Invoice" span={1.5}>
                                                    <Button type="primary" icon={<DownloadOutlined />}>Download</Button>
                                                </Descriptions.Item>
                                            </Descriptions>
                                    </>
                                ):<></>} */}
                            </div>
                        ):<></>}
                         {requests.length > 0 ? (
                            <>
                                <Divider/>
                                <h4>Requests</h4>
                                <Table 
                                    expandable={{
                                        expandedRowRender: (row) => expandedRowRenderRequest(row)
                                    }}
                                    columns={columnsRequest}
                                    dataSource={cloneDeep(requests)}
                                />
                                
                            </>
                        ):<></>}
                        <Divider/>
                        <Row justify="end" gutter={10}>
                            {(Object.keys(orderStore.order).length > 0 && orderStore.order.status === "PREPARING") ? (
                                <Col>
                                    <Button 
                                        type="primary" 
                                        disabled={orderStore.order.products.length !== totalNumberOfProducts}
                                        onClick={() => updateOrder("TO_SHIP")}
                                    >To Ship Order</Button>
                                </Col>
                            ):<></>}
                            {(Object.keys(orderStore.order).length > 0 && orderStore.order.status === "DELIVERED") ? (
                                <Col>
                                    <Button 
                                        type="primary" 
                                        disabled={toReturn.length === 0}
                                        onClick={() => updateForReturn("FOR_RETURN")}
                                    >For Return</Button>
                                </Col>
                            ):<></>}
                            {(Object.keys(orderStore.order).length > 0 && orderStore.order.status === "FOR_PICK_UP") ? (
                                <Col>
                                    <Button 
                                        type="primary" 
                                        disabled={hasNotReturnedProducts}
                                        onClick={() => setIsForFullPaymentModal(true)}
                                    >For Full Payment</Button>
                                    <Modal
                                        title="Full Payment Balance" 
                                        visible={forFullPaymentModal} 
                                        onCancel={() => setIsForFullPaymentModal(false)}
                                        onOk={() => form.submit()}
                                    >
                                        <Form
                                            form={form}
                                            layout="vertical"
                                            onFinish={onFinish}
                                            initialValues={{
                                                balance: parseFloat(paymentStore.payment?.totalOrderPayment) - parseFloat(paymentStore.payment?.totalPartialPayment),
                                                extraCharge: extraCharge
                                            }}
                                        >
                                            <Form.Item
                                                name={"balance"}
                                                label="Balance"
                                                rules={[{ required: true, message: 'Please enter invoice number!' }]}
                                            >
                                                <InputNumber readOnly style={{ width: "100%"}}/>
                                            </Form.Item>
                                            <Form.Item
                                                name={"extraCharge"}
                                                label="Extra Charges (for damaged or loast products)"
                                                rules={[{ required: true, message: 'Please enter invoice number!' }]}
                                            >
                                                <InputNumber readOnly style={{ width: "100%"}}/>
                                            </Form.Item>
                                            <Form.Item
                                                name={"note"}
                                                label="Note"
                                            >
                                                <TextArea />
                                            </Form.Item>
                                        </Form>
                                    </Modal>
                                </Col>
                            ):<></>}
                            <Col>
                                <Button onClick={() => navigation.goBack()}> Back</Button>
                            </Col>
                        </Row>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject("orderStore", "paymentStore")(observer(ViewRentalDetails));