import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import React, { useEffect, useState } from "react";
import { styles } from './styles';
import LOGO from '../../assets/img/backgroundhome/logo-med.png';
import moment from 'moment';

Font.register({
    family: 'Poppins',
    src: 'https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLGT9Z1xlEA.ttf',
  });

const RentFullInvoicePDF = ({order, payment, paymentRecords}) => {
    const [subtotal, setSubtotal] = useState(0)

    useEffect(() => {
        order.products.forEach((product) => {
            const amount = ((parseInt(product.quantity) * parseFloat(product.productAmount)) * parseInt(product.noOfRentalDays))
            setSubtotal(subtotal + amount)
        })
    },[])

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.headerContainer}>
                    <View style={styles.headerLogoContainer}>
                        {/* <Image source={{uri: "../../assets/img/backgroundhome/logo-med.png"}} style={styles.logo} /> */}
                        <Text style={styles.textHeader}>DURABLE MEDICAL EQUIPMENT</Text>
                    </View>
                    <View style={{display: "flex",flexDirection: "row", justifyContent: "flex-end"}}>
                        <View style={{ backgroundColor:"#548ca3", padding: "0 20px"}}>
                            <Text style={{color: "white", fontFamily: "Poppins", fontWeight: 800, fontSize: 24, letterSpacing: 2, }}>INVOICE</Text>
                        </View>
                    </View>
                </View>
            <View style={styles.body}>
                <Text style={{marginTop: 25}}>Invoice Number:   {paymentRecords[1].invoiceNumber}</Text>
                <Text style={{marginBottom: 20}}>Invoice Date: {moment(paymentRecords[1].createdAt).format("LL")}</Text>
                <Text>Receipt Number:   {paymentRecords[1].receiptNumber}</Text>

                <View style={{borderBottom:"1px solid #548ca3", margin: "15px 0 25px 0"}}/>
                <View style={{display: "flex", flexDirection: "row"}}>
                    <View style={{flex: 1}}>
                        <Text>Billing From:</Text>
                        <Text style={{fontSize: 8, marginTop: 5}}>Durable Medical Equipment</Text>
                        <Text style={{fontSize: 8}}>10 Oakstone Ct Napa County, American Canyon, CA 94503</Text>
                        <Text style={{fontSize: 8}}>durableequipment@medical.com</Text>
                        <Text style={{fontSize: 8}}>(888) 558-9004</Text>
                    </View>
                    <View style={{width: 50}}/>
                    <View style={{flex: 1}}>
                        <Text>Billing To:</Text>
                        <Text style={{fontSize: 8, marginTop: 5}}>{order.customer.firstName} {order.customer.middleName || ""} {order.customer.lastName} {order.customer.suffix || ""}</Text>
                        <Text style={{fontSize: 8}}>{order.deliveryAddress} {order.deliveryCity} {order.deliveryState} {order.deliveryZipcode}</Text>
                        <Text style={{fontSize: 8}}>{order.customer.email}</Text>
                        <Text style={{fontSize: 8}}>{order.customer.contactNumber}</Text>
                    </View>
                </View>
                <View style={{margin: "10px 0"}}/>
                <View style={styles.table}>
                    <View style={styles.tableHeader}>
                        <View style={{...styles.tableHeaderCell, flex: 3}}>
                            <Text>Product Name</Text>
                        </View>
                        <View style={styles.tableHeaderCell}>
                            <Text>Quantity</Text>
                        </View>
                        <View style={styles.tableHeaderCell}>
                            <Text>Rental Months</Text>
                        </View>
                        <View style={styles.tableHeaderCell}>
                            <Text>Amount/day</Text>
                        </View>
                        <View style={styles.tableHeaderCell}>
                            <Text>Subtotal</Text>
                        </View>
                    </View>
                    {order.products.map((product) => (
                        <View style={styles.tableRow}>
                            <View style={{...styles.tableCell, flex: 3}}>
                                <Text>
                                    {product.productName}
                                </Text>
                            </View>
                            <View style={styles.tableCell}>
                                <Text>
                                    {product.quantity}
                                </Text>
                            </View>
                            <View style={styles.tableCell}>
                                <Text>
                                    {product.noOfRentalDays}
                                </Text>
                            </View>
                            <View style={styles.tableCell}>
                                <Text>
                                    ${product.productAmount}
                                </Text>
                            </View>
                            <View style={styles.tableCell}>
                                <Text>
                                    ${((parseInt(product.quantity) * parseFloat(product.productAmount)).toFixed(2)) * 2}
                                </Text>
                            </View>
                        </View>
                    ))}
                </View>
                <View style={{display: "flex", flexDirection: "column", alignItems: "flex-end", marginTop: 50}}>
                    <Text style={{fontSize: 8}}>Subtotal:     ${subtotal.toFixed(2)}</Text>
                    <Text style={{fontSize: 8, marginTop: 3}}>Total:     ${parseFloat(payment.totalOrderPayment).toFixed(2)}</Text>
                    <Text style={{fontSize: 8, marginTop: 3}}>Extra charges:     ${parseFloat(payment.extraCharges).toFixed(2)}</Text>
                    <Text style={{fontSize: 8, marginTop: 3}}>Over All Total:     ${parseFloat(payment.overallTotal).toFixed(2)}</Text>
                </View>
                <View style={{borderBottom:"0.1px solid #548ca3", margin: "10px 0"}}/>
                <View style={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
                    <Text style={{fontSize: 8, marginTop: 15}}>Initial Payment:     ${parseFloat(payment.totalPartialPayment).toFixed(2)}</Text>
                    <Text style={{fontSize: 8, marginTop: 3}}>Balance:     <Text style={{fontSize: 15}}>${(parseFloat(payment.overallTotal) - parseFloat(payment.totalPartialPayment)).toFixed(2)}</Text></Text>
                </View>
                <View style={{borderBottom:"0.1px solid #548ca3", margin: "10px 0"}}/>
                <View >
                    <Text style={{fontSize: 8, marginTop: 3}}>Terms:     Completion of payment (100%)</Text>
                    <Text style={{fontSize: 8, marginTop: 15}}>Full Payment:     ${(parseFloat(payment.overallTotal) - parseFloat(payment.totalPartialPayment)).toFixed(2)}</Text>
                </View>
            </View>
            </Page>
        </Document>
    )
  
};

export default RentFullInvoicePDF;
