import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Form, Image, InputNumber, Layout, Radio, Row, Tag, Typography, message, notification } from "antd";
import {
    PlusOutlined,
    MinusOutlined,
    ShoppingCartOutlined
  } from '@ant-design/icons';
import SideNav from "../../../components/SideNav";
import Navbar from "../../../components/Navbar";
import { useParams } from 'react-router-dom';

import "./style.scss"
import { inject, observer } from "mobx-react";

const MemberProductDetails = ({productStore, cartStore}) => {
    const user = JSON.parse(localStorage.getItem("userStore"))

    const { id } = useParams();
    const { Content } = Layout;
    const { Text } = Typography;
    const [form] = Form.useForm();

    const [quantity, setQuantity] = useState(1)
    const [rentDays, setRentDays] = useState(1)
    const [variants, setVariants] = useState([])
    const [selectedVariant, setSelectedVariant] = useState()

    const [subVariants, setSubVariants] = useState([])
    const [selectedSubVariant, setSelectedSubVariant] = useState()

    useEffect(() => {
        productStore.getProduct(id)
    },[])

    useEffect(() => {
        if(Object.keys(productStore.product).length > 0 ) {
            let options = []
            productStore.product.variants.forEach((variant) => {
                options = [
                    ...options,
                    {
                        key: variant.id,
                        label: variant.name,
                        value: variant
                    }
                ]
            })

            setVariants(options)
        }
    },[productStore.product])

    const onChange = ({ target: { value } }) => {
        if(value.subVariants.length === 0) {
            setSelectedVariant(value)
        } else {

            let options = []
            value.subVariants.forEach((subVariant) => {
                options = [
                    ...options,
                    {
                        key: subVariant.id,
                        label: subVariant.name,
                        value: subVariant
                    }
                ]
            })
            
            setSelectedVariant(value)
            setSubVariants(options)
            setSelectedSubVariant()
        }
    }

    const onChangeSubVariant = ({ target: { value } }) => {
        setSelectedSubVariant(value)
    }

    const onFinish = (values) => {
        if(quantity <= productStore.product.availableQuantity) {
            let data = {}
            if(selectedSubVariant) {
                if(productStore.product.type === "FOR_RENT") {
                    data = {
                        productID: id,
                        productVariantID: selectedVariant.id,
                        productSubVariantID: selectedSubVariant.id,
                        quantity: quantity,
                        noOfRentalDays: rentDays,
                        type: "RENT",
                        createdBy: user.id
                    }
                } else {
                    data = {
                        productID: id,
                        productVariantID: selectedVariant.id,
                        productSubVariantID: selectedSubVariant.id,
                        quantity: quantity,
                        noOfRentalDays: 0,
                        type: "BUY",
                        createdBy: user.id
                    }
                }
            } else if(selectedVariant) {
                if(productStore.product.type === "FOR_RENT") {
                    data = {
                        productID: id,
                        productVariantID: selectedVariant.id,
                        quantity: quantity,
                        noOfRentalDays: rentDays,
                        type: "RENT",
                        createdBy: user.id
                    }
                } else {
                    data = {
                        productID: id,
                        productVariantID: selectedVariant.id,
                        quantity: quantity,
                        noOfRentalDays: 0,
                        type: "BUY",
                        createdBy: user.id
                    }
                }
            } else {
                if(productStore.product.type === "FOR_RENT") {
                    data = {
                        productID: id,
                        quantity: quantity,
                        noOfRentalDays: rentDays,
                        type: "RENT",
                        createdBy: user.id
                    }
                } else {
                    data = {
                        productID: id,
                        quantity: quantity,
                        noOfRentalDays: 0,
                        type: "BUY",
                        createdBy: user.id
                    }
                }
            }
            cartStore.createCart(data)

            notification.success({
                top: 120,
                message: 'Added to cart!',
            });
        } else {
            message.error("The item has reached the maximum stock! Please adjust the quantity according to its availability.")
        }
    }

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                    {Object.keys(productStore.product).length > 0 ? (
                        <Content>
                            <div className="member-product-details member-product-details-min-height">
                                <Row>
                                    <Col span={10}>
                                        <Image className="product-image" src={`https://api.alwaysavailabledme.com/${productStore.product.image}`}/>
                                    </Col>
                                    <Col span={14}>
                                        <div className="product-details-container">
                                            <h1>{productStore.product.name}</h1>
                                            <p>
                                                <Text type="secondary">
                                                    {productStore.product.brand}
                                                </Text>
                                            </p>
                                            <p>
                                                <Text type={(productStore.product.availableQuantity && parseInt(productStore.product.availableQuantity) !== 0) ? "success" : "danger"}>
                                                    {(productStore.product.availableQuantity && parseInt(productStore.product.availableQuantity) !== 0) ? <small>In-Stock</small> : <small>Sold-Out</small>}
                                                </Text>
                                            </p>
                                            <Form
                                                form={form}
                                                onFinish={onFinish}
                                            >
                                                {productStore.product.type === "FOR_RENT" ? (
                                                    <>
                                                        <Tag color="#13c2c2">For Rent</Tag>
                                                        <Divider/>
                                                        <p>Select Variant</p>
                                                        <Radio.Group options={variants} onChange={onChange} optionType="button" buttonStyle="solid" />
                                                        {productStore.product.variants.length > 0 ? (
                                                            <>
                                                                {selectedVariant ? (
                                                                    <>
                                                                        {subVariants.length > 0 ? (
                                                                            <>
                                                                                <Divider/>
                                                                                <p>Select Sub Variant</p>
                                                                                <Radio.Group options={subVariants} onChange={onChangeSubVariant} optionType="button" buttonStyle="solid" />
                                                                                {selectedSubVariant ? (
                                                                                    <>
                                                                                        <Divider/>
                                                                                        <h2>
                                                                                            Price: <strong>${selectedSubVariant.price}/day</strong>
                                                                                        </h2>
                                                                                        <Text type="danger">
                                                                                            Damage or Lost Penalty: ${selectedSubVariant.damageCharges}
                                                                                        </Text>
                                                                                        <Form.Item name={"quantity"}>
                                                                                            <Row gutter={100} align="middle" className="row-number-container">
                                                                                                <Col >
                                                                                                    <Text>Quantity</Text>
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Row align="middle">
                                                                                                        <Col>
                                                                                                            <Button className="quantity-btn" type="default" disabled={quantity===0} onClick={() => setQuantity(quantity - 1)}>
                                                                                                                <MinusOutlined />
                                                                                                            </Button>
                                                                                                        </Col>
                                                                                                        <Col>
                                                                                                            <InputNumber
                                                                                                                className="quantity-input"
                                                                                                                defaultValue={quantity}
                                                                                                                min={1}
                                                                                                                max={selectedSubVariant.subVariantsAvailableQuantity}
                                                                                                                value={quantity}
                                                                                                            />
                                                                                                        </Col>
                                                                                                        <Col>
                                                                                                            <Button className="quantity-btn" type="default" onClick={() => setQuantity(quantity + 1)}>
                                                                                                                <PlusOutlined />
                                                                                                            </Button>
                                                                                                        </Col>
                                                                                                        <Text className="quantity-available" type="secondary"> {selectedSubVariant.subVariantsAvailableQuantity} available</Text>
                                                                                                    </Row>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Form.Item>
                                                                                        <Form.Item name={"rentedDays"}>
                                                                                            <Row gutter={50} align="middle" >
                                                                                                <Col >
                                                                                                    <Text>Total Rental Months</Text>
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Row >
                                                                                                        <Col>
                                                                                                            <Button className="quantity-btn" type="default" disabled={rentDays===0} onClick={() => setRentDays(rentDays - 1)}>
                                                                                                                <MinusOutlined />
                                                                                                            </Button>
                                                                                                        </Col>
                                                                                                        <Col>
                                                                                                            <InputNumber
                                                                                                                className="quantity-input"
                                                                                                                defaultValue={rentDays}
                                                                                                                min={1}
                                                                                                                value={rentDays}
                                                                                                            />
                                                                                                        </Col>
                                                                                                        <Col>
                                                                                                            <Button className="quantity-btn" type="default" onClick={() => setRentDays(rentDays + 1)}>
                                                                                                                <PlusOutlined />
                                                                                                            </Button>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Form.Item>
                                                                                    </>
                                                                                ):<></>}
                                                                            </>
                                                                        ): (
                                                                            <>
                                                                                <Divider/>
                                                                                <h2>
                                                                                    Price: <strong>${selectedVariant.price}/day</strong>
                                                                                </h2>
                                                                                <Text type="danger">
                                                                                    Damage or Lost Penalty: ${selectedVariant.damageCharges}
                                                                                </Text>
                                                                                <Form.Item name={"quantity"}>
                                                                                    <Row gutter={100} align="middle" className="row-number-container">
                                                                                        <Col >
                                                                                            <Text>Quantity</Text>
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Row align="middle">
                                                                                                <Col>
                                                                                                    <Button className="quantity-btn" type="default" disabled={quantity===0} onClick={() => setQuantity(quantity - 1)}>
                                                                                                        <MinusOutlined />
                                                                                                    </Button>
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <InputNumber
                                                                                                        className="quantity-input"
                                                                                                        defaultValue={quantity}
                                                                                                        min={1}
                                                                                                        max={selectedVariant.variantsAvailableQuantity}
                                                                                                        value={quantity}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Button className="quantity-btn" type="default" onClick={() => setQuantity(quantity + 1)}>
                                                                                                        <PlusOutlined />
                                                                                                    </Button>
                                                                                                </Col>
                                                                                                <Text className="quantity-available" type="secondary"> {selectedVariant.variantsAvailableQuantity} available</Text>
                                                                                            </Row>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Form.Item>
                                                                                <Form.Item name={"rentedDays"}>
                                                                                    <Row gutter={50} align="middle" >
                                                                                        <Col >
                                                                                            <Text>Total Rental Months</Text>
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Row >
                                                                                                <Col>
                                                                                                    <Button className="quantity-btn" type="default" disabled={rentDays===0} onClick={() => setRentDays(rentDays - 1)}>
                                                                                                        <MinusOutlined />
                                                                                                    </Button>
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <InputNumber
                                                                                                        className="quantity-input"
                                                                                                        defaultValue={rentDays}
                                                                                                        min={1}
                                                                                                        value={rentDays}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Button className="quantity-btn" type="default" onClick={() => setRentDays(rentDays + 1)}>
                                                                                                        <PlusOutlined />
                                                                                                    </Button>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Form.Item>
                                                                            </>
                                                                        )}
                                                                    </>
                                                                ):<></>}
                                                            </>
                                                        ):(
                                                            <>
                                                                <h2>
                                                                    Price: <strong>${productStore.product.price}/day</strong>
                                                                </h2>
                                                                <Text type="danger">
                                                                    Damage or Lost Penalty: ${productStore.product.damageCharges}
                                                                </Text>
                                                                <Form.Item name={"quantity"}>
                                                                    <Row gutter={100} align="middle" className="row-number-container">
                                                                        <Col >
                                                                            <Text>Quantity</Text>
                                                                        </Col>
                                                                        <Col>
                                                                            <Row align="middle">
                                                                                <Col>
                                                                                    <Button className="quantity-btn" type="default" disabled={quantity===0} onClick={() => setQuantity(quantity - 1)}>
                                                                                        <MinusOutlined />
                                                                                    </Button>
                                                                                </Col>
                                                                                <Col>
                                                                                    <InputNumber
                                                                                        className="quantity-input"
                                                                                        defaultValue={quantity}
                                                                                        min={1}
                                                                                        max={productStore.product.availableQuantity}
                                                                                        value={quantity}
                                                                                    />
                                                                                </Col>
                                                                                <Col>
                                                                                    <Button className="quantity-btn" type="default" onClick={() => setQuantity(quantity + 1)}>
                                                                                        <PlusOutlined />
                                                                                    </Button>
                                                                                </Col>
                                                                                <Text className="quantity-available" type="secondary"> {productStore.product.availableQuantity} available</Text>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>
                                                                <Form.Item name={"rentedDays"}>
                                                                    <Row gutter={50} align="middle" >
                                                                        <Col >
                                                                            <Text>Total Rental Months</Text>
                                                                        </Col>
                                                                        <Col>
                                                                            <Row >
                                                                                <Col>
                                                                                    <Button className="quantity-btn" type="default" disabled={rentDays===0} onClick={() => setRentDays(rentDays - 1)}>
                                                                                        <MinusOutlined />
                                                                                    </Button>
                                                                                </Col>
                                                                                <Col>
                                                                                    <InputNumber
                                                                                        className="quantity-input"
                                                                                        defaultValue={rentDays}
                                                                                        min={1}
                                                                                        value={rentDays}
                                                                                    />
                                                                                </Col>
                                                                                <Col>
                                                                                    <Button className="quantity-btn" type="default" onClick={() => setRentDays(rentDays + 1)}>
                                                                                        <PlusOutlined />
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>
                                                            </>
                                                        )}
                                                    </>
                                                ):(
                                                    <>
                                                        <Tag color="#108ee9">For Sale</Tag>
                                                        <Divider/>
                                                        <h2>
                                                            Price: <strong>${productStore.product.price}</strong>
                                                        </h2>
                                                        <Form.Item name={"quantity"}>
                                                            <Row gutter={100} align="middle" className="row-number-container">
                                                                <Col >
                                                                    <Text>Quantity</Text>
                                                                </Col>
                                                                <Col>
                                                                    <Row align="middle">
                                                                        <Col>
                                                                            <Button className="quantity-btn" type="default" disabled={quantity===0} onClick={() => setQuantity(quantity - 1)}>
                                                                                <MinusOutlined />
                                                                            </Button>
                                                                        </Col>
                                                                        <Col>
                                                                            <InputNumber
                                                                                className="quantity-input"
                                                                                defaultValue={quantity}
                                                                                min={1}
                                                                                max={productStore.product.availableQuantity}
                                                                                value={quantity}
                                                                            />
                                                                        </Col>
                                                                        <Col>
                                                                            <Button className="quantity-btn" type="default" onClick={() => setQuantity(quantity + 1)}>
                                                                                <PlusOutlined />
                                                                            </Button>
                                                                        </Col>
                                                                        <Text className="quantity-available" type="secondary"> {productStore.product.availableQuantity} available</Text>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form>
                                            <Divider/>
                                            <Row justify="end">
                                                <Button 
                                                    icon={<ShoppingCartOutlined />} 
                                                    type="primary" 
                                                    htmlType="submit"
                                                    onClick={() => form.submit()}
                                                >Add to Cart</Button>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="product-additional-details">
                                <h1>Description</h1>
                                <Divider/>
                                <p>
                                    <pre>
                                        {productStore.product.description}
                                    </pre>
                                </p>
                                
                            </div>
                        </Content>
                    ):<></>}
                    
            </Layout>
        </Layout>
    )
}

export default inject("productStore","cartStore")(observer(MemberProductDetails));