import React from "react";
import { Table, Space, Tag } from "antd";
import {
    EyeOutlined,
} from '@ant-design/icons';

import { Link } from "react-router-dom";

const ForPickUpTable = ({data}) => {

    const buyColumns = [
        {
            title: 'Order Number',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
        },
        {
            title: 'Customer ID',
            dataIndex: 'customer',
            key: 'customer',
            render: (customer) => (
                <span>{customer.idNumber}</span>
            )
        },
        {
            title: 'Customer Name',
            dataIndex: 'customer',
            key: 'customer',
            render: (customer) => (
                <span>{`${customer.firstName} ${customer.middleName || ""} ${customer.lastName} ${customer.suffix || ""}`}</span>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => (
                <Tag
                    color={
                        status === "PENDING" 
                        ? "blue" 
                        : status === "FOR_PAYMENT" 
                        ?  "geekblue"
                        : status === "PREPARING" 
                        ?  "orange"
                        : status === "TO_SHIP" 
                        ?  "lime"
                        : status === "OUT_FOR_DELIVERY" 
                        ?  "cyan"
                        : status === "DELIVERED" 
                        ?  "green"
                        : "red"
                    }
                >
                    {status}
                </Tag>
            )
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (id, record) => (
                <Space size={"middle"}>
                    <Link type="primary" to={`/admin/for-pickup/${id}`}><EyeOutlined /> View</Link>
                </Space>

            )
        },
    ]

    return (
        <Table columns={buyColumns} dataSource={data} pagination={false}/>
    )
}

export default ForPickUpTable