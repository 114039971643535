import React, { useEffect, useState } from "react";
import { Tabs, Divider, Layout } from "antd";

import SideNav from "../../../../components/SideNav";
import Navbar from "../../../../components/Navbar";
import PurchasedTable from "../../../../components/Admin/PurchasedTable";

import "./style.scss"

import { inject, observer } from "mobx-react";
import { cloneDeep } from 'lodash';

const OrdersPurchased = ({orderStore}) => {
    const { Content } = Layout;
    const { TabPane } = Tabs;

    const [currentTab, setCurrentTab] = useState("PENDING")

    useEffect(() => {
        orderStore.isSuccess = false;
        orderStore.getOrdersByTypeFilterByStatus("BUY", currentTab)
    },[])

    useEffect(() => {
        if(orderStore.isSuccess) {
            orderStore.getOrdersByTypeFilterByStatus("BUY", currentTab)
            setTimeout(() => {
                orderStore.isSuccess = false
            },1000)
        }
    },[orderStore.isSuccess])

    const tabs = [
        {
            tab: "Pending",
            key: "PENDING"
        },
        {
            tab: "For Payment",
            key: "FOR_PAYMENT"
        },
        {
            tab: "Preparing",
            key: "PREPARING"
        },
        {
            tab: "To Ship",
            key: "TO_SHIP"
        },
        {
            tab: "Out for Delivery",
            key: "OUT_FOR_DELIVERY"
        },
        {
            tab: "Delivered",
            key: "DELIVERED"
        },
    ];

    const onChangeTabs = (key) => {
        orderStore.getOrdersByTypeFilterByStatus("BUY", key)
        setCurrentTab(key)
    }

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="admin-orders-purchased">
                        <h3>Purchased Products</h3>
                        <Divider/>
                        <Tabs
                            defaultActiveKey="ALL"
                            type="card"
                            onChange={onChangeTabs}
                        >
                            {tabs.map((tab) => (
                                <TabPane tab={tab.tab} key={tab.key}>
                                    <PurchasedTable data={cloneDeep(orderStore.orders)}/>
                                </TabPane>
                            ))}
                        </Tabs>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject("orderStore")(observer(OrdersPurchased));