import { observable, action } from "mobx";
import axios from "axios";

class OrderStore {
  @observable orders = [];
  @observable order = {};

  @observable ordersForRent = [];
  @observable ordersForSale = [];
  @observable ordersOngoing = [];
  
  @observable ordersRequests = [];

  @observable loading = false;
  @observable isSuccess = false;

  @observable onError = false;
  @observable onErrorMess = "";

  @observable outForDelivery = false;
  @observable forDelivery = false;
  @observable forReturn = false;

  @action
  createOrder = (values) => {
    this.loading = true;

    axios
      .post(`/orders/create.php`, values)
      .then((response) => {
        if (response.status === 200) {
            this.isSuccess = true;
        }
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        this.loading = false;
        // this.onError = false;
      });
  };

  @action
  getOrdersByTypeFilterByStatus = (type,status) => {
    this.loading = true;

    axios
      .get(`/orders/get_all.php?type=${type}&status=${status}`)
      .then((response) => {
        if (response.status === 200) {
          this.orders = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.data);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };
  
  @action
  getOrdersByType = (type) => {
    this.loading = true;

    axios
      .get(`/orders/get_all.php?type=${type}`)
      .then((response) => {
        if (response.status === 200) {
          if(type === "FOR_RENT") {
            this.ordersForRent = response.data.data;
          } else if(type === "FOR_SALE"){
            this.ordersForSale = response.data.data;
          }
        }
      })
      .catch((error) => {
        console.log(error.response.data);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };

  @action
  getOrdersByDriver = (driverID, status) => {
    this.loading = true;

    axios
      .get(`/orders/get_all.php?driverID=${driverID}&status=${status}`)
      .then((response) => {
        if (response.status === 200) {
          this.orders = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.data);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };

  @action
  geOwntOrdersByTypeFilterByStatus = (userID, type, status) => {
    this.loading = true;

    axios
      .get(`/orders/get_all.php?userID=${userID}&type=${type}&status=${status}`)
      .then((response) => {
        if (response.status === 200) {
          this.orders = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.data);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };

  @action
  geOwntOrdersRentOngoing = (userID, type, status) => {
    this.loading = true;

    axios
      .get(`/orders/get_all.php?userID=${userID}&type=${type}&status=${status}`)
      .then((response) => {
        if (response.status === 200) {
          this.ordersOngoing = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.data);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };

  @action
  getOrder = (orderID) => {
    this.loading = true;

    axios
      .get(`/orders/get.php?id=${orderID}`)
      .then((response) => {
        if (response.status === 200) {
          this.order = response.data.data;

          if(response.data.data.status === "TO_SHIP"){
            this.outForDelivery = true;
          }else if(response.data.data.status === "OUT_FOR_DELIVERY"){
            this.forDelivery = true;
          }else if(response.data.data.status === "FOR_RETURN") {
            this.forReturn = true;
          }
        }
      })
      .catch((error) => {
        console.log(error.response);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };

  @action
  updateOrder = (orderID, data) => {
    this.loading = true;

    axios
      .patch(`/orders/update.php?id=${orderID}`, data)
      .then((response) => {
          if(response.status === 201) {
              this.isSuccess = true;
          }
      })
      .catch((error) => {
          console.log(error.response.data);
          this.onError = true;
          this.onErrorMess = error.response.data.message
      })
      .finally(() => {
          this.loading = false;
          this.onError = false;
      });
  };

  @action
  updateOrderOutForDelivery = (orderID, data) => {
    this.loading = true;

    axios
      .patch(`/orders/update.php?id=${orderID}`, data)
      .then((response) => {
          if(response.status === 201) {
              this.isSuccess = true;
          }
      })
      .catch((error) => {
          console.log(error.response.data);
          this.onError = true;
          this.onErrorMess = error.response.data.message
      })
      .finally(() => {
          this.loading = false;
          this.onError = false;
      });
  };

  @action
  updateOrderForReturn = (orderID, data) => {
    this.loading = true;

    axios
      .patch(`/orders/update.php?id=${orderID}`, data)
      .then((response) => {
          if(response.status === 201) {
              this.isSuccess = true;
          }
      })
      .catch((error) => {
          console.log(error.response.data);
          this.onError = true;
          this.onErrorMess = error.response.data.message
      })
      .finally(() => {
          this.loading = false;
          this.onError = false;
      });
  };

  @action
  updateOrderDelivered = (orderID, data) => {
    this.loading = true;

    axios
      .patch(`/orders/update.php?id=${orderID}`, data)
      .then((response) => {
          if(response.status === 201) {
              this.isSuccess = true;
          }
      })
      .catch((error) => {
          console.log(error.response.data);
          this.onError = true;
          this.onErrorMess = error.response.data.message
      })
      .finally(() => {
          this.loading = false;
          this.onError = false;
      });
  };

  @action
  updateOrderProductSerial = (orderProductSerialID, serialCode, productID, data) => {
    this.loading = true;

    axios
      .patch(`/orders_product_serial_code/update.php?id=${orderProductSerialID}&serialCode=${serialCode}&productID=${productID}`, data)
      .then((response) => {
          if(response.status === 201) {
              window.location.reload()
          }
      })
      .catch((error) => {
          console.log(error.response.data);
          this.onError = true;
          this.onErrorMess = error.response.data.message
      })
      .finally(() => {
          this.loading = false;
          this.onError = false;
      });
  };

  @action
  deleteOrder = (orderID) => {
    this.loading = true;

    axios
      .delete(`/orders/delete.php?id=${orderID}`)
      .then((response) => {
          if(response.status === 200) {
              this.isSuccess = true;
          }
      })
      .catch((error) => {
          console.log(error.response.data);
      })
      .finally(() => {
          this.loading = false;
          this.onError = false;
      });
  };


  // ORDER REQUEST
  @action
  createOrderRequest = (data) => {
    this.loading = true;

    axios
      .post(`/requests/create.php?id=${data.orderID}`, data)
      .then((response) => {
          if(response.status === 200) {
            this.isSuccess = true;
          }
      })
      .catch((error) => {
          console.log(error.response.data);
      })
      .finally(() => {
          this.loading = false;
          this.onError = false;
      });
  };

  @action
  getOrderRequests = (orderID) => {
    this.loading = true;

    axios
      .get(`/requests/get_all.php?id=${orderID}`)
      .then((response) => {
          if(response.status === 200) {
            this.ordersRequests = response.data.data;
          }
      })
      .catch((error) => {
          console.log(error.response.data);
      })
      .finally(() => {
          this.loading = false;
          this.onError = false;
      });
  };

  @action
  updateOrderRequest = (requestID, data) => {
    this.loading = true;

    axios
      .patch(`/requests/update.php?id=${requestID}`, data)
      .then((response) => {
          if(response.status === 201) {
            this.isSuccess = true;
          }
      })
      .catch((error) => {
          console.log(error.response.data);
      })
      .finally(() => {
          this.loading = false;
          this.onError = false;
      });
  };

}

export default OrderStore;
