import React, { useEffect } from "react";
import { Table, Divider, Layout, Space, Popconfirm, Typography, Tag } from "antd";
import {
    EyeOutlined,
    EditOutlined,
    UserDeleteOutlined,
    QuestionCircleOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import {Link} from 'react-router-dom';

import SideNav from "../../../components/SideNav";
import Navbar from "../../../components/Navbar";

import "./style.scss"

import { inject, observer } from "mobx-react";
import { cloneDeep } from 'lodash';

const Users = ({userStore}) => {
    const { Content } = Layout;

    useEffect(() => {
      userStore.getUsersByType("CUSTOMER")
    },[])

    const onChangeUserStatus = (user) => {
      let status
  
      if(user.status === "ACTIVE") {
        status = "DEACTIVATED"
      } else if(user.status === "DEACTIVATED") {
         status = "ACTIVE"
      }
  
      userStore.updateUser(
        user.id,
        {
          ...user,
          'status': status
        }
      )
  
      setTimeout(() => {
        userStore.getUsersByType("CUSTOMER")
      }, 1000)
    }

    const columns = [
        {
          title: 'Account Number',
          dataIndex: 'idNumber',
          key: 'idNumber',
        },
        {
          title: 'Username',
          dataIndex: 'username',
          key: 'username',
        },
        {
          title: 'First Name',
          dataIndex: 'firstName',
          key: 'firstName',
        },
        {
            title: 'Middle Name',
            dataIndex: 'middleName',
            key: 'middleName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Suffix',
            dataIndex: 'suffix',
            key: 'suffix',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => (
                <>
                  <Tag
                    color={
                      status === "ACTIVE"
                        ? "green"
                        : "red"
                    }
                  >
                    {status}
                  </Tag>
                </>
              ),
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (id, record) => (
                <Space size={'middle'}>
                    <Link to={`/admin/users/${id}`}><EyeOutlined /> View</Link>
                    {record.status === "ACTIVE" ? (
                        <Popconfirm
                            title="Deactivate user"
                            description="Are you sure to deactivate this user?"
                            onConfirm={() => onChangeUserStatus(record)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Typography.Link type='danger'><UserDeleteOutlined /> Deactivate</Typography.Link>
                        </Popconfirm>
                    ):(
                        <Popconfirm
                            title="Activate user"
                            description="Are you sure to activate this user?"
                            onConfirm={() => onChangeUserStatus(record)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Typography.Link type='success'><UserDeleteOutlined /> Activate</Typography.Link>
                        </Popconfirm>
                    )}
                    
                  <Popconfirm
                    title="Delete user"
                    description="Are you sure to delete this user?"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    onConfirm={async() => {
                      await userStore.deleteUser(id)
                      setTimeout(() => {
                        userStore.getUsersByType("CUSTOMER")
                      },1000)
                    }}
                    okText="Yes"
                  >
                    <Typography.Link type='danger'><DeleteOutlined /> Delete</Typography.Link>
                  </Popconfirm>
                </Space>
            )
        },
      ];

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="admin-users-list">
                        <h3>Users List</h3>
                        <Divider/>
                        <Table dataSource={cloneDeep(userStore.users)} columns={columns} />
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject('userStore')(observer(Users));