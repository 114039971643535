import { observable, action } from "mobx";
import axios from "axios";

class PaymentStore {
  @observable payments = [];
  @observable payment = {};
  @observable payment_records = [];

  @observable loading = false;
  @observable isSuccess = false;

  @observable onError = false;
  @observable onErrorMess = "";

  @action
  getPayments = (status) => {
    this.loading = true;

    axios
      .get(`/payments/get_all.php?status=${status}`)
      .then((response) => {
        if (response.status === 200) {
          this.payments = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.data);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };

  @action
  getPayment = (paymentID) => {
    this.loading = true;

    axios
      .get(`/payments/get.php?id=${paymentID}`)
      .then((response) => {
        if (response.status === 200) {
          this.payment = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };

  @action
  getPaymentByOrdeID = (orderID) => {
    this.loading = true;

    axios
      .get(`/payments/get.php?orderID=${orderID}`)
      .then((response) => {
        if (response.status === 200) {
          this.payment = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };

  @action
  getPaymentRecords = (paymentID) => {
    this.loading = true;

    axios
      .get(`/payment_records/get_all.php?paymentID=${paymentID}`)
      .then((response) => {
        if (response.status === 200) {
          this.payment_records = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };

  @action
  updatePayment = (paymentID, data) => {
    this.loading = true;

    axios
      .patch(`/payments/update.php?id=${paymentID}`, data)
      .then((response) => {
          if(response.status === 201) {
              // this.isSuccess = true;
              window.location.reload()
          }
      })
      .catch((error) => {
          console.log(error.response.data);
          this.onError = true;
          this.onErrorMess = error.response.data.message
      })
      .finally(() => {
          this.loading = false;
          this.onError = false;
      });
  };
}

export default PaymentStore;
