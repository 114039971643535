import React, { useEffect, useState } from "react";
import { Tabs, Divider, Layout } from "antd";

import SideNav from "../../../components/SideNav";
import Navbar from "../../../components/Navbar";
import OngoingTable from "../../../components/Admin/OngoingTable";

import "./style.scss"
import { inject, observer } from "mobx-react";
import { cloneDeep } from 'lodash';

const OngoingRentals = ({orderStore}) => {
    const { Content } = Layout;
    const { TabPane } = Tabs;

    const [currentTab, setCurrentTab] = useState("DELIVERED")

    useEffect(() => {
        orderStore.isSuccess = false;
        orderStore.getOrdersByTypeFilterByStatus("RENT", currentTab)
    },[])

    useEffect(() => {
        if(orderStore.isSuccess) {
            orderStore.getOrdersByTypeFilterByStatus("RENT", currentTab)
            setTimeout(() => {
                orderStore.isSuccess = false
            },1000)
        }
    },[orderStore.isSuccess])

    const tabs = [
        {
            tab: "Ongoing",
            key: "DELIVERED"
        },
        {
            tab: "Due Date",
            key: "DUE_DATE"
        },
        {
            tab: "For Return",
            key: "FOR_RETURN"
        },
        {
            tab: "Picked up",
            key: "PAID"
        },
        
    ];

    const onChangeTabs = (key) => {
        orderStore.getOrdersByTypeFilterByStatus("RENT", key)
        setCurrentTab(key)
    }

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="admin-ongoing-rentals">
                        <h3>Ongoing Rentals</h3>
                        <Divider/>
                        <Tabs
                            defaultActiveKey="ALL"
                            type="card"
                            onChange={onChangeTabs}
                        >
                            {tabs.map((tab) => (
                                <TabPane tab={tab.tab} key={tab.key}>
                                    <OngoingTable data={cloneDeep(orderStore.orders)}/>
                                </TabPane>
                            ))}
                        </Tabs>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject("orderStore")(observer(OngoingRentals));