import React, { useEffect, useState } from "react";
import { Divider, Layout, Row, Col, Button, Form, Input, DatePicker, Select, InputNumber, Upload, Modal, message, notification } from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory  } from 'react-router-dom';

import SideNav from "../../../../components/SideNav";
import Navbar from "../../../../components/Navbar";

import "./style.scss"
import { inject, observer } from "mobx-react";

const AddEmployee = ({attachmentStore, userStore}) => {
    const { Content } = Layout;
    const { TextArea } = Input;
    const navigation = useHistory()

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [userImage, setUserImage] = useState();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    useEffect(() => {
        attachmentStore.images = [];
        userStore.isSuccess = false;
        userStore.onError = false;
        userStore.onErrorMess = "";
    },[])

    useEffect(() => {
        if(attachmentStore.images.length > 0){
            setUserImage(attachmentStore.images[0])
        }
    },[attachmentStore.images])

    useEffect(() => {
        console.log(userStore.isSuccess)
        if(userStore.isSuccess){
            notification.success({
                top: 120,
                message: 'User created successfully!',
            });

            setTimeout(() => {
                navigation.goBack()
            }, 1000)
        }
    }, [userStore.isSuccess]);

    useEffect(() => {
        if(userStore.onError){
            notification.error({
                top: 120,
                message: 'Create User Error',
                description: userStore.onErrorMess
            });
        }
    }, [userStore.onError]);


    const ageCalculator = (birthdate) => {
        const today = new Date();
        const birthDate = new Date(birthdate);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        if(!isNaN(age)) {
            return age;
        } else {
            return ""
        }
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

    const props = {
        beforeUpload: (file) => {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJPG) {
                    message.error('You can only upload JPG or PNG file!');
                    return false;
                } else {
                    return true;
                }
        },
        customRequest: async (info) => {
            let formData = new FormData();
            formData.append('image', info.file)
            await attachmentStore.uploadImage(formData)
            setTimeout(() => {
                if(attachmentStore.isSuccess) {
                    info.onSuccess("ok")
                    setTimeout(() => {
                        attachmentStore.isSuccess = false
                    }, 500)
                } else {
                    info.onError("error")
                }
            }, 1000);

        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log("uploading")
            }
            if (status === 'done') {
                console.log("done")
            } else if (status === 'error') {
                console.log("error")
            }
        },
        onDrop(e) {
            console.log("drop")
        },
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const onFinish = (values) => {
        if(attachmentStore.images.length > 0) {
            const data = {
                ...values,
                image: attachmentStore.images[attachmentStore.images.length -1]?.imagePath,
                middleName: values.middleName || "",
                suffix: values.suffix || "",
                status: "ACTIVE"
            }
            
            userStore.createUser(data)
        } else {
            message.error("Please upload image!")
        }
        
    }

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="admin-employees-add">
                        <Row justify="space-between">
                          <h3>Add New Employee</h3>
                        </Row>
                        <Divider/>
                        <div className="content-container">
                            <Form
                                form={form}
                                onFinish={onFinish}
                                layout="vertical"
                            >
                                <Row gutter={25}>
                                    <Col span={12}>
                                        <Form.Item label="User Type" name={"userType"} rules={[{ required: true, message: 'Please select User Type!' }]}>
                                            <Select
                                                options={[
                                                    {value: "SUPERADMIN", label: "Super Admin"},
                                                    {value: "WAREHOUSE_ADMIN", label: "Warehouse Admin"},
                                                    {value: "WAREHOUSE_STAFF", label: "Warehouse Staff"},
                                                    {value: "ACCOUNTING_ADMIN", label: "Accounting Admin"},
                                                    {value: "DRIVER", label: "Driver"},
                                                    {value: "SUPPORT", label: "Support"},
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Position"
                                            name="position"
                                            rules={[{ required: true, message: 'Please input Position!' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Divider/>
                                        <Form.Item>
                                            <h4>Personal Information</h4>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label="Upload Profile Image">
                                            <Upload
                                                name="image"
                                                listType="picture-card"
                                                className="avatar-uploader"
                                                multiple={false}
                                                maxCount={1}
                                                onPreview={handlePreview}
                                                {...props}
                                            >
                                                {attachmentStore.images.length === 1 ? null : uploadButton}
                                            </Upload>
                                            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
                                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item
                                            label="First Name"
                                            name="firstName"
                                            rules={[{ required: true, message: 'Please input First Name!' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item
                                            label="Middle Name"
                                            name="middleName"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item
                                            label="Last Name"
                                            name="lastName"
                                            rules={[{ required: true, message: 'Please input Last Name!' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label="Suffix"
                                            name="suffix"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Date of Birth" name="birthDate" rules={[{ required: true, message: 'Please input Date of Birth!' }]}>
                                            <DatePicker onChange={async(date,dateString) => {
                                                const age = await ageCalculator(dateString)
                                                form.setFieldsValue({
                                                    'age': age
                                                })
                                            }} style={{width:'100%'}} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Age" name="age" rules={[{ required: true, message: 'Please input Age!' }]}>
                                            <Input readOnly/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Gender" name="gender" rules={[{ required: true, message: 'Please input Gender!' }]}>
                                            <Select
                                                options={[
                                                    { value: 'MALE', label: 'Male' },
                                                    { value: 'FEMALE', label: 'Female' },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Divider/>
                                        <Form.Item>
                                            <h4>Contact Information</h4>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Contact Number" name="contactNumber" rules={[{ required: true, message: 'Please input Contact Number!' }]}>
                                            <InputNumber style={{width:'100%'}}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input Email!' }]}>
                                            <Input type="email"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Divider/>
                                        <Form.Item>
                                            <h4>Address Information</h4>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label="Address" name="address" rules={[{ required: true, message: 'Please input Address!' }]}>
                                            <TextArea rows={2}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="State" name="state" rules={[{ required: true, message: 'Please input State!' }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="City" name="city" rules={[{ required: true, message: 'Please input City!' }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Zipcode" name="zipcode" rules={[{ required: true, message: 'Please input Zipcode!' }]}>
                                            <InputNumber minLength={6} style={{width:'100%'}}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Divider/>
                                        <Form.Item>
                                            <h4>Login Information</h4>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Username"
                                            name="username"
                                            rules={[{ required: true, message: 'Please input Username!' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Password"
                                            name="password"
                                            rules={[{ required: true, message: 'Please input Password!' }]}
                                        >
                                            <Input.Password />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        <Divider/>
                        <Row justify="end" gutter={15}>
                            <Col>
                                <Button type="primary" htmlType="submit" onClick={() => form.submit()}>Submit</Button>
                            </Col>
                            <Col>
                                <Button onClick={() => navigation.goBack()}>Back</Button>
                            </Col>
                        </Row>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject('attachmentStore','userStore')(observer(AddEmployee));