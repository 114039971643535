import React, { useEffect, useState } from "react";
import { Table, Divider, Layout, Space, Popconfirm, Typography, Row, Button, Tag } from "antd";
import {
    EyeOutlined,
    EditOutlined,
    QuestionCircleOutlined,
    DeleteOutlined,
    PlusOutlined
} from '@ant-design/icons';
import {Link} from 'react-router-dom';

import SideNav from "../../../../components/SideNav";
import Navbar from "../../../../components/Navbar";

import "./style.scss"

import { inject, observer } from "mobx-react";
import { cloneDeep, forEach } from 'lodash';

const WarehouseBinsProducts = ({productStore}) => {
    const { Content } = Layout;

    const [products, setProducts] = useState([])

    useEffect(() => {
      productStore.getProducts();
    },[])

    useEffect(() => {
      if(productStore.products.length > 0) {

        let list = []

        productStore.products.forEach((product) => {
          list = [
            ...list,
            {
              key: product.id,
              ...product
            }
          ]
      
        })
        
        setProducts(list)
      }
    },[productStore.products])

    const columns = [
      {
        title: 'Brand Name',
        dataIndex: 'brand',
        key: 'brand',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: 'Available',
        dataIndex: 'availableQuantity',
        key: 'availableQuantity',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (type) => (
          <Tag 
            color={
              type === "FOR_RENT"
              ? "cyan"
              : "blue"
            }
          >{type}</Tag>
        )
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        render: (price, record) => (
          <span>
            {record["variants"].length > 0 
            ? "" 
            : `${parseFloat(price).toFixed(2)}`}
          </span>
        )
      },
      {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: (id, record) => (
            <Space size={'middle'}>
              {record.variants.length === 0 ? (
                <Link to={`/admin/products/${id}/stocks`}><EyeOutlined /> Stocks</Link>
              ):<></>}
              <Link to={`/admin/products/${id}`}><EyeOutlined /> View</Link>
              <Link to={`/admin/products/${id}/edit`}><EditOutlined /> Edit</Link>
              <Popconfirm
                title="Delete product"
                description="Are you sure to delete this product?"
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                onConfirm={async() => {
                  await productStore.deleteProduct(id)
                  setTimeout(() => {
                    productStore.getProducts();
                  },1000)
                }}
                okText="Yes"
              >
                <Typography.Link type='danger'><DeleteOutlined /> Delete</Typography.Link>
              </Popconfirm>
            </Space>
          )
        },
      ];

    const expandedRowRender = (row) => {
      row.variants.forEach((variant) => {
        variant.key = variant.id
      })

      const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          key: 'quantity',
        },
        {
          title: 'Available',
          dataIndex: 'variantsAvailableQuantity',
          key: 'variantsAvailableQuantity',
        },
        {
          title: 'Price',
          dataIndex: 'price',
          key: 'price',
          render: (price, record) => (
            <span>
              {record["subVariants"].length > 0 
              ? "" 
              : `${parseFloat(price).toFixed(2)}`}
            </span>
          )
        },
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: (id, record) => (
            <Space size={'middle'}>
              {record.subVariants.length === 0 ? (
                <Link to={`/admin/products/${record.productID}/stocks-variant/${id}`}><EyeOutlined /> Stocks</Link>
              ):<></>}
            </Space>
          )
        },
      ];
  
      return <Table 
        columns={columns} 
        dataSource={row.variants} 
        pagination={false} 
        expandable={{ 
          expandedRowRender: (row) => row.subVariants.length > 0 ? subVariantExpandedRowRender(row) : null,
          rowExpandable: (row) => row.subVariants.length > 0 
        }}
      />;
    };

    const subVariantExpandedRowRender = (row) => {
      const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          key: 'quantity',
        },
        {
          title: 'Available',
          dataIndex: 'subVariantsAvailableQuantity',
          key: 'subVariantsAvailableQuantity',
        },
        {
          title: 'Price',
          dataIndex: 'price',
          key: 'price',
          render: (price) => (
            <span>${parseFloat(price).toFixed(2)}</span>
          )
        },
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: (id, record) => (
            <Space size={'middle'}>
              <Link to={`/admin/products/${record.productID}/stocks-variant/${record.productVariantID}/sub-variant/${id}`}><EyeOutlined /> Stocks</Link>
            </Space>
          )
        },
      ];
  
      return <Table 
        columns={columns} 
        dataSource={row.subVariants} 
        pagination={false} 
      />;
    };

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="admin-warehouse-bin-product-list">
                        <Row justify="space-between">
                          <h3>Product List</h3>
                          <Link to={'/admin/products/add'}>
                            <Button icon={<PlusOutlined/>} type="primary">Add Product</Button>
                          </Link>
                        </Row>
                        <Divider/>
                        <Table 
                          dataSource={cloneDeep(products)} 
                          columns={columns} 
                          expandable={{ 
                            expandedRowRender: (row) => row.variants.length > 0 ? expandedRowRender(row) : null,
                            rowExpandable: (row) => row.variants.length > 0 
                          }}
                        />
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject("productStore")(observer(WarehouseBinsProducts));