import { createContext } from 'react'
import { create } from "mobx-persist";
import UserStore from './UserStore'
import AttachmentStore from './AttachmentStore';
import WarehouseBinStore from './WarehouseBinStore';
import ProductStore from './ProductStore';
import StockStore from './StockStore';
import CartStore from './CartStore';
import OrderStore from './OrderStore';
import PaymentStore from './PaymentStore';
import ReportStore from './ReportStore';

const hydrate = create({
    storage: localStorage,
    jsonify: true,
});

class RootStore {
    attachmentStore = new AttachmentStore()
    userStore = new UserStore()
    warehouseBinStore = new WarehouseBinStore()
    productStore = new ProductStore()
    stockStore = new StockStore()
    cartStore = new CartStore()
    orderStore = new OrderStore()
    paymentStore = new PaymentStore()
    reportStore = new ReportStore()

    constructor() {
        hydrate("userStore", this.userStore)
        .then((userStore) => {
            console.log("data persisted");
        })
    }
}

export const RootStoreContext = createContext(new RootStore())