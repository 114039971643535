import React, { useEffect, useState } from "react";
import { Descriptions, Divider, Button, Image, Layout, Table, Tag, Row, Col } from "antd";

import { useHistory, useParams } from 'react-router-dom';
import moment from "moment-timezone";

import SideNav from "../../../../components/SideNav";
import Navbar from "../../../../components/Navbar";

import "./style.scss"

import { inject, observer } from "mobx-react";
import { cloneDeep } from 'lodash';

const ViewForPickUp = ({orderStore}) => {
    const {id} = useParams();
    const navigation = useHistory()
    const { Content } = Layout;

    const [products, setProducts] = useState([])
    const [deliveredDate, setDeliveredDate] = useState()

    useEffect(() => {
        orderStore.getOrder(id)
    }, [])

    useEffect(() => {
        if(Object.keys(orderStore.order).length > 0) {
            const toReturnProducts = orderStore.order.products?.filter((product) => (
                moment().isSame(moment(deliveredDate).add(parseInt(product.noOfRentalDays), 'days')) 
                || moment(moment(deliveredDate).add(5, 'days')).isAfter(moment(deliveredDate).add(parseInt(product.noOfRentalDays), 'days'))
            ))
            // orderStore.order.products?.map((product) => {
            //     console.log("today",moment().format("LL"))
            //     console.log("dueDate",moment(deliveredDate).add(parseInt(product.noOfRentalDays), 'days').format("LL"))
            //     console.log("isSame", moment(moment(deliveredDate).add(parseInt(product.noOfRentalDays), 'days')).isSame(moment(deliveredDate).add(parseInt(product.noOfRentalDays), 'days')))
            //     console.log("isAfter", moment().isAfter(moment(deliveredDate).add(parseInt(product.noOfRentalDays), 'days')))
            //     console.log("isSame or isAfter", (moment().isSame(moment(deliveredDate).add(parseInt(product.noOfRentalDays), 'days')) || moment(moment(deliveredDate).add(5, 'days')).isAfter(moment(deliveredDate).add(parseInt(product.noOfRentalDays), 'days'))))
               
            // })
            setProducts(toReturnProducts)
        }
    }, [orderStore.order])

    useEffect(() => {
        if(orderStore.order.orderTracker?.length > 0) {
            const isDelivered = orderStore.order.orderTracker.find((tracker) => tracker.status === "DELIVERED")
            if(isDelivered) {
                setDeliveredDate(isDelivered.createdAt)
            }
        }
    }, [orderStore.order.orderTracker])

    const columnsPending = [
        {
            title: 'Product Image',
            dataIndex: 'productImage',
            key: 'productImage',
            render: (productImage) => (
                <Image className="product-image" src={`https://api.alwaysavailabledme.com/${productImage}`}/>
            )
        },
        {
            title: 'Product Name',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Price',
            dataIndex: 'productAmount',
            key: 'productAmount',
            render: (productAmount) => (
                <span>${parseFloat(productAmount).toFixed(2)}</span>
            )
        },
    ];

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="member-orders-rental-details">
                        <h3>For Delivery Details</h3>
                        <Divider/>
                        {Object.keys(orderStore.order).length > 0 ? (
                            <div className="content-container">
                                <Descriptions column={3}>
                                    <Descriptions.Item label="Order Number" span={1}>{orderStore.order.orderNumber}</Descriptions.Item>
                                    <Descriptions.Item label="Date" span={1}>{moment.utc(orderStore.order.createdAt).format('LLL')}</Descriptions.Item>
                                    <Descriptions.Item label="Status" span={1}>
                                        <Tag 
                                            color={
                                                orderStore.order.status === "PENDING" 
                                                ? "blue" 
                                                : orderStore.order.status === "FOR_PAYMENT" 
                                                ?  "geekblue"
                                                : orderStore.order.status === "PREPARING" 
                                                ?  "orange"
                                                : orderStore.order.status === "TO_SHIP" 
                                                ?  "lime"
                                                : orderStore.order.status === "OUT_FOR_DELIVERY" 
                                                ?  "cyan"
                                                : orderStore.order.status === "DELIVERED" 
                                                ?  "green"
                                                : "red"
                                            }
                                        >{orderStore.order.status}</Tag>
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider/>
                                <h4>Customer Information</h4>
                                <Descriptions column={2} bordered>
                                    <Descriptions.Item label="Customer Number" span={2}>{orderStore.order.customer?.idNumber}</Descriptions.Item>
                                    <Descriptions.Item label="First Name" span={1}>{orderStore.order.customer?.firstName}</Descriptions.Item>
                                    <Descriptions.Item label="Middle Name" span={1}>{orderStore.order.customer?.middleName}</Descriptions.Item>
                                    <Descriptions.Item label="Last Name" span={1}>{orderStore.order.customer?.lastName}</Descriptions.Item>
                                    <Descriptions.Item label="Suffix" span={1}>{orderStore.order.customer?.suffix}</Descriptions.Item>
                                    <Descriptions.Item label="Contact Number" span={1}>{orderStore.order.customer?.contactNumber}</Descriptions.Item>
                                    <Descriptions.Item label="Email" span={1}>{orderStore.order.customer?.email}</Descriptions.Item>
                                </Descriptions>
                                <Divider/>
                                <h4>Shipping Information</h4>
                                <Descriptions column={1} layout="vertical" bordered>
                                    <Descriptions.Item label="Shipping Address">{`${orderStore.order.deliveryAddress} ${orderStore.order.deliveryCity} ${orderStore.order.deliveryState} ${orderStore.order.deliveryZipcode}`}</Descriptions.Item>
                                    <Descriptions.Item label="Note">{orderStore.order.note}</Descriptions.Item>
                                </Descriptions>
                                <Divider/>
                                <h4>Products Information</h4>
                                <Table
                                    columns={columnsPending}
                                    dataSource={cloneDeep(products)}
                                    pagination={false}
                                />
                            </div>
                        ):<></>}
                        <Divider/>
                        <Row justify="end" gutter={10}>
                            <Col>
                                <Button onClick={() => navigation.goBack()}> Back</Button>
                            </Col>
                        </Row>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject("orderStore", "paymentStore")(observer(ViewForPickUp));