import React, { useEffect } from "react";
import { Tabs, Divider, Layout } from "antd";

import SideNav from "../../../components/SideNav";
import Navbar from "../../../components/Navbar";
import PurchasedTable from "../../../components/Member/PurchasedTable";
import RentalsTable from "../../../components/Member/RentalsTable";

import "./style.scss"

import { inject, observer } from "mobx-react";
import { cloneDeep } from 'lodash';


const MemberOrdersRentals = ({orderStore}) => {
    const user = JSON.parse(localStorage.getItem("userStore"))
    // const navigation = useHistory()

    const { Content } = Layout;
    const { TabPane } = Tabs;

    useEffect(() => {
        orderStore.geOwntOrdersByTypeFilterByStatus(user.id, "RENT", "PENDING")
        orderStore.geOwntOrdersRentOngoing(user.id, "RENT", "DELIVERED")
    }, [])

    const tabs = [
        {
            tab: "Pending",
            key: "PENDING"
        },
        {
            tab: "For Payment",
            key: "FOR_PAYMENT"
        },
        {
            tab: "Preparing",
            key: "PREPARING"
        },
        {
            tab: "To Ship",
            key: "TO_SHIP"
        },
        {
            tab: "Out for Delivery",
            key: "OUT_FOR_DELIVERY"
        },
        {
            tab: "Delivered",
            key: "DELIVERED"
        },
        {
            tab: "For Return",
            key: "FOR_RETURN"
        },
        {
            tab: "For Pick up",
            key: "FOR_PICK_UP"
        },
        {
            tab: "For Full Payment",
            key: "FOR_FULL_PAYMENT"
        },
        {
            tab: "Completed",
            key: "COMPLETED"
        },
    ];

    const onChangeTabs = (key) => {
        orderStore.geOwntOrdersByTypeFilterByStatus(user.id, "RENT", key)
    }

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    {/* <div className="member-orders-buy">
                        <h3>On-Going Rentals</h3>
                        <Divider/>
                        <PurchasedTable data={cloneDeep(orderStore.ordersOngoing)}/>
                    </div> */}
                    <div className="member-orders-buy">
                        <h3>Rentals</h3>
                        <Divider/>
                        <Tabs
                            defaultActiveKey="ALL"
                            type="card"
                            onChange={onChangeTabs}
                        >
                            {tabs.map((tab) => (
                                <TabPane tab={tab.tab} key={tab.key}>
                                    <RentalsTable data={cloneDeep(orderStore.orders)}/>
                                </TabPane>
                            ))}
                        </Tabs>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject("orderStore")(observer(MemberOrdersRentals));