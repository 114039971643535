import React from "react";
import {Layout, Badge, Row, Col, Avatar } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

import './style.scss'
import { Link } from "react-router-dom";
import { Modal } from "antd";
import { WarningFilled } from "@ant-design/icons";
import Logo from "../../assets/img/backgroundhome/logo-med.png";


import { inject, observer } from 'mobx-react'

const { Header } = Layout;
const { confirm } = Modal;

const Navbar = ({ userStore }) => {

    const showConfirm = () => {
        confirm({
          title: 'Warning!',
          icon: <WarningFilled />,
          content: 'Are you sure you want to logout?',
          okText: 'yes',
          cancelText: 'no',
          onOk() {
            onOkLogout()
          },
          onCancel() {},
        })
    }

    const onOkLogout = () => {
        localStorage.removeItem('userStore')
        localStorage.removeItem('ACCESS_TOKEN')
        localStorage.removeItem("SESSION_TOKEN")
        localStorage.removeItem('AuthStore')
        window.location.href = '/login'
    }

    return (
        <>
            <Header>
                <Row justify='space-between' align="middle" gutter={15}>
                    <Col>
                        <img src={Logo} className="nav-logo"/>
                    </Col>
                    <Row align='middle'>
                        <Col>
                            <h1>{userStore.firstName} {userStore.lastName}</h1>
                            <small>@{userStore.username}</small>
                        </Col>
                        <Col>
                            <span className="right-icon" onClick={showConfirm}>
                                <LogoutOutlined/>
                            </span>
                        </Col>
                    </Row>
                </Row>
                {/* <div className="header-content">
                    <div className="right">
                        <span className="avatar-item">
                            <Badge className="user">
                                <span className="name">Name: <Link to="#">{userStore.firstName} {userStore.lastName}</Link> - Username: <Link to="#">{userStore.username}</Link></span>
                            </Badge>
                            <Badge className="mobile-view">
                                <p className="name">Name: <Link to="#">{userStore.firstName} {userStore.lastName}</Link></p>
                                <p className="name">Username: <Link to="#">{userStore.username}</Link></p>
                            </Badge>
                        </span>
                        <span className="right-icon" onClick={showConfirm}>
                            Log Out<LogoutOutlined/>
                        </span>
                    </div>
                </div> */}
            </Header>
        </>
    );
}

export default inject('userStore')(observer(Navbar))
