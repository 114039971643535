import React from "react";
import { Table, Space, Tag, Typography, Popconfirm } from "antd";
import {
    EyeOutlined,
    WalletOutlined
} from '@ant-design/icons';
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";

const OngoingTable = ({data, orderStore}) => {

    const updateOrder = (status, record) => {
        let totalPartialPayment = 0;
        record.products.forEach((product) => {
            totalPartialPayment = totalPartialPayment + product.productAmount
        });

        const data = {
            customerID: record.createdBy,
            status: status,
            totalAmount: record.totalAmount,
            totalPartialPayment: totalPartialPayment,
            type: "RENT",
        }
        
        orderStore.updateOrder(record.id, data)
    }
    
    const buyColumns = [
        {
            title: 'Order Number',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
        },
        {
            title: 'Customer ID',
            dataIndex: 'customer',
            key: 'customer',
            render: (customer) => (
                <span>{customer.idNumber}</span>
            )
        },
        {
            title: 'Customer Name',
            dataIndex: 'customer',
            key: 'customer',
            render: (customer) => (
                <span>{`${customer.firstName} ${customer.middleName || ""} ${customer.lastName} ${customer.suffix || ""}`}</span>
            )
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => (
                <span>{moment.utc(createdAt).format("LLL")}</span>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => (
                <Tag
                    color={
                        status === "DELIVERED" 
                        ?  "blue"
                        : "red"
                    }
                >
                    {status === "DELIVERED" ? "ON_GOING" : status}
                </Tag>
            )
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (id, record) => (
                <Space size={"middle"}>
                    <Link type="primary" to={`/admin/ongoing-rentals/${id}`}><EyeOutlined /> View</Link>
                    {record.status === "PENDING" ? (
                        <Popconfirm
                            title="Are you sure you want to proceed this order to payment?"
                            description=""
                            onConfirm={() => updateOrder("FOR_PAYMENT", record)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Typography.Link><WalletOutlined /> For Payment</Typography.Link>
                        </Popconfirm>
                    ): <></>}
                </Space>

            )
        },
    ]

    return (
        <Table columns={buyColumns} dataSource={data} pagination={false}/>
    )
}

export default inject("orderStore")(observer(OngoingTable))