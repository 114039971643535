import { observable, action } from "mobx";
import axios from "axios";

class WarehouseBinStore {
  @observable warehouseBins = [];
  @observable warehouseBin = {};

  @observable loading = false;
  @observable isSuccess = false;

  @observable onError = false;
  @observable onErrorMess = "";

  @action
  createWarehouseBin = (values) => {
    this.loading = true;

    axios
      .post(`/warehousebins/create.php`, values)
      .then((response) => {
        if (response.status === 200) {
          this.isSuccess = true;
        }
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        this.loading = false;
        // this.onError = false;
      });
  };

  @action
  getWarehouseBins = () => {
    this.loading = true;

    axios
      .get(`/warehousebins/get_all.php`)
      .then((response) => {
        if (response.status === 200) {
          this.warehouseBins = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.data);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };

  @action
  getWarehouseBin = (warehouseBinID) => {
    this.loading = true;

    axios
      .get(`/warehousebins/get.php?id=${warehouseBinID}`)
      .then((response) => {
        if (response.status === 200) {
          this.warehouseBin = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };

  @action
  updateWarehouseBin = (warehouseBinID, data) => {
    this.loading = true;

    axios
      .patch(`/warehousebins/update.php?id=${warehouseBinID}`, data)
      .then((response) => {
          if(response.status === 201) {
              this.isSuccess = true;
          }
      })
      .catch((error) => {
          console.log(error.response.data);
          this.onError = true;
          this.onErrorMess = error.response.data.message
      })
      .finally(() => {
          this.loading = false;
          this.onError = false;
      });
  };

  @action
  deleteWarehouseBin = (warehouseBinID) => {
    this.loading = true;

    axios
      .delete(`/warehousebins/delete.php?id=${warehouseBinID}`)
      .then((response) => {
          if(response.status === 200) {
              this.isSuccess = true;
          }
      })
      .catch((error) => {
          console.log(error.response.data);
      })
      .finally(() => {
          this.loading = false;
          this.onError = false;
      });
  };
}

export default WarehouseBinStore;
