import React, { useEffect } from "react";
import { Image, Divider, Layout, Row, Descriptions, Tag, Button, Col } from "antd";
import { useHistory, useParams } from 'react-router-dom';
import moment from "moment-timezone";

import SideNav from "../../../../components/SideNav";
import Navbar from "../../../../components/Navbar";

import "./style.scss"

import { inject, observer } from "mobx-react";


const ViewUsers = ({userStore}) => {
    const { Content } = Layout;
    const navigation = useHistory()

    const { id } = useParams();

    useEffect(() => {
        userStore.getUser(id)
    },[])

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="admin-users-view">
                        <Row justify="space-between">
                          <h3>User Information</h3>
                        </Row>
                        <Divider/>
                        {Object.keys(userStore.user).length > 0 ? (
                            <div className="content-container">
                                <Descriptions column={2} >
                                    <Descriptions.Item label="Account Number">{userStore.user.idNumber}</Descriptions.Item>
                                    <Descriptions.Item label="Created Date">{moment.utc(userStore.user.createdAt).format('LL')}</Descriptions.Item>
                                    <Descriptions.Item label="Status">
                                        <Tag
                                            color={
                                            userStore.user.status === "ACTIVE"
                                                ? "green"
                                                : "red"
                                            }
                                        >
                                            {userStore.user.status}
                                        </Tag>
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider/>
                                <Descriptions column={2} bordered>
                                    <Descriptions.Item label="User Image" span={2}>
                                        <Image className="user-image" src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D&w=1000&q=80"/>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Username" span={2}>{userStore.user.username}</Descriptions.Item>
                                    <Descriptions.Item label="First Name">{userStore.user.firstName}</Descriptions.Item>
                                    <Descriptions.Item label="Middle Name">{userStore.user.middleName}</Descriptions.Item>
                                    <Descriptions.Item label="Last Name">{userStore.user.lastName}</Descriptions.Item>
                                    <Descriptions.Item label="Suffix">{userStore.user.suffix}</Descriptions.Item>
                                    <Descriptions.Item label="Birth Date">{userStore.user.birthDate}</Descriptions.Item>
                                    <Descriptions.Item label="Age">{userStore.user.age}</Descriptions.Item>
                                    <Descriptions.Item label="Gender">{userStore.user.gender}</Descriptions.Item>
                                    <Descriptions.Item label="Contact Number">{userStore.user.contactNumber}</Descriptions.Item>
                                    <Descriptions.Item label="Email Address">{userStore.user.email}</Descriptions.Item>
                                </Descriptions>
                            </div>
                        ): <></>}
                        <Divider/>
                        <Row justify="end">
                            <Button onClick={() => navigation.goBack()}>Back</Button>
                        </Row>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject('userStore')(observer(ViewUsers));