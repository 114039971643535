import React, { useEffect } from "react";
import { Table, Divider, Layout, Space, Popconfirm, Typography, Tag, Row, Button } from "antd";
import {
    EyeOutlined,
    EditOutlined,
    UserDeleteOutlined,
    QuestionCircleOutlined,
    DeleteOutlined,
    PlusOutlined
} from '@ant-design/icons';

import {Link} from 'react-router-dom';

import SideNav from "../../../components/SideNav";
import Navbar from "../../../components/Navbar";

import "./style.scss"

import { inject, observer } from "mobx-react";
import { cloneDeep } from 'lodash';

const Employees = ({userStore}) => {
    const { Content } = Layout;

    useEffect(() => {
      userStore.getUsers()
    },[])

    const onChangeUserStatus = (user) => {
      let status
  
      if(user.status === "ACTIVE") {
        status = "DEACTIVATED"
      } else if(user.status === "DEACTIVATED") {
         status = "ACTIVE"
      }
  
      userStore.updateUser(
        user.id,
        {
          ...user,
          'status': status
        }
      )
  
      setTimeout(() => {
        userStore.getUsers()
      }, 1000)
    }

    const columns = [
        {
          title: 'Employee Number',
          dataIndex: 'idNumber',
          key: 'idNumber',
        },
        {
          title: 'Username',
          dataIndex: 'username',
          key: 'username',
        },
        {
          title: 'First Name',
          dataIndex: 'firstName',
          key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
          title: 'Position',
          dataIndex: 'position',
          key: 'position',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => (
              <>
                <Tag
                  color={
                    status === "ACTIVE"
                      ? "green"
                      : "red"
                  }
                >
                  {status}
                </Tag>
              </>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (id, record) => (
                <Space size={'middle'}>
                    <Link to={`/admin/employees/${id}`}><EyeOutlined /> View</Link>
                    <Link to={`/admin/employees/${id}/edit`}><EditOutlined /> Edit</Link>
                    {record.status === "ACTIVE" ? (
                        <Popconfirm
                            title="Deactivate employee"
                            description="Are you sure to deactivate this employee?"
                            onConfirm={() => onChangeUserStatus(record)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Typography.Link type='danger'><UserDeleteOutlined /> Deactivate</Typography.Link>
                        </Popconfirm>
                    ):(
                        <Popconfirm
                            title="Activate employee"
                            description="Are you sure to activate this employee?"
                            onConfirm={() => onChangeUserStatus(record)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Typography.Link type='success'><UserDeleteOutlined /> Activate</Typography.Link>
                        </Popconfirm>
                    )}


                  <Popconfirm
                    title="Delete employee"
                    description="Are you sure to delete this employee?"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    onConfirm={async() => {
                      await userStore.deleteUser(id)
                      setTimeout(() => {
                        userStore.getUsers()
                      },1000)
                    }}
                    okText="Yes"
                  >
                    <Typography.Link type='danger'><DeleteOutlined /> Delete</Typography.Link>
                  </Popconfirm>
                </Space>
            )
        },
      ];

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="admin-employee-list">
                        <Row justify="space-between">
                          <h3>Employee List</h3>
                          <Link to={'/admin/employees/add'}>
                            <Button icon={<PlusOutlined/>} type="primary">Add Employee</Button>
                          </Link>
                        </Row>
                        <Divider/>
                        <Table dataSource={cloneDeep(userStore.users)} columns={columns} />
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject('userStore')(observer(Employees));