import React, { useEffect, useState } from "react";
import { Checkbox, Col, Divider, Tag, Layout, Row, Table, Typography, InputNumber, Input, Button, Modal, Form, notification } from "antd";
import {
    EnvironmentOutlined
  } from '@ant-design/icons';
import { useHistory } from "react-router-dom"

import SideNav from "../../../components/SideNav";
import Navbar from "../../../components/Navbar";

import "./style.scss"
import { inject, observer } from "mobx-react";

const MemberBulkPurchaseOrder = ({productStore, orderStore}) => {
    const user = JSON.parse(localStorage.getItem("userStore"))
    const navigation = useHistory()

    const { Content } = Layout;
    const { TextArea } = Input;
    const [form] = Form.useForm();

    const [selectedProducts, setSelectedProducts] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        orderStore.isSuccess = false
        productStore.getProductsByType("FOR_SALE");
        form.setFieldsValue(user)
    },[])

    useEffect(() => {
        if(orderStore.isSuccess) {
            notification.success({
                top: 120,
                message: 'Order submitted successfully!',
            });

            setTimeout(() => {
                navigation.push("/orders/purchased")
            }, 1000)

        }
    },[orderStore.isSuccess])

    const onChangeCheckProducts = (e) => {
        if(e.target.checked) {
            setSelectedProducts([...selectedProducts, e.target.value])
        } else {
            const filtered = selectedProducts.filter((product) => product.id !== e.target.value.id)
            setSelectedProducts(filtered)
        }
    }

    const onChangeCheckProductVariants = (e) => {
        if(e.target.checked) {
            const findExist = selectedProducts.find((product) => product.id === e.target.value.productID)
            if(findExist) {
                const filtered = selectedProducts.filter((product) => product.id !== e.target.value.productID)

                const data = {
                    ...findExist,
                    variants: [
                        ...findExist.variants,
                        e.target.value
                    ]
                }
                setSelectedProducts([...filtered, data])
            } else {
                const product = productStore.productsForSale.find((product) => product.id === e.target.value.productID)
                if(product) {
                    const data = {
                        ...product,
                        variants: [
                            e.target.value
                        ]
                    }
                    setSelectedProducts([...selectedProducts, data])
                }
            }
        } else {
            const findExist = selectedProducts.find((product) => product.id === e.target.value.productID)
            if(findExist) {
                if(findExist.variants.length === 1) {
                    const filtered = selectedProducts.filter((product) => product.id !== e.target.value.productID)
                    setSelectedProducts(filtered)
                } else {
                    const filtered = selectedProducts.filter((product) => product.id !== e.target.value.productID)
                    const filteredVariants = findExist.variants.filter((variant) => variant.id === e.target.value.id)

                    const data = {
                        ...findExist,
                        variants: filteredVariants
                    }
                    setSelectedProducts([...filtered, data])
                }
            }
            
        }
    }

    const onChangeCheckProductSubVariants = (e) => {
        if(e.target.checked) {
            const findExist = selectedProducts.find((product) => product.id === e.target.value.productID)
            if(findExist) {
                const filteredProducts = selectedProducts.filter((product) => product.id !== e.target.value.productID)
                const findExistVariant = findExist.variants.find((variant) => variant.id === e.target.value.productVariantID)
                if(findExistVariant) {
                    const productVariant = {
                        ...findExistVariant,
                        subVariants: [
                            ...findExistVariant.subVariants.filter((subVariant) => subVariant.id !== e.target.value.id),
                            e.target.value
                        ]
                    }

                    const data = {
                        ...findExist,
                        variants: [
                            ...findExist.variants.filter((variant) => variant.id !== e.target.value.productVariantID),
                            productVariant
                        ]
                    }

                    setSelectedProducts([...filteredProducts, data])
                } else {
                    const product = productStore.productsForSale.find((product) => product.id === e.target.value.productID)
                    if(product) {
                        const filteredProducts = selectedProducts.filter((product) => product.id !== e.target.value.productID)
                        const productVariant = product.variants.find((variant) => variant.id === e.target.value.productVariantID)
                        if(productVariant) {
                            const dataVariant = {
                                ...productVariant,
                                subVariants: [
                                    e.target.value
                                ]
                            }
    
                            const data = {
                                ...findExist,
                                variants: [
                                    ...findExist.variants,
                                    dataVariant
                                ]
                            }
    
                            setSelectedProducts([...filteredProducts, data])
                        }
                    }
                }
            } else {
                const product = productStore.productsForSale.find((product) => product.id === e.target.value.productID)
                if(product) {
                    const productVariant = product.variants.find((variant) => variant.id === e.target.value.productVariantID)
                    if(productVariant) {
                        const dataVariant = {
                            ...productVariant,
                            subVariants: [
                                e.target.value
                            ]
                        }

                        const data = {
                            ...product,
                            variants: [
                                dataVariant
                            ]
                        }

                        setSelectedProducts([...selectedProducts, data])
                    }
                }
            }
        } else {
            const findExist = selectedProducts.find((product) => product.id === e.target.value.productID)
            if(findExist) {
                if(findExist.variants.length === 1) {
                    const filtered = selectedProducts.filter((product) => product.id !== e.target.value.productID)
                    setSelectedProducts(filtered)
                } else {
                    const filtered = selectedProducts.filter((product) => product.id !== e.target.value.productID)
                    const filteredVariants = findExist.variants.filter((variant) => variant.id === e.target.value.id)

                    const data = {
                        ...findExist,
                        variants: filteredVariants
                    }
                    setSelectedProducts([...filtered, data])
                }
            }
            
        }
    }


    const columns = [
        {
            title: 'Product Brand',
            dataIndex: 'brand',
            key: 'brand',
        },
        {
            title: 'Product Name',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => (
                <span>
                    {name}
                    <br/>
                    {record.productSubVariantID != null ? (
                        <>
                            <Tag>{record.variant.name}</Tag>
                            <Tag>{record.subVariant.name}</Tag>
                        </>
                    ): record.productVariantID != null ? (
                        <Tag>{record.variant.name}</Tag>
                    ):<></>}
                </span>
            )
        },
        {
            title: 'Quantity',
            dataIndex: 'availableQuantity',
            key: 'availableQuantity',
            render: (availableQuantity, record) => (
                record.variants.length === 0 ? (
                    <>
                        <InputNumber max={availableQuantity} min={1} onChange={(value) => record.quantity = value}/> <Typography.Text type="secondary">({availableQuantity} avilable)</Typography.Text>
                    </>
                ):<></>
            )
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (price, record) => (
                record.variants.length === 0 ? (
                    <span>
                        {record.productSubVariantID != null ? (
                            `$${parseFloat(record.subVariant.price).toFixed(2)}`
                        ): record.productVariantID != null ? (
                            `$${parseFloat(record.variant.price).toFixed(2)}`
                        ):(`$${parseFloat(price).toFixed(2)}`)}
                        <Typography.Text type="secondary">/pc</Typography.Text>
                    </span>
                ):<></>
            )
        },
    ]

    const expandedRowRender = (row) => {
        row.variants.forEach((variant) => {
            variant.key = variant.id
            variant.noOfRentalDays = 0
        })
    
        const columns = [
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
            },
            {
                title: 'Quantity',
                dataIndex: 'variantsAvailableQuantity',
                key: 'variantsAvailableQuantity',
                render: (variantsAvailableQuantity, record) => (
                    record.subVariants.length === 0 ? (
                        <>
                            <InputNumber max={variantsAvailableQuantity} min={1} onChange={(value) => record.quantity = value}/> <Typography.Text type="secondary">({variantsAvailableQuantity} avilable)</Typography.Text>
                        </>
                    ) : <></>
                )
            },
            {
                title: 'Price',
                dataIndex: 'price',
                key: 'price',
                render: (price, record) => (
                    record.subVariants.length === 0 ? (
                        <span>
                            {record.productSubVariantID != null ? (
                                `$${parseFloat(record.subVariant.price).toFixed(2)}`
                            ):(`$${parseFloat(price).toFixed(2)}`)}
                            <Typography.Text type="secondary">/pc</Typography.Text>
                        </span>
                    ) : <></>
                )
            },
          ];
      
          return <Table 
            columns={columns} 
            dataSource={row.variants} 
            pagination={false} 
            expandable={{ 
              expandedRowRender: (row) => row.subVariants.length > 0 ? subVariantExpandedRowRender(row) : null,
              rowExpandable: (row) => row.subVariants.length > 0 
            }}
        />;
    };

    const subVariantExpandedRowRender = (row) => {
        row.subVariants.forEach((variant) => {
            variant.key = variant.id
        })
    
        const columns = [
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
            },
            {
                title: 'Quantity',
                dataIndex: 'subVariantsAvailableQuantity',
                key: 'subVariantsAvailableQuantity',
                render: (subVariantsAvailableQuantity, record) => (
                    <>
                        <InputNumber max={subVariantsAvailableQuantity} min={1} onChange={(value) => record.quantity = value}/> <Typography.Text type="secondary">({subVariantsAvailableQuantity} avilable)</Typography.Text>
                    </>
                )
            },
            {
                title: 'Rental Months',
                key: 'noOfRentalDays',
                render: (text, record) => (
                    <InputNumber min={1} onChange={(value) => record.noOfRentalDays = value}/>
                )
            },
            {
                title: 'Price',
                dataIndex: 'price',
                key: 'price',
                render: (price, record) => (
                    <span>
                        {record.productSubVariantID != null ? (
                            `$${parseFloat(record.subVariant.price).toFixed(2)}`
                        ):(`$${parseFloat(price).toFixed(2)}`)}
                        <Typography.Text type="secondary">/pc</Typography.Text>
                    </span>
                )
            },
          ];
      
          return <Table 
            columns={columns} 
            dataSource={row.subVariants} 
            pagination={false} 
        />;
    };

    const onSubmit = () => {
        let totalAmount = 0;

        selectedProducts.forEach((selectedProduct) => {
            if(selectedProduct.variants.length === 0) {
                totalAmount = totalAmount + (parseInt(selectedProduct.quantity) * parseFloat(selectedProduct.price).toFixed(2))
            }

            selectedProduct.variants.forEach((variant) => {
                if(variant.subVariants.length === 0) {
                    totalAmount = totalAmount + (parseInt(variant.quantity) * parseFloat(variant.price).toFixed(2))
                }

                variant.subVariants.forEach((subVariant) => {
                    totalAmount = totalAmount + (parseInt(subVariant.quantity) * parseFloat(subVariant.price).toFixed(2))
                })
            })
        })

        setTotalAmount(totalAmount)
        setOpenModal(true)
    }

    const onFinish = (values) => {
        const data = {
            typeOfOrder: "BULK",
            type: "BUY",
            totalAmount: totalAmount.toFixed(2),
            deliveryAddress: values.address,
            deliveryCity: values.city,
            deliveryState: values.state,
            deliveryZipcode: values.zipcode,
            note: values.note || "",
            createdBy: user.id,
            products: selectedProducts
        }

        orderStore.createOrder(data)
    }

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="member-dashboard">
                        <h2>For Sale Items</h2>
                        <div className="member-bulk-rental-order">
                            <Row gutter={[30,30]}>
                                {productStore.productsForSale.map((product) => (
                                    product.variants.length === 0 ? (
                                        <Col span={12}>
                                            <Checkbox onChange={onChangeCheckProducts} value={product}>{product.name} ({product.brand})</Checkbox>
                                        </Col>
                                    ):<></>
                                ))}
                            </Row>
                            <Divider/>
                            <Row gutter={[30,30]}>
                                {productStore.productsForSale.map((product) => (
                                    product.variants.length > 0 ? (
                                        <Col span={12}>
                                            <p>{product.name} ({product.brand})</p>
                                            {product.variants.map((variant) => (
                                                <div className="variant-container">
                                                    {variant.subVariants.length > 0 ? (
                                                        <Col span={12}>
                                                            <p>{variant.name}</p>
                                                            {variant.subVariants.map((subVariant) => (
                                                                <div className="variant-container">
                                                                    <Checkbox onChange={onChangeCheckProductSubVariants} value={subVariant}>{subVariant.name}</Checkbox>
                                                                </div>
                                                            ))}
                                                        </Col>
                                                    ):(
                                                        <Checkbox onChange={onChangeCheckProductVariants} value={variant}>{variant.name}</Checkbox>
                                                    )}
                                                </div>
                                            ))}
                                        </Col>
                                    ):<></>
                                ))}
                            </Row>
                            <Divider/>
                            <div>
                                <Table 
                                    dataSource={selectedProducts} 
                                    columns={columns}
                                    expandable={{
                                        expandedRowRender: (row) => expandedRowRender(row),
                                        rowExpandable: (row) => row.variants.length > 0,
                                    }}
                                />
                            </div>
                            <Divider/>
                            <Row justify={"end"}>
                                <Button type="primary" onClick={onSubmit}>Submit</Button>
                            </Row>
                        </div>
                    </div>
                    <Modal
                        visible={openModal}
                        title={"Review Address"}
                        footer={null}
                        onCancel={() => setOpenModal(false)}
                    >
                        <Form
                            name="normal_register"
                            className="register-form"
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                        >
                            <Form.Item 
                                name="address"
                                label="Address"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Address!',
                                    },
                                ]}
                            >
                                <Input
                                    maxLength={12}
                                    prefix={<EnvironmentOutlined className="site-form-item-icon" />}
                                    placeholder="Address"
                                />
                            </Form.Item>
                            <Form.Item 
                                name="city"
                                label="City"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your City!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<EnvironmentOutlined className="site-form-item-icon" />}
                                    placeholder="City"
                                />
                            </Form.Item>
                            <Form.Item 
                                name="state"
                                label="State"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your State!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<EnvironmentOutlined className="site-form-item-icon" />}
                                    placeholder="State"
                                />
                            </Form.Item>
                            <Form.Item 
                                name="zipcode"
                                label="Zipcode"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Zipcode!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<EnvironmentOutlined className="site-form-item-icon" />}
                                    placeholder="Zipcode"
                                    minLength={6}
                                />
                            </Form.Item>
                            <Form.Item 
                                name="note"
                                label="Add Note"
                            >
                                <TextArea
                                    placeholder="Add Note for your order..."
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>Confirm Order</Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject("productStore","orderStore")(observer(MemberBulkPurchaseOrder));