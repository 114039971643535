import React, { useEffect } from "react";
import { Badge, Col, Divider, Image, Layout, Row, Typography } from "antd";
import SideNav from "../../../components/SideNav";
import Navbar from "../../../components/Navbar";
import { Link } from "react-router-dom";

import "./style.scss"
import { inject, observer } from "mobx-react";

const MemberDashboard = ({productStore}) => {
    const { Content } = Layout;
    const { Text } = Typography;

    useEffect(() => {
        productStore.getProductsByType("FOR_RENT");
        productStore.getProductsByType("FOR_SALE");
    },[])

    const getVariantPriceRange = (variants) => {
        // Initialize variables to store the highest and lowest prices
        let highestPrice = null;
        let lowestPrice = null;
      
        // Loop through the variants array to find the highest and lowest prices
        variants.forEach((variant) => {
            if(variant.subVariants.length > 0) {
                variant.subVariants.forEach((subVariant) => {
                    const price = parseFloat(subVariant.price); // Convert the price to a number
                
                    // Update the highest and lowest prices if necessary
                    if (highestPrice === null || price > highestPrice) {
                        highestPrice = price;
                    }
                    if (lowestPrice === null || price < lowestPrice) {
                        lowestPrice = price;
                    }
                });
            } else {
                const price = parseFloat(variant.price); 
      
                // Update the highest and lowest prices if necessary
                if (highestPrice === null || price > highestPrice) {
                    highestPrice = price;
                }
                if (lowestPrice === null || price < lowestPrice) {
                    lowestPrice = price;
                }
            }
        });
      
        return `${lowestPrice} ~ ${highestPrice}`;
      };

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="member-dashboard">
                        <h2>For Rental Items</h2>
                        <Row gutter={[30,30]}>
                            {productStore.productsForRent.map((product) => (
                                <Col span={6}>
                                    <Badge.Ribbon text="For Rent" color="cyan">
                                        <div className="product-card">
                                            <Image className="product-image" src={`https://api.alwaysavailabledme.com/${product.image}`} width={"100%"}/>
                                            <div className="product-card-details">
                                                <h3>
                                                    <Link to={`/product-description/${product.id}`}>{product.name}</Link>
                                                </h3>
                                                <p>
                                                    <Text type="secondary">
                                                        <small>{product.brand}</small>
                                                    </Text>
                                                </p>
                                                <p>
                                                    <Text type={(product.availableQuantity && parseInt(product.availableQuantity) !== 0) ? "success" : "danger"}>
                                                        {(product.availableQuantity && parseInt(product.availableQuantity) !== 0) ? <small>In-Stock</small> : <small>Sold-Out</small>}
                                                    </Text>
                                                </p>
                                                <Row gutter={20} justify="space-between" className="price-container">
                                                    <Col>
                                                        <p>
                                                            <Text strong>
                                                                {(product.variants.length > 0) 
                                                                    ?  getVariantPriceRange(product.variants)
                                                                    : `${product.price}`}
                                                                
                                                            </Text> / day
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Badge.Ribbon>
                                </Col>
                            ))}
                        </Row>
                        <Divider/>
                        <h2>For Sale Items</h2>
                        <Row gutter={[30,30]}>
                            {productStore.productsForSale.map((product) => (
                                <Col span={6}>
                                    <Badge.Ribbon text="For Sale">
                                        <div className="product-card">
                                            <Image className="product-image" src={`https://api.alwaysavailabledme.com/${product.image}`} width={"100%"}/>
                                            <div className="product-card-details">
                                                <h3>
                                                    <Link to={`/product-description/${product.id}`}>{product.name}</Link>
                                                </h3>
                                                <p>
                                                    <Text type="secondary">
                                                        <small>{product.brand}</small>
                                                    </Text>
                                                </p>
                                                <p>
                                                    <Text type={(product.availableQuantity && parseInt(product.availableQuantity) !== 0) ? "success" : "danger"}>
                                                        {(product.availableQuantity && parseInt(product.availableQuantity) !== 0) ? <small>In-Stock</small> : <small>Sold-Out</small>}
                                                    </Text>
                                                </p>
                                                <Row gutter={20} justify="space-between" className="price-container">
                                                    <Col>
                                                        <p>
                                                            <Text strong>
                                                                {(product.variants.length > 0) 
                                                                    ?  getVariantPriceRange(product.variants)
                                                                    : `${product.price}`}
                                                            </Text>
                                                        </p>
                                                    </Col>
                                                    <Col>
                                                        <p>
                                                            <Text type="secondary">
                                                                {product.soldQuantity} Sold
                                                            </Text>
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Badge.Ribbon>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject("productStore")(observer(MemberDashboard));