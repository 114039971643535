import React, { useEffect, useState } from "react";
import { Table, Divider, Layout, Typography, Row, Button } from "antd";
import {
    PlusOutlined,
    FileExcelOutlined
} from '@ant-design/icons';
import {Link} from 'react-router-dom';
// import { ExcelFile, ExcelSheet, ExcelColumn } from 'react-excel-export';

import SideNav from "../../../components/SideNav";
import Navbar from "../../../components/Navbar";

import "./style.scss"

import { inject, observer } from "mobx-react";
import { cloneDeep } from 'lodash';

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Reports = ({productStore, reportStore}) => {
    const { Content } = Layout;

    const [forRentProducts, setForRentProducts] = useState([])
    const [forSaleProducts, setForSaleProducts] = useState([])
    const [totalPurchasedSale, setTotalPurchasedSale] = useState(0)
    const [totalRentalSale, setTotalRentalSale] = useState(0)

    const [totalPurchasedInvoice, setTotalPurchasedInvoice] = useState(0)
    const [totalUnpaidRentalInvoice, setTotalUnpaidRentalInvoice] = useState(0)
    const [totalPartialRentalInvoice, setTotalPartialRentalInvoice] = useState(0)


    useEffect(() => {
      productStore.getProducts();
      reportStore.getPurchasedSalesReport();
      reportStore.getRentalSalesReport();
      reportStore.getPurchasedInvoiceReport();
      reportStore.getRentalUnpaidInvoiceReport();
      reportStore.getRentalPartialInvoiceReport();
    },[])

    useEffect(() => {
      if(productStore.products.length > 0) {
        setForRentProducts(
          productStore.products.filter((product) =>  product.type === "FOR_RENT")
        )
        setForSaleProducts(
          productStore.products.filter((product) =>  product.type === "FOR_SALE")
        )
      } 
    },[productStore.products])

    useEffect(() => {
      if(reportStore.purchasedSalesReport.length > 0) {
        reportStore.purchasedSalesReport.forEach((product) => {
          setTotalPurchasedSale(totalPurchasedSale + parseFloat(product.totalSales))
        })
      } 
    },[reportStore.purchasedSalesReport])

    useEffect(() => {
      if(reportStore.rentalSalesReport.length > 0) {
        reportStore.rentalSalesReport.forEach((product) => {
          setTotalRentalSale(totalRentalSale + parseFloat(product.totalSales))
        })
      } 
    },[reportStore.rentalSalesReport])

    useEffect(() => {
      if(reportStore.purchasedInvoiceReport.length > 0) {
        reportStore.purchasedInvoiceReport.forEach((product) => {
          setTotalPurchasedInvoice(totalPurchasedInvoice + parseFloat(product.totalSales))
        })
      } 
    },[reportStore.purchasedInvoiceReport])

    useEffect(() => {
      if(reportStore.rentalUnpaidInvoiceReport.length > 0) {
        reportStore.rentalUnpaidInvoiceReport.forEach((product) => {
          setTotalUnpaidRentalInvoice(totalUnpaidRentalInvoice + parseFloat(product.totalSales))
        })
      } 
    },[reportStore.rentalUnpaidInvoiceReport])

    useEffect(() => {
      if(reportStore.rentalPartialInvoiceReport.length > 0) {
        reportStore.rentalPartialInvoiceReport.forEach((product) => {
          setTotalPartialRentalInvoice(totalPartialRentalInvoice + parseFloat(product.totalBalance))
        })
      } 
    },[reportStore.rentalPartialInvoiceReport])

    

    // const ExcelExportButton = () => {
    //   return (
    //     <ExcelFile>
    //       <ExcelSheet data={productStore.products} name="Sheet 1">
    //         <ExcelColumn label="Brand Name" value="brand" />
    //         <ExcelColumn label="Name" value="name" />
    //         <ExcelColumn label="Quantity" value="quantity" />
    //         <ExcelColumn label="Available" value="availableQuantity" />
    //         <ExcelColumn label="Rented" value="rentedQuantity" />
    //         <ExcelColumn label="Damaged" value="damagedQuantity" />
    //         <ExcelColumn label="Lost Quantity" value="lostQuantity" />
    //       </ExcelSheet>
    //     </ExcelFile>
    //   );
    // };

    const columnsForRent = [
      {
        title: 'Brand Name',
        dataIndex: 'brand',
        key: 'brand',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: 'Available',
        dataIndex: 'availableQuantity',
        key: 'availableQuantity',
      },
      {
        title: 'Rented',
        dataIndex: 'rentedQuantity',
        key: 'rentedQuantity',
      },
      {
        title: 'Damaged',
        dataIndex: 'damagedQuantity',
        key: 'damagedQuantity',
      },
      {
        title: 'Lost Quantity',
        dataIndex: 'lostQuantity',
        key: 'lostQuantity',
      },
    ];

    const columnsForSale = [
      {
        title: 'Brand Name',
        dataIndex: 'brand',
        key: 'brand',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: 'Available',
        dataIndex: 'availableQuantity',
        key: 'availableQuantity',
      },
      {
        title: 'Sold',
        dataIndex: 'soldQuantity',
        key: 'soldQuantity',
      },
    ];

    const salesPurchased = [
      {
        title: 'Brand Name',
        dataIndex: 'brand',
        key: 'brand',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Sales Quantity',
        dataIndex: 'totalQuantity',
        key: 'totalQuantity',
      },
      {
        title: 'Product Amount',
        dataIndex: 'productAmount',
        key: 'productAmount',
        render: (amount) => (
          <span>${parseFloat(amount).toFixed(2)}</span>
        )
      },
      {
        title: 'Sales Amount',
        dataIndex: 'totalSales',
        key: 'totalSales',
        render: (amount) => (
          <span>${parseFloat(amount).toFixed(2)}</span>
        )
      },
      
    ];

    const salesRental = [
      {
        title: 'Brand Name',
        dataIndex: 'brand',
        key: 'brand',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Sales Quantity',
        dataIndex: 'totalQuantity',
        key: 'totalQuantity',
      },
      {
        title: 'Product Amount',
        dataIndex: 'productAmount',
        key: 'productAmount',
        // render: (amount) => {
        //   <span>${parseFloat(amount).toFixed(2)}</span>
        // }
      },
      {
        title: 'Total Rental Months',
        dataIndex: 'totalRentalDays',
        key: 'totalRentalDays',
      },
      {
        title: 'Sales Amount',
        dataIndex: 'totalSales',
        key: 'totalSales',
        render: (amount) => (
          <span>${parseFloat(amount).toFixed(2)}</span>
        )
      },
      
    ];

    const invoiceRental = [
      {
        title: 'Brand Name',
        dataIndex: 'brand',
        key: 'brand',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Sales Quantity',
        dataIndex: 'totalQuantity',
        key: 'totalQuantity',
      },
      {
        title: 'Product Amount',
        dataIndex: 'productAmount',
        key: 'productAmount',
      },
      {
        title: 'Total Rental Months',
        dataIndex: 'totalRentalDays',
        key: 'totalRentalDays',
      },
      {
        title: 'Total Amount',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        render: (amount) => (
          <span>${parseFloat(amount).toFixed(2)}</span>
        )
      },
      {
        title: 'Total Partial Amount',
        dataIndex: 'totalPartialPayments',
        key: 'totalPartialPayments',
        render: (amount) => (
          <span>${parseFloat(amount).toFixed(2)}</span>
        )
      },
      {
        title: 'Total Balance',
        dataIndex: 'totalBalance',
        key: 'totalBalance',
        render: (amount) => (
          <span>${parseFloat(amount).toFixed(2)}</span>
        )
      },
      
    ];

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="admin-warehouse-bin-product-list">
                        <Row justify="space-between">
                          <h3>For Rent Products</h3>
                          {/* <Link to={'/admin/products/add'}>
                            <Button icon={<PlusOutlined/>} type="primary">Add Product</Button>
                          </Link> */}
                          <ExcelFile 
                            filename="FOR_RENT_PRODUCT_REPORTS"
                            element={<Button icon={<FileExcelOutlined />} type="primary">Export Excel File</Button>}
                          >
                            <ExcelSheet data={forRentProducts} name="Sheet 1">
                              <ExcelColumn label="Brand Name" value="brand" />
                              <ExcelColumn label="Name" value="name" />
                              <ExcelColumn label="Quantity" value="quantity" />
                              <ExcelColumn label="Available" value="availableQuantity" />
                              <ExcelColumn label="Rented" value="rentedQuantity" />
                              <ExcelColumn label="Damaged" value="damagedQuantity" />
                              <ExcelColumn label="Lost Quantity" value="lostQuantity" />
                            </ExcelSheet>
                          </ExcelFile>
                        </Row>
                        <Divider/>
                        <h4><Typography.Text type="success">Total No. of Products: {forRentProducts.length}</Typography.Text></h4>
                        <Table dataSource={cloneDeep(forRentProducts)} columns={columnsForRent} pagination={false}/>
                        <Divider/>
                        <Row justify="space-between">
                          <h3>For Sale Products</h3>
                          <ExcelFile 
                            filename="FOR_SALE_PRODUCT_REPORTS"
                            element={<Button icon={<FileExcelOutlined />} type="primary">Export Excel File</Button>}
                          >
                            <ExcelSheet data={forSaleProducts} name="Sheet 1">
                              <ExcelColumn label="Brand Name" value="brand" />
                              <ExcelColumn label="Name" value="name" />
                              <ExcelColumn label="Quantity" value="quantity" />
                              <ExcelColumn label="Available" value="availableQuantity" />
                              <ExcelColumn label="Sold" value="soldQuantity" />
                            </ExcelSheet>
                          </ExcelFile>
                        </Row>
                        <Divider/>
                        <h4><Typography.Text type="success">Total No. of Products: {forSaleProducts.length}</Typography.Text></h4>
                        <Table dataSource={cloneDeep(forSaleProducts)} columns={columnsForSale}  pagination={false}/>
                    </div>
                    <div className="admin-warehouse-bin-product-list">
                        <Row justify="space-between">
                          <h3>Sales for Purchased Products</h3>
                          <ExcelFile 
                            filename="PURCHASED_PRODUCTS_SALES_REPORT"
                            element={<Button icon={<FileExcelOutlined />} type="primary">Export Excel File</Button>}
                          >
                            <ExcelSheet data={reportStore.purchasedSalesReport} name="Sheet 1">
                              <ExcelColumn label="Brand Name" value="brand" />
                              <ExcelColumn label="Name" value="name" />
                              <ExcelColumn label="Sales Quantity" value="totalQuantity" />
                              <ExcelColumn label="Product Amount" value="productAmount" />
                              <ExcelColumn label="Sales Amount" value="totalSales" />
                            </ExcelSheet>
                          </ExcelFile>
                        </Row>
                        <Divider/>
                        <h4><Typography.Text type="success">Total Sales Amount: ${totalPurchasedSale.toFixed(2)}</Typography.Text></h4>
                        <Table dataSource={cloneDeep(reportStore.purchasedSalesReport)} columns={salesPurchased} pagination={false}/>
                        <Divider/>
                        <Row justify="space-between">
                          <h3>Sales for Rental Products</h3>
                          <ExcelFile 
                            filename="RENTAL_PRODUCTS_SALES_REPORT"
                            element={<Button icon={<FileExcelOutlined />} type="primary">Export Excel File</Button>}
                          >
                            <ExcelSheet data={reportStore.rentalSalesReport} name="Sheet 1">
                              <ExcelColumn label="Brand Name" value="brand" />
                              <ExcelColumn label="Name" value="name" />
                              <ExcelColumn label="Sales Quantity" value="totalQuantity" />
                              <ExcelColumn label="Total Rental Months" value="totalRentalDays" />
                              <ExcelColumn label="Product Amount" value="productAmount" />
                              <ExcelColumn label="Sales Amount" value="totalSales" />
                            </ExcelSheet>
                          </ExcelFile>
                        </Row>
                        <Divider/>
                        <h4><Typography.Text type="success">Total Sales Amount: ${totalRentalSale.toFixed(2)}</Typography.Text></h4>
                        <Table dataSource={cloneDeep(reportStore.rentalSalesReport)} columns={salesRental} pagination={false}/>
                        <Divider/>
                        <Row justify={"end"}>
                          <h3><Typography.Text type="success"><b>Over all Sales Total Amount: ${(totalPurchasedSale + totalRentalSale).toFixed(2)}</b></Typography.Text></h3>
                        </Row>
                    </div>
                    <div className="admin-warehouse-bin-product-list">
                        <Row justify="space-between">
                          <h3>Invoice for Purchased Products</h3>
                          <ExcelFile 
                            filename="PURCHASED_PRODUCTS_INVOICE_REPORT"
                            element={<Button icon={<FileExcelOutlined />} type="primary">Export Excel File</Button>}
                          >
                            <ExcelSheet data={reportStore.purchasedInvoiceReport} name="Sheet 1">
                              <ExcelColumn label="Brand Name" value="brand" />
                              <ExcelColumn label="Name" value="name" />
                              <ExcelColumn label="Sales Quantity" value="totalQuantity" />
                              <ExcelColumn label="Product Amount" value="productAmount" />
                              <ExcelColumn label="Invoice Amount" value="totalSales" />
                            </ExcelSheet>
                          </ExcelFile>
                        </Row>
                        <Divider/>
                        <h4><Typography.Text type="danger">Total Invoice Amount: ${totalPurchasedInvoice.toFixed(2)}</Typography.Text></h4>
                        <Table dataSource={cloneDeep(reportStore.purchasedInvoiceReport)} columns={salesPurchased} pagination={false}/>
                        <Divider/>
                        <Row justify="space-between">
                          <h3>Invoice for Rental Products (Unpaid)</h3>
                          <ExcelFile 
                            filename="RENTAL_PRODUCTS_UNPAID_INVOICE_REPORT"
                            element={<Button icon={<FileExcelOutlined />} type="primary">Export Excel File</Button>}
                          >
                            <ExcelSheet data={reportStore.rentalUnpaidInvoiceReport} name="Sheet 1">
                              <ExcelColumn label="Brand Name" value="brand" />
                              <ExcelColumn label="Name" value="name" />
                              <ExcelColumn label="Quantity" value="totalQuantity" />
                              <ExcelColumn label="Total Rental Months" value="totalRentalDays" />
                              <ExcelColumn label="Product Amount" value="productAmount" />
                              <ExcelColumn label="Invoice Amount" value="totalSales" />
                            </ExcelSheet>
                          </ExcelFile>
                        </Row>
                        <Divider/>
                        <h4><Typography.Text type="danger">Total Invoice Amount: ${totalUnpaidRentalInvoice.toFixed(2)}</Typography.Text></h4>
                        <Table dataSource={cloneDeep(reportStore.rentalUnpaidInvoiceReport)} columns={salesRental} pagination={false}/>
                        <Divider/>
                        <Row justify="space-between">
                          <h3>Invoice for Rental Products (Partial)</h3>
                          <ExcelFile 
                            filename="RENTAL_PRODUCTS_PARTIAL_INVOICE_REPORT"
                            element={<Button icon={<FileExcelOutlined />} type="primary">Export Excel File</Button>}
                          >
                            <ExcelSheet data={reportStore.rentalPartialInvoiceReport} name="Sheet 1">
                              <ExcelColumn label="Brand Name" value="brand" />
                              <ExcelColumn label="Name" value="name" />
                              <ExcelColumn label="Sales Quantity" value="totalQuantity" />
                              <ExcelColumn label="Total Rental Months" value="totalRentalDays" />
                              <ExcelColumn label="Product Amount" value="productAmount" />
                              <ExcelColumn label="Total Amount" value="totalAmount" />
                              <ExcelColumn label="Total Partial Payment" value="totalPartialPayments" />
                              <ExcelColumn label="Total Invoice Balance" value="totalBalance" />
                            </ExcelSheet>
                          </ExcelFile>
                        </Row>
                        <Divider/>
                        <h4><Typography.Text type="danger">Total Invoice Balance Amount: ${totalPartialRentalInvoice.toFixed(2)}</Typography.Text></h4>
                        <Table dataSource={cloneDeep(reportStore.rentalPartialInvoiceReport)} columns={invoiceRental} pagination={false}/>
                        <Divider/>
                        <Row justify={"end"}>
                          <h3><Typography.Text type="danger"><b>Over all Invoice Total Amount: ${(totalPurchasedInvoice + totalUnpaidRentalInvoice + totalPartialRentalInvoice).toFixed(2)}</b></Typography.Text></h3>
                        </Row>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject("productStore","reportStore")(observer(Reports));