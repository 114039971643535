import { observable, action } from "mobx";
import axios from "axios";

class AttachmentStore {
    @observable images = [];
    @observable image = {};
    @observable isSuccess = false;

    @action
    uploadImage = (data) => {
        this.loading = true;

        axios
            .post(`/upload/upload.php`, data)
            .then((response) => {
                if(response.status === 200) {
                    this.images = [...this.images, response.data.data]
                    this.isSuccess = true;
                }
            })
            .catch((error) => {
                console.log(error.response.data.error);
            })
            .finally(() => {
                this.loading = false;
                this.onError = false;
            });
    };
}

export default AttachmentStore