import React from "react";
import axios from "axios";
import moment from "moment-timezone";
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Pages from "./pages";
import { Provider } from "mobx-react";
import { RootStoreContext } from "./stores/RootStore";

import "antd/dist/antd.css";
import "./theme.scss";

moment.tz.setDefault("Asia/Manila");

class App extends React.Component {
  static contextType = RootStoreContext;
  render() {
    axios.defaults.baseURL = "https://api.alwaysavailabledme.com";

    // const data = JSON.parse(localStorage.getItem("userStore"));
    // const status = data ? data.loginStatus : false;

    // if (status === true && localStorage.getItem("ACCESS_TOKEN") !== null) {
    //   axios.defaults.headers = {
    //     Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
    //   };
    // } else {
    //   axios.defaults.headers = "";
    //   localStorage.removeItem("userStore");
    //   localStorage.removeItem("ACCESS_TOKEN");
    //   localStorage.removeItem("SESSION_TOKEN");
    //   localStorage.removeItem("AuthStore");
    // }

    // axios.interceptors.request.use(
    //   (config) => {
    //     const token = localStorage.getItem("ACCESS_TOKEN");
    //     if (token !== null) {
    //       config.headers["Authorization"] = "Bearer " + token;
    //     }
    //     return config;
    //   },
    //   (error) => {
    //     Promise.reject(error);
    //   }
    // );

    // axios.interceptors.response.use(
    //   (response) => {
    //     return response;
    //   },
    //   function (error) {
    //     const originalRequest = error.config;
    //     if (error.response.status === 401 && !originalRequest._retry) {
    //       originalRequest._retry = true;

    //       if (localStorage.getItem("ACCESS_TOKEN") !== null) {
    //         return axios
    //           .post(`/users/refresh`, {
    //             sessionToken: localStorage.getItem("SESSION_TOKEN"),
    //           })
    //           .then((response) => {
    //             if (response.status === 200) {
    //               localStorage.setItem(
    //                 "ACCESS_TOKEN",
    //                 response.data.data.accessToken
    //               );
    //               localStorage.setItem(
    //                 "SESSION_TOKEN",
    //                 response.data.data.sessionToken
    //               );
    //               axios.defaults.headers = {
    //                 Authorization: `Bearer ${localStorage.getItem(
    //                   "ACCESS_TOKEN"
    //                 )}`,
    //               };
    //               return axios(originalRequest);
    //             }
    //           });
    //       }
    //     }
    //     // return Error object with Promise
    //     return Promise.reject(error);
    //   }
    // );

    return (
      // <GoogleReCaptchaProvider
      //     reCaptchaKey="6LdThYwcAAAAANMlXuPVzWsITRvD_nxIOk64DTuI"
      //     language="en"
      //     useEnterprise="true"
      //     scriptProps={{
      //         async: false, // optional, default to false,
      //         defer: false, // optional, default to false
      //         appendTo: 'head', // optional, default to "head", can be "head" or "body",
      //         nonce: undefined // optional, default undefined
      //     }}
      // >
      <Provider {...this.context}>
        <Pages />
      </Provider>
      // </GoogleReCaptchaProvider>
    );
  }
}

export default App;
