import React, { useEffect, useState } from "react";
import { Descriptions, Divider, Button, Image, Layout, Table, Tag, Row, notification, List, Modal, Col, Spin, message, Typography } from "antd";
import {
    DownloadOutlined,
    QrcodeOutlined
} from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import moment from "moment-timezone";
import { QrReader } from 'react-qr-reader';

import SideNav from "../../../../components/SideNav";
import Navbar from "../../../../components/Navbar";

import "./style.scss"
import LOGO from "../../../../assets/img/backgroundhome/logo-med-1.png"

import { inject, observer } from "mobx-react";
import { cloneDeep } from 'lodash';
import { QRCode } from "react-qrcode-logo";
import html2canvas from "html2canvas";

const ViewOngoingRentalDetails = ({orderStore, paymentStore}) => {
    const {id} = useParams();
    const navigation = useHistory()
    const { Content } = Layout;

    const [isScanningProduct, setIsScanningProduct] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState()
    const [scannedQRcodes, setScannedQRCodes] = useState([])
    const [currentQrCode, setCurrentQRCode] = useState()
    const [totalRemainingToScan, setTotalRemainingToScan] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [products, setProducts] = useState([])
    const [totalNumberOfProducts, setTotalNumberOfProducts] = useState(0)
    const [deliveredDate, setDeliveredDate] = useState()
    const [forReturnProducts, setForReturnProducts] = useState([])

    useEffect(() => {
        orderStore.isSuccess = false
        orderStore.getOrder(id)
        paymentStore.getPaymentByOrdeID(id)
    }, [])

    useEffect(() => {
        if(orderStore.isSuccess) {
            orderStore.getOrder(id)
            paymentStore.getPaymentByOrdeID(id)
            setTimeout(() => {
                orderStore.isSuccess = false
            }, 1000)
        } 
    }, [orderStore.isSuccess])

    useEffect(() => {
        if(Object.keys(paymentStore.payment).length > 0) {
            paymentStore.getPaymentRecords(paymentStore.payment?.id)
        }
    }, [paymentStore.payment])

    useEffect(() => {
        if(orderStore.order.orderTracker?.length > 0) {
            const isDelivered = orderStore.order.orderTracker.find((tracker) => tracker.status === "DELIVERED")
            if(isDelivered) {
                setDeliveredDate(isDelivered.createdAt)
            }
        }
    }, [orderStore.order.orderTracker])

    useEffect(() => {
        if(forReturnProducts.length > 0 && orderStore.order.status === "DELIVERED") {
            notification.error({
                top: 120,
                message: 'Some products are due for return today.'
            })
        }
    }, [forReturnProducts])

    useEffect(() => {
        if(Object.keys(orderStore.order).length > 0) {
            const toReturnProducts = orderStore.order.products?.filter((product) => moment().isSame(moment(deliveredDate).add(parseInt(product.noOfRentalDays), 'days')))
            setForReturnProducts(toReturnProducts)
            setProducts([
                ...orderStore.order.products.map((product, index) => {
                    product = {
                        key: index,
                        ...product
                    }
                    return product
                })
            ])
        }
    }, [orderStore.order])


    useEffect(() => {
        if(currentQrCode) {
            const checkExist = scannedQRcodes.find((value) => value.serialCode === currentQrCode.serialCode)
            if(checkExist) {
                console.log(currentQrCode.serialCode)
                message.error("Already scanned")
            } else {
                setScannedQRCodes([...scannedQRcodes, currentQrCode])
                setCurrentQRCode()
                setTotalRemainingToScan(totalRemainingToScan + 1)
            }
            setTimeout(() => {
                setIsLoading(false)
            }, 1000)
        }
    }, [currentQrCode])

    const scanner = (scannedQR) => {
        setIsLoading(true)
        setTimeout(() => {
            setCurrentQRCode(scannedQR)
        }, 1000)
    }

    const onOk = () => {
        setIsScanningProduct(false)
        setProducts([
            ...products.map((product) => {
                if(product.id  === selectedProduct.id) {
                    product.serialCodes = scannedQRcodes
                }
                return product
            })
        ])
        setTotalNumberOfProducts(totalNumberOfProducts + 1)
    }

    const updateOrder = (status) => {
        const data = {
            customerID: orderStore.order.customerID,
            status: status,
            totalAmount: orderStore.order.totalAmount,
            products: products, 
            type: "RENT"
        }
        orderStore.updateOrder(id, data)
    }

    const updateOrderToReturn = (status) => {
        const data = {
            status: status,
            type: "RENT"
        }
        
        orderStore.updateOrder(id, data)
    }

    const expandedRowRender = (row) => {
        const columns = [
          {
            title: 'Serial Code',
            dataIndex: 'serialCode',
            key: 'serialCode',
          },
        ];

        return <Table columns={columns} dataSource={cloneDeep(row.serialCodes?.length > 0 ? row.serialCodes : [])} pagination={false} />;
    };
    

    const columnsPreparing = [
        {
            title: 'Product Image',
            dataIndex: 'productImage',
            key: 'productImage',
            render: (productImage) => (
                <Image className="product-image" src={`https://api.alwaysavailabledme.com/${productImage}`}/>
            )
        },
        {
            title: 'Product Name',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Price',
            dataIndex: 'productAmount',
            key: 'productAmount',
            render: (productAmount) => (
                <span>${parseFloat(productAmount).toFixed(2)}</span>
            )
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (id, record) => (
                <Button 
                    type="primary" 
                    icon={<QrcodeOutlined />}
                    onClick={() => {
                        setSelectedProduct(record)
                        setIsScanningProduct(true)
                    }}
                    disabled={record.serialCodes && record.serialCodes.length > 0}
                >Scan Items</Button>
            )
        },
    ];

    const columns = [
        {
            title: 'Product Image',
            dataIndex: 'productImage',
            key: 'productImage',
            render: (productImage) => (
                <Image className="product-image" src={`https://api.alwaysavailabledme.com/${productImage}`}/>
            )
        },
        {
            title: 'Product Name',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Price',
            dataIndex: 'productAmount',
            key: 'productAmount',
            render: (productAmount) => (
                <span>${parseFloat(productAmount).toFixed(2)}</span>
            )
        },
        {
            title: 'Months of Rent',
            dataIndex: 'noOfRentalDays',
            key: 'noOfRentalDays',
        },
        {
            title: 'Delivered Date',
            dataIndex: 'noOfRentalDays',
            key: 'due_date',
            render: (noOfRentalDays) => (
                <span>{moment(deliveredDate).format("LL")}</span>
            )
        },
        {
            title: 'Due Date',
            dataIndex: 'noOfRentalDays',
            key: 'due_date',
            render: (noOfRentalDays) => (
                <Typography.Text type="danger"><b>{moment(deliveredDate).add(parseInt(noOfRentalDays), 'days').format("LL")}</b></Typography.Text>
            )
        },
    ];

    const columnsPending = [
        {
            title: 'Product Image',
            dataIndex: 'productImage',
            key: 'productImage',
            render: (productImage) => (
                <Image className="product-image" src={`https://api.alwaysavailabledme.com/${productImage}`}/>
            )
        },
        {
            title: 'Product Name',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Price',
            dataIndex: 'productAmount',
            key: 'productAmount',
            render: (productAmount) => (
                <span>${parseFloat(productAmount).toFixed(2)}</span>
            )
        },
    ];

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="member-orders-rental-details">
                        <h3>Purchased Details</h3>
                        <Divider/>
                        {Object.keys(orderStore.order).length > 0 ? (
                            <div className="content-container">
                                <Descriptions column={3}>
                                    <Descriptions.Item label="Order Number" span={1}>{orderStore.order.orderNumber}</Descriptions.Item>
                                    <Descriptions.Item label="Date" span={1}>{moment.utc(orderStore.order.createdAt).format('LLL')}</Descriptions.Item>
                                    <Descriptions.Item label="Status" span={1}>
                                        <Tag 
                                            color={
                                                orderStore.order.status === "PENDING" 
                                                ? "blue" 
                                                : orderStore.order.status === "FOR_PAYMENT" 
                                                ?  "geekblue"
                                                : orderStore.order.status === "PREPARING" 
                                                ?  "orange"
                                                : orderStore.order.status === "TO_SHIP" 
                                                ?  "lime"
                                                : orderStore.order.status === "OUT_FOR_DELIVERY" 
                                                ?  "cyan"
                                                : orderStore.order.status === "DELIVERED" 
                                                ?  "green"
                                                : "red"
                                            }
                                        >{orderStore.order.status}</Tag>
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider/>
                                <h4>Customer Information</h4>
                                <Descriptions column={2} bordered>
                                    <Descriptions.Item label="Customer Number" span={2}>{orderStore.order.customer?.idNumber}</Descriptions.Item>
                                    <Descriptions.Item label="First Name" span={1}>{orderStore.order.customer?.firstName}</Descriptions.Item>
                                    <Descriptions.Item label="Middle Name" span={1}>{orderStore.order.customer?.middleName}</Descriptions.Item>
                                    <Descriptions.Item label="Last Name" span={1}>{orderStore.order.customer?.lastName}</Descriptions.Item>
                                    <Descriptions.Item label="Suffix" span={1}>{orderStore.order.customer?.suffix}</Descriptions.Item>
                                    <Descriptions.Item label="Contact Number" span={1}>{orderStore.order.customer?.contactNumber}</Descriptions.Item>
                                    <Descriptions.Item label="Email" span={1}>{orderStore.order.customer?.email}</Descriptions.Item>
                                </Descriptions>
                                <Divider/>
                                <h4>Shipping Information</h4>
                                <Descriptions column={1} layout="vertical" bordered>
                                    <Descriptions.Item label="Shipping Address">{`${orderStore.order.deliveryAddress} ${orderStore.order.deliveryCity} ${orderStore.order.deliveryState} ${orderStore.order.deliveryZipcode}`}</Descriptions.Item>
                                    <Descriptions.Item label="Note">{orderStore.order.note}</Descriptions.Item>
                                </Descriptions>
                                <Divider/>
                                <h4>Products Information</h4>
                                {(orderStore.order.status !== "PENDING" && orderStore.order.status !== "FOR_PAYMENT") ? (
                                    <>
                                        <Table
                                            columns={orderStore.order.status === "PREPARING" ? columnsPreparing : columns}
                                            expandable={{
                                                expandedRowRender: (row) => expandedRowRender(row)
                                            }}
                                            dataSource={cloneDeep(products)}
                                            pagination={false}
                                        />
                                        <Modal
                                            visible={isScanningProduct}
                                            title={`Scan ${selectedProduct?.productName}`}
                                            onCancel={() => setIsScanningProduct(false)}
                                            onOk={onOk}
                                            okButtonProps={{ disabled: totalRemainingToScan !== parseInt(selectedProduct?.quantity)}}
                                        >
                                            <h4>To scan products: {totalRemainingToScan} / {selectedProduct?.quantity}</h4>
                                            {totalRemainingToScan !== parseInt(selectedProduct?.quantity) ? (
                                                <>
                                                    <Row justify="center">
                                                        <Col span={12} align={"middle"}>
                                                            {isLoading ? (
                                                                <Spin tip="Loading..." spinning={isLoading}/>
                                                            ): (
                                                                <QrReader
                                                                    onResult={(result, error) => {
                                                                        if (!!result) {
                                                                            const scannedQR = JSON.parse(result.text)
                                                                            
                                                                            if(scannedQR.productID === selectedProduct.productID) {
                                                                                scanner(scannedQR)
                                                                            } else {
                                                                                message.error(`Scanned product is not ${selectedProduct?.productName}. Please make sure that you only scanned the type of product you selected.`)
                                                                            }
                                                                        }
                                                                    }}
                                                                    constraints={{
                                                                        facingMode: 'environment'
                                                                    }}
                                                                    style={{ width: '100%' }}
                                                                />
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <Divider/>

                                                </>
                                            ):<></>}
                                            <h4>Serial Codes</h4>
                                            <Table
                                                columns={[
                                                    {
                                                        title: 'Serial Code',
                                                        dataIndex: 'serialCode',
                                                        key: 'serialCode',
                                                    }
                                                ]}
                                                dataSource={cloneDeep(scannedQRcodes)}
                                            >
                                            </Table>
                                            
                                            
                                        </Modal>
                                    </>
                                ):(
                                    <Table
                                        columns={columnsPending}
                                        // expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
                                        dataSource={cloneDeep(orderStore.order.products)}
                                        pagination={false}
                                    />
                                )}
                                <Divider/>
                            </div>
                        ):<></>}
                        <Divider/>
                        <Row justify="end" gutter={10}>
                            {(Object.keys(orderStore.order).length > 0 && orderStore.order.status === "PREPARING") ? (
                                <Col>
                                    <Button 
                                        type="primary" 
                                        disabled={orderStore.order.products.length !== totalNumberOfProducts}
                                        onClick={() => updateOrder("TO_SHIP")}
                                    >To Ship Order</Button>
                                </Col>
                            ):<></>}
                            {(Object.keys(orderStore.order).length > 0 && orderStore.order.status === "DELIVERED") ? (
                                <Col>
                                    <Button 
                                        type="primary" 
                                        disabled={forReturnProducts.length === 0}
                                        onClick={() => updateOrderToReturn("FOR_RETURN")}
                                    >For Return</Button>
                                </Col>
                            ):<></>}
                            <Col>
                                <Button onClick={() => navigation.goBack()}> Back</Button>
                            </Col>
                        </Row>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject("orderStore", "paymentStore")(observer(ViewOngoingRentalDetails));