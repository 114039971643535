import { observable, action } from "mobx";
import axios from "axios";

class ReportStore {
    @observable purchasedSalesReport = [];
    @observable purchasedInvoiceReport = [];
    @observable rentalSalesReport = [];
    @observable rentalUnpaidInvoiceReport = [];
    @observable rentalPartialInvoiceReport = [];

    @action
    getPurchasedSalesReport = () => {
        this.loading = true;

        axios
            .get(`/reports/purchased_products_sales.php`)
            .then((response) => {
                if(response.status === 200) {
                    this.purchasedSalesReport = response.data.data
                }
            })
            .catch((error) => {
                console.log(error.response.data.error);
            })
            .finally(() => {
                this.loading = false;
                this.onError = false;
            });
    };

    @action
    getRentalSalesReport = () => {
        this.loading = true;

        axios
            .get(`/reports/rental_products_sales.php`)
            .then((response) => {
                if(response.status === 200) {
                    this.rentalSalesReport = response.data.data
                }
            })
            .catch((error) => {
                console.log(error.response.data.error);
            })
            .finally(() => {
                this.loading = false;
                this.onError = false;
            });
    };

    @action
    getPurchasedInvoiceReport = () => {
        this.loading = true;

        axios
            .get(`/reports/purchased_products_invoice.php`)
            .then((response) => {
                if(response.status === 200) {
                    this.purchasedInvoiceReport = response.data.data
                }
            })
            .catch((error) => {
                console.log(error.response.data.error);
            })
            .finally(() => {
                this.loading = false;
                this.onError = false;
            });
    };

    @action
    getRentalUnpaidInvoiceReport = () => {
        this.loading = true;

        axios
            .get(`/reports/rental_products_unpaid_invoice.php`)
            .then((response) => {
                if(response.status === 200) {
                    this.rentalUnpaidInvoiceReport = response.data.data
                }
            })
            .catch((error) => {
                console.log(error.response.data.error);
            })
            .finally(() => {
                this.loading = false;
                this.onError = false;
            });
    };

    @action
    getRentalPartialInvoiceReport = () => {
        this.loading = true;

        axios
            .get(`/reports/rental_products_partial_invoice.php`)
            .then((response) => {
                if(response.status === 200) {
                    this.rentalPartialInvoiceReport = response.data.data
                }
            })
            .catch((error) => {
                console.log(error.response.data.error);
            })
            .finally(() => {
                this.loading = false;
                this.onError = false;
            });
    };

    
}

export default ReportStore