import React, { useEffect } from "react";
import { Table, Divider, Layout, Space, Popconfirm, Typography, Row, Button } from "antd";
import {
    EyeOutlined,
    EditOutlined,
    QuestionCircleOutlined,
    DeleteOutlined,
    PlusOutlined
} from '@ant-design/icons';
import {Link} from 'react-router-dom';

import SideNav from "../../../components/SideNav";
import Navbar from "../../../components/Navbar";

import "./style.scss"

import { inject, observer } from "mobx-react";
import { cloneDeep } from 'lodash';


const WarehouseBins = ({warehouseBinStore}) => {
    const { Content } = Layout;

    useEffect(() => {
      warehouseBinStore.getWarehouseBins()
    },[])

    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'username',
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (id, record) => (
                <Space size={'middle'}>
                  <Link to={`/admin/warehouse-bins/${id}/stocks`}><EyeOutlined /> View Stocks</Link>
                  <Link to={`/admin/warehouse-bins/${id}/edit`}><EditOutlined />Edit</Link>
                  <Popconfirm
                    title="Delete bin"
                    description="Are you sure to delete this bin?"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    onConfirm={async() => {
                      await warehouseBinStore.deleteWarehouseBin(id)
                      setTimeout(() => {
                        warehouseBinStore.getWarehouseBins()
                      },1000)
                    }}
                    okText="Yes"
                  >
                    <Typography.Link type='danger'><DeleteOutlined /> Delete</Typography.Link>
                  </Popconfirm>
                </Space>
            )
        },
      ];

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="admin-warehouse-bin-list">
                        <Row justify="space-between">
                          <h3>Warehouse Bin List</h3>
                          <Link to={'/admin/warehouse-bins/add'}>
                            <Button icon={<PlusOutlined/>} type="primary">Add Bin</Button>
                          </Link>
                        </Row>
                        <Divider/>
                        <Table dataSource={cloneDeep(warehouseBinStore.warehouseBins)} columns={columns} />
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject("warehouseBinStore")(observer(WarehouseBins));