import React, { useEffect, useState } from "react";
import { Table, Divider, Image, Layout, Row, Button, Typography, Popconfirm, Modal, Form, Input, notification, Tag } from "antd";
import {
    DeleteOutlined,
    QuestionCircleOutlined,
    EnvironmentOutlined
  } from '@ant-design/icons';
import { useHistory } from "react-router-dom"

import SideNav from "../../../components/SideNav";
import Navbar from "../../../components/Navbar";

import "./style.scss"
import { inject, observer } from "mobx-react";
import { cloneDeep } from 'lodash';

const MemberCart = ({cartStore, orderStore}) => {
    const user = JSON.parse(localStorage.getItem("userStore"))
    const navigation = useHistory()
    const { Content } = Layout;
    const { TextArea } = Input;

    const [form] = Form.useForm();
    const [totalRentAmount, setTotalRentAmount] = useState(0)
    const [totalBuyAmount, setTotalBuyAmount] = useState(0)
    const [type, setType] = useState()
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        orderStore.isSuccess = false
        cartStore.getCartProductsByType(user.id, "RENT")
        cartStore.getCartProductsByType(user.id, "BUY")
        form.setFieldsValue(user)
    },[])

    useEffect(() => {
        if(cartStore.cartProductsRent.length > 0) {
            let totalAmount = 0;

            cartStore.cartProductsRent.forEach((product) => {
                if(product.productSubVariantID != null) {
                    totalAmount = totalAmount + ((parseInt(product.quantity) * parseFloat(product.subVariant.price).toFixed(2)) * parseInt(product.noOfRentalDays))
                } else if (product.productVariantID != null) {
                    totalAmount = totalAmount + ((parseInt(product.quantity) * parseFloat(product.variant.price).toFixed(2)) * parseInt(product.noOfRentalDays))
                } else {
                    totalAmount = totalAmount + ((parseInt(product.quantity) * parseFloat(product.price).toFixed(2)) * parseInt(product.noOfRentalDays))
                }
            })

            setTotalRentAmount(parseFloat(totalAmount).toFixed(2))
        } else {
            setTotalRentAmount(0)
        }
    },[cartStore.cartProductsRent])

    useEffect(() => {
        if(cartStore.cartProductsBuy.length > 0) {
            let total = 0
            cartStore.cartProductsBuy.forEach(product => {
                total = total + (parseFloat(product.price) * parseInt(product.quantity))
            });

            setTotalBuyAmount(parseFloat(total).toFixed(2))
        } else {
            setTotalBuyAmount(0)
        }
    },[cartStore.cartProductsBuy])

    useEffect(() => {
        if(orderStore.isSuccess) {
            notification.success({
                top: 120,
                message: 'Order submitted successfully!',
            });

            setTimeout(() => {
                if(type === "RENT") {
                    navigation.push("/orders/rentals")
                } else {
                    navigation.push("/orders/purchased")
                }
            }, 1000)

        }
    },[orderStore.isSuccess])

    const toRentColumns = [
        {
            title: 'Product Image',
            dataIndex: 'image',
            key: 'image',
            render: (image) => (
                <Image className="product-image" src={`https://api.alwaysavailabledme.com/${image}`}/>
            )
        },
        {
            title: 'Product Brand',
            dataIndex: 'brand',
            key: 'brand',
        },
        {
            title: 'Product Name',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => (
                <span>
                    {name}
                    <br/>
                    {record.productSubVariantID != null ? (
                        <>
                            <Tag>{record.variant.name}</Tag>
                            <Tag>{record.subVariant.name}</Tag>
                        </>
                    ): record.productVariantID != null ? (
                        <Tag>{record.variant.name}</Tag>
                    ):<></>}
                </span>
            )
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Rental Months',
            dataIndex: 'noOfRentalDays',
            key: 'noOfRentalDays',
            render: (noOfRentalDays) => (
                <span>{noOfRentalDays === "1" ? `${noOfRentalDays} day` : `${noOfRentalDays} days`} </span>
            )
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (price, record) => (
                <span>
                    {record.productSubVariantID != null ? (
                        `$${parseFloat(record.subVariant.price).toFixed(2)}`
                    ): record.productVariantID != null ? (
                        `$${parseFloat(record.variant.price).toFixed(2)}`
                    ):(`$${parseFloat(price).toFixed(2)}`)}
                </span>
            )
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (cartID) => (
                <Popconfirm
                    title="Remove product"
                    description="Are you sure to delete this product?"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    onConfirm={async() => {
                      await cartStore.deleteCart(cartID)
                      setTimeout(() => {
                        cartStore.getCartProductsByType(user.id, "RENT")
                      },1000)
                    }}
                    okText="Yes"
                  >
                    <Typography.Link type='danger'><DeleteOutlined /> Remove</Typography.Link>
                </Popconfirm>
            )
        },
    ]

    const buyColumns = [
        {
            title: 'Product Image',
            dataIndex: 'image',
            key: 'image',
            render: (image) => (
                <Image className="product-image" src={`https://api.alwaysavailabledme.com/${image}`}/>
            )
        },
        {
            title: 'Product Brand',
            dataIndex: 'brand',
            key: 'brand',
        },
        {
            title: 'Product Name',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => (
                <span>
                    {name}
                    <br/>
                    {record.productSubVariantID != null ? (
                        <>
                            <Tag>{record.variant.name}</Tag>
                            <Tag>{record.subVariant.name}</Tag>
                        </>
                    ): record.productVariantID != null ? (
                        <Tag>{record.variant.name}</Tag>
                    ):<></>}
                </span>
            )
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (price, record) => (
                <span>
                    {record.productSubVariantID != null ? (
                        `$${parseFloat(record.subVariant.price).toFixed(2)}`
                    ): record.productVariantID != null ? (
                        `$${parseFloat(record.variant.price).toFixed(2)}`
                    ):(`$${parseFloat(price).toFixed(2)}`)}
                </span>
            )
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (cartID) => (
                <Popconfirm
                    title="Remove product"
                    description="Are you sure to delete this product?"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    onConfirm={async() => {
                      await cartStore.deleteCart(cartID)
                      setTimeout(() => {
                        cartStore.getCartProductsByType(user.id, "BUY")
                      },1000)
                    }}
                    okText="Yes"
                  >
                    <Typography.Link type='danger'><DeleteOutlined /> Remove</Typography.Link>
                </Popconfirm>
            )
        },
    ]

    const onFinish = (values) => {
        const data = {
            type: type,
            totalAmount: type === "RENT" ? totalRentAmount : totalBuyAmount,
            deliveryAddress: values.address,
            deliveryCity: values.city,
            deliveryState: values.state,
            deliveryZipcode: values.zipcode,
            note: values.note || "",
            createdBy: user.id,
            products: type === "RENT" ? [...cartStore.cartProductsRent] : [...cartStore.cartProductsBuy]
        }

        orderStore.createOrder(data)
    }

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="member-cart">
                        <h3>To Rent Products</h3>
                        <Divider/>
                        <Table columns={toRentColumns} dataSource={cloneDeep(cartStore.cartProductsRent)} pagination={false}/>
                        <Divider/>
                        <h4>Total: ${totalRentAmount}</h4>
                        <Row align="end">
                            <Button 
                                type="primary" 
                                onClick={() => {
                                    setType("RENT")
                                    setOpenModal(true)
                                }}
                            >Rent Now</Button>
                        </Row>
                    </div>
                    <div className="member-cart">
                        <h3>To Purchase Products</h3>
                        <Divider/>
                        <Table columns={buyColumns} dataSource={cloneDeep(cartStore.cartProductsBuy)} pagination={false}/>
                        <Divider/>
                        <h4>Total: ${totalBuyAmount}</h4>
                        <Row align="end">
                            <Button 
                                type="primary"
                                onClick={() => {
                                    setType("BUY")
                                    setOpenModal(true)
                                }}
                            >Buy Now</Button>
                        </Row>
                    </div>
                    <Modal
                        visible={openModal}
                        title={"Review Address"}
                        footer={null}
                        onCancel={() => setOpenModal(false)}
                    >
                        <Form
                            name="normal_register"
                            className="register-form"
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                        >
                            <Form.Item 
                                name="address"
                                label="Address"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Address!',
                                    },
                                ]}
                            >
                                <Input
                                    maxLength={12}
                                    prefix={<EnvironmentOutlined className="site-form-item-icon" />}
                                    placeholder="Address"
                                />
                            </Form.Item>
                            <Form.Item 
                                name="city"
                                label="City"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your City!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<EnvironmentOutlined className="site-form-item-icon" />}
                                    placeholder="City"
                                />
                            </Form.Item>
                            <Form.Item 
                                name="state"
                                label="State"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your State!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<EnvironmentOutlined className="site-form-item-icon" />}
                                    placeholder="State"
                                />
                            </Form.Item>
                            <Form.Item 
                                name="zipcode"
                                label="Zipcode"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Zipcode!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<EnvironmentOutlined className="site-form-item-icon" />}
                                    placeholder="Zipcode"
                                    minLength={6}
                                />
                            </Form.Item>
                            <Form.Item 
                                name="note"
                                label="Add Note"
                            >
                                <TextArea
                                    placeholder="Add Note for your order..."
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>Confirm Order</Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject("cartStore","orderStore")(observer(MemberCart));