import { observable, action } from "mobx";
import { persist } from "mobx-persist";
import axios from "axios";

class UserStore {
  @observable users = [];
  @observable user = {};

  @observable loading = false;
  @observable isSuccess = false;

  @observable onError = false;
  @observable onErrorMess = "";

  @persist @observable loginStatus = false;
  @persist @observable id = "";
  @persist @observable idNumber = "";
  @persist @observable username = "";
  @persist @observable userType = "";
  @persist @observable firstName = "";
  @persist @observable middleName = "";
  @persist @observable lastName = "";
  @persist @observable suffix = "";
  @persist @observable age = "";
  @persist @observable gender = "";
  @persist @observable birthDate = "";
  @persist @observable address = "";
  @persist @observable city = "";
  @persist @observable state = "";
  @persist @observable zipcode = "";
  @persist @observable contactNumber = "";
  @persist @observable email = "";
  @persist @observable password = "";
  @persist @observable status = "";
  @persist @observable position = "";
  @persist @observable createdAt = "";
  @persist @observable updatedAt = "";
  

  @action
  createUser = (values) => {
    this.loading = true;

    axios
      .post(`/users/create.php`, values)
      .then((response) => {
        if (response.status === 200) {
          if(response.data.status === 200) {
            this.isSuccess = true;
          }else if(response.data.status === 409) {
            this.onError = true;
            this.onErrorMess = response.data.message

            setTimeout(()=>{
              this.onError = false;
            },500)
          }
        }
      })
      .catch((error) => {
        console.log(error.response.data);
      })
      .finally(() => {
        this.loading = false;
        // this.onError = false;
      });
  };

  @action
  login = ({ username, password }) => {

    this.loading = true;

    const datapost = {
      username: username,
      password: password,
    };

    axios
      .post(`/users/login.php`, datapost)
      .then((response) => {
        if (response.status === 200) {
          let user = response.data.data;

          this.loginStatus = true;
          this.id = user.id;
          this.idNumber = user.idNumber;
          this.username = user.username;
          this.userType = user.userType;
          this.firstName = user.firstName;
          this.middleName = user.middleName;
          this.lastName = user.lastName;
          this.suffix = user.suffix;
          this.age = user.age;
          this.gender = user.gender;
          this.birthDate = user.birthDate;
          this.address = user.address;
          this.city = user.city;
          this.state = user.state;
          this.zipcode = user.zipcode;
          this.contactNumber = user.contactNumber;
          this.email = user.email;
          this.password = user.password;
          this.status = user.status;
          this.position = user.position;
          this.createdAt = user.createdAt;
          this.updatedAt = response.updatedAt;

          if (user.userType === "CUSTOMER") {
            window.location.href = "/dashboard";
          } else if (user.userType === "SUPERADMIN") {
            window.location.href = "/admin/users";
          } else if (user.userType === "ACCOUNTING_ADMIN") {
            window.location.href = "/admin/payments";
          } else if (user.userType === "DRIVER") {
            window.location.href = "/admin/for-delivery";
          } else if (user.userType === "WAREHOUSE_ADMIN" | user.userType === "WAREHOUSE_STAFF") {
            window.location.href = "/admin/warehouse-bins";
          }
        }
      })
      .catch((error) => {
        console.log(error.response.data);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };

  @action
  getUsers = () => {
    this.loading = true;

    axios
      .get(`/users/get_all.php`)
      .then((response) => {
        if (response.status === 200) {
          this.users = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.data);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };

  @action
  getUsersByType = (userType) => {
    this.loading = true;

    axios
      .get(`/users/get_all.php?userType=${userType}`)
      .then((response) => {
        if (response.status === 200) {
          this.users = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.data);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };

  @action
  getUser = (userID) => {
    this.loading = true;

    axios
      .get(`/users/get.php?id=${userID}`)
      .then((response) => {
        if (response.status === 200) {
          this.user = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response);

        this.onError = true;
        this.onErrorMess = error.response.data.message
      })
      .finally(() => {
        this.loading = false;
        this.onError = false;
      });
  };

  @action
  updateUser = (userID, data) => {
    this.loading = true;

    axios
      .patch(`/users/update.php?id=${userID}`, data)
      .then((response) => {
          if(response.status === 201) {
              this.isSuccess = true;
          }
      })
      .catch((error) => {
          console.log(error.response.data);
          this.onError = true;
          this.onErrorMess = error.response.data.message
      })
      .finally(() => {
          this.loading = false;
          this.onError = false;
      });
  };

  @action
  deleteUser = (userID) => {
    this.loading = true;

    axios
      .delete(`/users/delete.php?id=${userID}`)
      .then((response) => {
          if(response.status === 200) {
              this.isSuccess = true;
          }
      })
      .catch((error) => {
          console.log(error.response.data);
      })
      .finally(() => {
          this.loading = false;
          this.onError = false;
      });
  };
}

export default UserStore;
