import React from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from "react-router-dom";
import LogoMed from "../../assets/img/backgroundhome/logo-med.png"
import './style.scss'

// const { confirm } = Modal

const NavbarLogin = ({ userStore }) => {

    // const [click, setClick] = useState(false)
    // const [onscroll, setOnscroll] = useState(false)

    // const handleClick = () => {
    //     // console.log("hahahaha")
    //     setClick(!click)
    // }

    // const changeNavbarColor = () =>{
    //     if(window.scrollY >= 80){
    //         setOnscroll(true);
    //     }
    //     else{
    //         setOnscroll(false);
    //     }
    //  };

    //  window.addEventListener('scroll', changeNavbarColor);

    return (
        <nav className="navbar-login">
            <div className="container">
                <div className="navbar-wrapper">
                    <div className="logo">
                        <a href={"/login"}>
                            <img src={LogoMed} alt="main-logo"/>
                        </a>
                    </div>

                    <div className="nav-links show">

                        <div
                            className="link-wrapper"
                        >
                            <ul>
                                <li><Link style={{ color: onscroll ? '#333333' : '#333333'}} to={"/about"}>About</Link></li>
                                <li><Link style={{ color: onscroll ? '#333333' : '#333333'}} to="/login">Sign-In</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default inject('userStore')(observer(NavbarLogin))
