import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import './style.scss'
import { Link } from "react-router-dom";
import LogoMed from "../../assets/img/backgroundhome/logo-med.png"

// const { confirm } = Modal

const NavbarHome = ({ userStore }) => {

    // const [click, setClick] = useState(false)
    const [onscroll, setOnscroll] = useState(false)

    // const handleClick = () => {
    //     console.log("hahahaha")
    //     setClick(!click)
    // }

    const changeNavbarColor = () =>{
        if(window.scrollY >= 80){
            setOnscroll(true);
        }
        else{
            setOnscroll(false);
        }
     };

     window.addEventListener('scroll', changeNavbarColor);

    return (
        <nav className="navbar-home" style={{ backgroundColor: onscroll ? '#ffffff' : 'transparent', boxShadow: onscroll ? 'none' : 'none'}}>
        {/* <nav className="navbar-home" style={{ backgroundColor: onscroll ? '#ffb300' : 'transparent', boxShadow: onscroll ? '0 8px 17px 2px rgba(216, 216, 216, 0.14), 0 3px 14px 2px rgba(201, 201, 201, 0.12),0 5px 5px -3px rgba(225, 225, 225, 0.2)' : 'none'}}> */}
            <div className="container">
                <div className="navbar-wrapper">
                    <div className="logo">
                        {/* Logo */}
                        {/* <a href={"/"}><h1 style={{ color: onscroll ? '#000000' : '#ffffff'}}>LOGO</h1></a> */}
                        <a href={"/login"}><img src={LogoMed} alt="main-logo"/></a>
                    </div>

                    <div className="nav-links show">

                        <div
                            className="link-wrapper"
                        >
                            <ul>
                                <li><Link style={{ color: onscroll ? '#333333' : '#ffffff'}} to={"/about"}>About</Link></li>
                                <li><Link style={{ color: onscroll ? '#333333' : '#ffffff'}} to="/login">Sign-In</Link></li>
                            </ul>
                        </div>
                    </div>
                    {/* <button className="drawer-btn" onClick={handleClick}>
                        <DehazeIcon />
                    </button> */}
                </div>
            </div>
        </nav>
    )
}

export default inject('userStore')(observer(NavbarHome))
