
import React from 'react';
import { Layout, Menu } from 'antd';
import {
    ShopOutlined,
    ShoppingCartOutlined,
    ShoppingOutlined,
    UserOutlined,
    ClusterOutlined,
    StockOutlined,
    CreditCardOutlined,
    UsergroupAddOutlined,
    MessageOutlined,
    FundProjectionScreenOutlined,
    TagsOutlined,
    CarOutlined,
    NodeIndexOutlined
} from '@ant-design/icons';
import logo from "../../assets/img/backgroundhome/logo-med-white.png"

import './style.scss'
import { useHistory  } from 'react-router-dom';

const SideNav = ({collapsed}) => {
    const { Sider } = Layout;
    const navigate = useHistory ();

    const user = JSON.parse(localStorage.getItem("userStore"))

    const customerItems = [
        {
            key: 'dashboard',
            icon: <ShopOutlined />,
            label: 'Products',
        },
        {
          key: 'cart',
          icon: <ShoppingCartOutlined />,
          label: 'Cart',
        },
        {
            key: 'bulk-order-purchased',
            icon: <ShoppingOutlined />,
            label: 'Create Bulk Purchased Order',
        },
        {
            key: 'bulk-order-rental',
            icon: <ShoppingOutlined />,
            label: 'Create Bulk Rental Order',
        },
        {
            // key: 'orders',
            icon: <ShoppingOutlined />,
            label: 'Orders',
            children: [
                {
                    key: 'orders/purchased',
                    icon: <ShoppingOutlined />,
                    label: 'Purchased Products',
                },
                {
                    key: 'orders/rentals',
                    icon: <StockOutlined />,
                    label: 'Rentals',
                },
            ]
        },
    ]

    const adminItems = [
        {
            key: 'admin/users',
            icon: <UserOutlined />,
            label: 'Users',
        },
        {
            key: 'admin/employees',
            icon: <UsergroupAddOutlined />,
            label: 'Employees',
        },
        {
            key: 'admin/warehouse-bins',
            icon: <ClusterOutlined />,
            label: 'Warehouse Bins',
        },
        {
            key: 'admin/products',
            icon: <TagsOutlined />,
            label: 'Products',
        },
        {
            icon: <ShoppingOutlined />,
            label: 'Orders',
            children: [
                {
                    key: 'admin/orders/purchased',
                    icon: <ShoppingOutlined />,
                    label: 'Purchased Products',
                },
                {
                    key: 'admin/orders/rentals',
                    icon: <StockOutlined />,
                    label: 'Rentals',
                },
            ]
        },
        {
            key: 'admin/for-delivery',
            icon: <CarOutlined />,
            label: 'For Delivery',
        },
        {
            key: 'admin/for-pickup',
            icon: <NodeIndexOutlined />,
            label: 'For Pickup',
        },
        {
            key: 'admin/ongoing-rentals',
            icon: <StockOutlined />,
            label: 'Ongoing Rentals',
        },
        {
            key: 'admin/payments',
            icon: <CreditCardOutlined />,
            label: 'Payments',
        },
        // {
        //     key: 'admin/customer-care',
        //     icon: <MessageOutlined />,
        //     label: 'Customer Care',
        // },
        {
            key: 'admin/reports',
            icon: <FundProjectionScreenOutlined />,
            label: 'Reports',
        },
    ]

    const driverItems = [
        {
            key: 'admin/for-delivery',
            icon: <CarOutlined />,
            label: 'For Delivery',
        },
        {
            key: 'admin/for-pickup',
            icon: <NodeIndexOutlined />,
            label: 'For Pickup',
        },
    ]

    const accountingItems = [
        {
            key: 'admin/payments',
            icon: <CreditCardOutlined />,
            label: 'Payments',
        },
    ]

    const warehouseItems = [
        {
            key: 'admin/warehouse-bins',
            icon: <ClusterOutlined />,
            label: 'Warehouse Bins',
        },
        {
            key: 'admin/products',
            icon: <TagsOutlined />,
            label: 'Products',
        },
        {
            icon: <ShoppingOutlined />,
            label: 'Orders',
            children: [
                {
                    key: 'admin/orders/purchased',
                    icon: <ShoppingOutlined />,
                    label: 'Purchased Products',
                },
                {
                    key: 'admin/orders/rentals',
                    icon: <StockOutlined />,
                    label: 'Rentals',
                },
            ]
        },
    ]

    return (
        <Sider trigger={null} collapsible collapsed={collapsed}>
            <div className="logo-sidenav">
                {!collapsed ? <img src={logo} alt=''/> : <></>}
            </div>
            <div className='space'/>
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={['1']}
                onClick={({ item, key, keyPath, domEvent }) => navigate.push(`/${key}`)}
                items={
                    user.userType === "CUSTOMER" 
                    ? customerItems 
                    : (user.userType === "SUPERADMIN" 
                        ? adminItems 
                        : (user.userType === "DRIVER" 
                        ? driverItems
                        : (user.userType === "ACCOUNTING_ADMIN"
                        ? accountingItems
                        : (user.userType === "WAREHOUSE_ADMIN" | user.userType === "WAREHOUSE_STAFF"
                        ? warehouseItems 
                        : []
                        )))
                    )}
            />
        </Sider>
    );
};
export default SideNav;