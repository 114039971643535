import React, { useEffect, useState } from "react";
import { Divider, Layout, Row, Button, Modal, Spin, Col } from "antd";
import {
    QrcodeOutlined
} from '@ant-design/icons';
import { QrReader } from "react-qr-reader";

import SideNav from "../../../components/SideNav";
import Navbar from "../../../components/Navbar";
import ForDeliveryTable from "../../../components/Admin/ForDeliveryTable";

import "./style.scss"

import { inject, observer } from "mobx-react";
import { cloneDeep } from 'lodash';

const ForDelivery = ({orderStore}) => {
    const user = JSON.parse(localStorage.getItem("userStore"))

    const { Content } = Layout;

    const [isScanning, setIsScanning] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        orderStore.isSuccess = false;
        orderStore.outForDelivery = false;
        orderStore.forDelivery = false;
        orderStore.getOrdersByDriver(user.id, "OUT_FOR_DELIVERY")
    },[])

    useEffect(() => {
        if(orderStore.isSuccess) {
            setIsScanning(false)
            setIsLoading(false)
            window.location.reload();
        }
    },[orderStore.isSuccess])

    useEffect(() => {
        if(Object.keys(orderStore.order).length > 0 && orderStore.outForDelivery) {
            if(orderStore.order.status === "TO_SHIP") {
                orderStore.updateOrderOutForDelivery(
                    orderStore.order.id, 
                    {
                        driverID: user.id,
                        status: "OUT_FOR_DELIVERY"
                    }
                )
            } 
        }
        
    },[orderStore.outForDelivery])

    useEffect(() => {

        if(Object.keys(orderStore.order).length > 0 && orderStore.forDelivery) {
            if(orderStore.order.status === "OUT_FOR_DELIVERY") {
                orderStore.updateOrderDelivered(
                    orderStore.order.id, 
                    {
                        driverID: user.id,
                        status: "DELIVERED"
                    }
                )
            } 
        }

    },[orderStore.forDelivery])

    const onScan = (data) => {
        orderStore.getOrder(data.orderID)
    }

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="admin-orders-purchased">
                        <Row justify="space-between" align="bottom">
                            <h3>For Delivery</h3>
                            <Button 
                                type="primary" 
                                icon={<QrcodeOutlined />}
                                onClick={() => {
                                    setIsScanning(true)
                                }}
                                // disabled={user.userType !== "DRIVER"}
                            >Scan Order</Button>
                            <Modal
                                visible={isScanning}
                                title={`Scan Order`}
                                onCancel={() => setIsScanning(false)}
                                footer={null}
                            >
                                <Col align={"middle"}>
                                    { isLoading ? (
                                        <Spin tip="Loading..." spinning={isLoading}/>
                                    ): (
                                        <QrReader
                                            onResult={(result, error) => {
                                                if (!!result) {
                                                    setIsLoading(true)
                                                    onScan(JSON.parse(result.text))
                                                }
                                            }}
                                            constraints={{
                                                facingMode: 'environment'
                                            }}
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </Col>
                            </Modal>
                        </Row>
                        <Divider/>
                        <ForDeliveryTable data={cloneDeep(orderStore.orders)}/> 
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject("orderStore")(observer(ForDelivery));