/* eslint-disable */
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom"
import { inject, observer } from 'mobx-react'

import { Form, Input, Button, notification } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import NavbarLogin from "../../components/NavbarLogin"

import LogoName from "../../assets/img/backgroundhome/logo-med.png"
import Particles from "react-tsparticles";
import "./style.scss"


const Login = ({ userStore }) => {

    const { setValue } = useForm();

    useEffect(() => {
        userStore.onError = false
        userStore.onErrorMess = ''
    }, []);

    useEffect(() => {
        if(userStore.onError){
            notification.error({
                top: 120,
                message: 'User Login Error',
                description: userStore.onErrorMess
            });
        }
    }, [userStore.onError]);

    const onFinish = (values) => {
        userStore.login(values)
    }

    return (
        <div className="login-page">
            <NavbarLogin />
            <Particles
                id="tsparticles"
                options={{
                    background: {
                    color: {
                        value: "#fffffff",
                    },
                    },
                    fpsLimit: 200,
                    interactivity: {
                    events: {
                        onClick: {
                            enable: false,
                            mode: "push",
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        bubble: {
                            distance: 600,
                            duration: 1,
                            opacity: 0.6,
                            size: 5,
                        },
                        push: {
                            quantity: 2,
                        },
                        repulse: {
                            distance: 200,
                            duration: 0.4,
                        },
                    },
                    },
                    particles: {
                    color: {
                        value: "#fdcb6e",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 200,
                        enable: true,
                        opacity: 0.6,
                        width: 1,
                    },
                    collisions: {
                        enable: false,
                    },
                    move: {
                        direction: "none",
                        enable: true,
                        outMode: "bounce",
                        random: true,
                        speed: 2,
                        straight: false,
                    },
                    number: {
                        density: {
                        enable: true,
                        value_area: 800,
                        },
                        value: 10,
                    },
                    opacity: {
                        value: 0.5,
                    },
                    shape: {
                        type: "polygon",
                    },
                    size: {
                        random: true,
                        value: 6,
                    },
                    },
                    detectRetina: true,
                }}
            />
            <div className="login-page-form">
                <div className="login-logo">
                    <div className="brand-logo">
                        <img src={LogoName} alt="" />
                    </div>
                </div>
                <p className="login-intro">Sign-In</p>
                <Form
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                >

                    <Form.Item name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Username!',
                            },
                        ]}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder="Username"
                            onChange={
                                e => setValue("username", e.target.value, true)
                            }
                        />
                    </Form.Item>

                    <Form.Item name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Password!',
                            },
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Password"
                            onChange={
                                e => setValue("password", e.target.value, true)
                            }
                        />
                    </Form.Item>

                    <Form.Item>

                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            loading={userStore.loading}
                            block>
                            Sign-In
                        </Button>
                        <div className='register-form-button'>
                            <span>Don't have an account?</span><Link to="/register"> Register.</Link>
                        </div>
                    </Form.Item>

                </Form>
            </div>

            {/* <div className="login-page-risk">
                <div className="container">
                <div className="main-content">
                    <div className="content">
                        <p className="title">RISK WARNING!!!</p>
                        <p className="sub-title">All trading involves risk. Only risk capital you're prepared to lose.</p>
                        <p className="sub-title">
                            All trading involves risk. Only risk capital you're prepared to lose. <br/> The Financial Products offered by the company include Contracts for Difference ('CFDs') and other complex financial products. Trading CFDs carries a high level of risk since leverage can work both to your advantage and disadvantage. As a result, CFDs may not be suitable for all investors because it is possible to lose all of your invested capital. You should never invest money that you cannot afford to lose. Before trading in the complex financial products offered, please ensure to understand the risks involved.
                        </p>
                    </div>
                </div>
                </div>
            </div> */}

            <footer>
                <p>© 2023 DME. All Rights Reserved.</p>
            </footer>

        </div>
    )

}

export default inject('userStore')(observer(Login))