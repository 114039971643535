import React, { useEffect, useState } from "react";
import { Descriptions, Divider, Button, Image, Layout, Table, Tag, Row, Col, Modal, Form, Input } from "antd";
import {
    DownloadOutlined
} from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import moment from "moment-timezone";

import SideNav from "../../../../components/SideNav";
import Navbar from "../../../../components/Navbar";

import "./style.scss"

import { inject, observer } from "mobx-react";
import { cloneDeep } from 'lodash';
import { PDFDownloadLink } from "@react-pdf/renderer";
import PurchasedInvoicePDF from "../../../../components/PurchasedInvoicePDF";
import RentInitialInvoicePDF from "../../../../components/RentInitialInvoicePDF";
import RentFullInvoicePDF from "../../../../components/RentFullInvoicePDF";

const ViewPaymentDetails = ({orderStore, paymentStore}) => {
    const user = JSON.parse(localStorage.getItem("userStore"))

    const {id} = useParams();
    const navigation = useHistory()

    const { Content } = Layout;
    const { TextArea } = Input;
    const [form] = Form.useForm();

    const [isModalOpen, setIsOpenModal] = useState(false)

    useEffect(() => {
        paymentStore.isSuccess = false;
        paymentStore.getPayment(id)
    }, [])

    useEffect(() => {
        if(Object.keys(paymentStore.payment).length > 0) {
            orderStore.getOrder(paymentStore.payment.orderID)
            paymentStore.getPaymentRecords(paymentStore.payment.id)
        }
    }, [paymentStore.payment])

    useEffect(() => {
        if(paymentStore.isSuccess) {
            paymentStore.getPayment(id)
            setTimeout(() => {
                paymentStore.isSuccess = false
            },1000)
        }
    }, [paymentStore.isSuccess])

    const columns = [
        {
            title: 'Product Image',
            dataIndex: 'productImage',
            key: 'productImage',
            render: (productImage) => (
                <Image className="product-image" src={`https://api.alwaysavailabledme.com/${productImage}`}/>
            )
        },
        {
            title: 'Product Name',
            dataIndex: 'productName',
            key: 'productName',
            render: (name, record) => (
                <span>
                    {name}
                    <br/>
                    {record.productSubVariantID != null ? (
                        <>
                            <Tag>{record.variant.name}</Tag>
                            <Tag>{record.subVariant.name}</Tag>
                        </>
                    ): record.productVariantID != null ? (
                        <Tag>{record.variant.name}</Tag>
                    ):<></>}
                </span>
            )
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Price',
            dataIndex: 'productAmount',
            key: 'productAmount',
            render: (price, record) => (
                <span>
                    {record.productSubVariantID != null ? (
                        `$${parseFloat(record.subVariant.price).toFixed(2)}`
                    ): record.productVariantID != null ? (
                        `$${parseFloat(record.variant.price).toFixed(2)}`
                    ):(`$${parseFloat(price).toFixed(2)}`)}
                </span>
            )
        },
    ];

    const onFinish = (values) => {
        if(orderStore.order.type === "BUY") {
            const data = {
                ...values,
                status: "PAID",
                type: "FULL",
                accommodatedBy: user.id, 
                overallTotal: orderStore.order.totalAmount,
                orderStatus: "PREPARING",
                orderType: "BUY",
                orderID: paymentStore.payment.orderID,
            }
            paymentStore.updatePayment(id, data)
            setIsOpenModal(false)
        } else {
            if(paymentStore.payment.status === "FOR_FULL_PAYMENT") {
                const data = {
                    ...values,
                    status: "PAID",
                    type: "FULL",
                    accommodatedBy: user.id, 
                    orderStatus: "COMPLETED",
                    orderType: "RENT",
                    orderID: paymentStore.payment.orderID,
                }
                paymentStore.updatePayment(id, data)
                setIsOpenModal(false)
            } else {
                const data = {
                    ...values,
                    status: "INITIAL_PAYMENT",
                    type: "PARTIAL",
                    accommodatedBy: user.id, 
                    overallTotal: 0,
                    orderStatus: "PREPARING",
                    orderType: "RENT",
                    orderID: paymentStore.payment.orderID,
                }
                paymentStore.updatePayment(id, data)
                setIsOpenModal(false)
            }
            
            
            
        }
    }

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="member-orders-rental-details">
                        <h3>Payment Details</h3>
                        <Divider/>
                        {Object.keys(orderStore.order).length > 0 ? (
                            <div className="content-container">
                                <Descriptions column={2}>
                                    <Descriptions.Item label="Order Number" span={1}>{orderStore.order.orderNumber}</Descriptions.Item>
                                </Descriptions>
                                <Divider/>
                                <h4>Payment Information</h4>
                                {/* <Descriptions bordered column={2}>
                                    <Descriptions.Item label="Status" span={1}>
                                        <Tag 
                                            color={
                                                paymentStore.payment.status === "PENDING"
                                                ? "blue"
                                                : paymentStore.payment.status === "INITIAL_PAYMENT"
                                                ? "lime"
                                                : "green"
                                        }>{paymentStore.payment.status}</Tag>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Total Order Payment" span={1}>${parseFloat(orderStore.order.totalAmount).toFixed(2)}</Descriptions.Item>
                                </Descriptions> */}
                                {/* {(paymentStore.payment.status === "PENDING" && orderStore.order.type === "RENT") ? (
                                    <>
                                        <Divider/>
                                        <Descriptions bordered column={2}>
                                            <Descriptions.Item label="Partial Payment Order Payment" span={1}>${parseFloat(paymentStore.payment.totalPartialPayment).toFixed(2)}</Descriptions.Item>
                                            <Descriptions.Item label="Partial Payment Status" span={1}>
                                                <Tag color="blue">
                                                    UNPAID
                                                </Tag>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Partial Payment Date" span={1}>
                                                -
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </>
                                ):(
                                    (paymentStore.payment.status === "INITIAL_PAYMENT" && orderStore.order.type === "RENT") ? (
                                        paymentStore.payment_records.length > 0 ? (
                                            <>
                                                <Divider/>
                                                <Descriptions bordered column={2}>
                                                    <Descriptions.Item label="Payment Date" span={1}>{moment.utc(paymentStore.payment_records[0].createdAt).format("LLL")}</Descriptions.Item>
                                                    <Descriptions.Item label="Accommodated By" span={1}>{`${paymentStore.payment_records[0].employee?.firstName} ${paymentStore.payment_records[0].employee?.lastName}`}</Descriptions.Item>
                                                    <Descriptions.Item label="Invoice Number" span={1}>{paymentStore.payment_records[0].invoiceNumber}</Descriptions.Item>
                                                    <Descriptions.Item label="Receipt Number" span={1}>{paymentStore.payment_records[0].receiptNumber}</Descriptions.Item>
                                                    <Descriptions.Item label="Note" span={2}>{paymentStore.payment.note}</Descriptions.Item>
                                                    <Descriptions.Item label="Invoice Document" span={1}>
                                                        <Button type="primary" icon={<DownloadOutlined />}>Download Invoice</Button>
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            </>
                                        ):<></>
                                    ): <></>
                                )}
                                {(paymentStore.payment.status === "PAID" && orderStore.order.type === "BUY") ? (
                                    paymentStore.payment_records.length > 0 ? (
                                        <>
                                            <Divider/>
                                            <Descriptions bordered column={2}>
                                                <Descriptions.Item label="Payment Date" span={1}>{moment.utc(paymentStore.payment_records[0].createdAt).format("LLL")}</Descriptions.Item>
                                                <Descriptions.Item label="Accommodated By" span={1}>{`${paymentStore.payment_records[0].employee?.firstName} ${paymentStore.payment_records[0].employee?.lastName}`}</Descriptions.Item>
                                                <Descriptions.Item label="Invoice Number" span={1}>{paymentStore.payment_records[0].invoiceNumber}</Descriptions.Item>
                                                <Descriptions.Item label="Receipt Number" span={1}>{paymentStore.payment_records[0].receiptNumber}</Descriptions.Item>
                                                <Descriptions.Item label="Note" span={2}>{paymentStore.payment.note}</Descriptions.Item>
                                                <Descriptions.Item label="Invoice Document" span={1}>
                                                    <Button type="primary" icon={<DownloadOutlined />}>Download Invoice</Button>
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </>
                                    ):<></>
                                ):<></>} */}
                                {(orderStore.order.status !== "PENDING" && orderStore.order.status !== "FOR_PAYMENT") ? (
                                    <>
                                        <Descriptions bordered column={2}>
                                            <Descriptions.Item label="Status" span={1}>
                                                <Tag 
                                                    color={
                                                        paymentStore.payment.status === "PENDING"
                                                        ? "blue"
                                                        : paymentStore.payment.status === "INITIAL_PAYMENT"
                                                        ? "lime"
                                                        : "green"
                                                }>{paymentStore.payment.status}</Tag>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Total Order Payment" span={1}>${parseFloat(orderStore.order.totalAmount).toFixed(2)}</Descriptions.Item>
                                        </Descriptions>
                                        {paymentStore.payment.status !== "PENDING" ? (
                                            paymentStore.payment_records.length > 0 ? (
                                                <>
                                                    <Divider/>
                                                    <Descriptions bordered column={2}>
                                                        <Descriptions.Item label="Accommodated By" span={2}>{`${paymentStore.payment_records[0].employee?.firstName} ${paymentStore.payment_records[0].employee?.lastName}`}</Descriptions.Item>
                                                        <Descriptions.Item label="Payment Date" span={1}>{moment.utc(paymentStore.payment_records[0].createdAt).format("LLL")}</Descriptions.Item>
                                                        <Descriptions.Item label="Payment Amount" span={1}>${parseFloat(paymentStore.payment.totalPartialPayment).toFixed(2)}</Descriptions.Item>
                                                        <Descriptions.Item label="Invoice Number" span={1}>{paymentStore.payment_records[0].invoiceNumber}</Descriptions.Item>
                                                        <Descriptions.Item label="Receipt Number" span={1}>{paymentStore.payment_records[0].receiptNumber}</Descriptions.Item>
                                                        <Descriptions.Item label="Note" span={2}>{paymentStore.payment.note}</Descriptions.Item>
                                                        <Descriptions.Item label="Invoice Document" span={1}>
                                                            {orderStore.order.type === "RENT" ? (
                                                                <PDFDownloadLink document={<RentInitialInvoicePDF order={orderStore.order} payment={paymentStore.payment} paymentRecords={paymentStore.payment_records} />} fileName="INITIAL_PAYMENT_INVOICE.pdf">
                                                                    <Button type="primary" icon={<DownloadOutlined />}>Download Invoice</Button>
                                                                </PDFDownloadLink>
                                                            ):(
                                                                <PDFDownloadLink document={<PurchasedInvoicePDF order={orderStore.order} payment={paymentStore.payment} paymentRecords={paymentStore.payment_records} />} fileName="INVOICE.pdf">
                                                                    <Button type="primary" icon={<DownloadOutlined />}>Download Invoice</Button>
                                                                </PDFDownloadLink>
                                                            )}
                                                            
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    {((paymentStore.payment.status === "FOR_FULL_PAYMENT" | paymentStore.payment.status === "PAID") && orderStore.order.type === "RENT") ? (
                                                        <>
                                                            <Divider/>
                                                            <Descriptions bordered column={3}>
                                                                <Descriptions.Item label="Due Date" span={3}>{moment.utc(paymentStore.payment.dueDate).format("LL")}</Descriptions.Item>
                                                                <Descriptions.Item label="Total Extra charges" span={3}>${parseFloat(paymentStore.payment.extraCharges).toFixed(2)}</Descriptions.Item>
                                                                <Descriptions.Item label="OverAll Total Payment" span={3}>${parseFloat(paymentStore.payment.overallTotal).toFixed(2)}</Descriptions.Item>
                                                                <Descriptions.Item label="Balance" span={3}>${((parseFloat(paymentStore.payment.totalOrderPayment) - parseFloat(paymentStore.payment.totalPartialPayment)) + parseFloat(paymentStore.payment.extraCharges)).toFixed(2)}</Descriptions.Item>
                                                            </Descriptions>
                                                            {paymentStore.payment_records.length === 2 ? (
                                                                <>
                                                                    <Divider/>
                                                                    <Descriptions bordered column={2}>
                                                                    
                                                                        <Descriptions.Item label="Accommodated By" span={2}>{`${paymentStore.payment_records[1].employee?.firstName} ${paymentStore.payment_records[1].employee?.lastName}`}</Descriptions.Item>
                                                                        <Descriptions.Item label="Payment Date" span={1}>{moment.utc(paymentStore.payment_records[1].createdAt).format("LLL")}</Descriptions.Item>
                                                                        <Descriptions.Item label="Payment Amount" span={1}>${((parseFloat(paymentStore.payment.totalOrderPayment) - parseFloat(paymentStore.payment.totalPartialPayment)) + parseFloat(paymentStore.payment.extraCharges)).toFixed(2)}</Descriptions.Item>
                                                                        <Descriptions.Item label="Invoice Number" span={1}>{paymentStore.payment_records[1].invoiceNumber}</Descriptions.Item>
                                                                        <Descriptions.Item label="Receipt Number" span={1}>{paymentStore.payment_records[1].receiptNumber}</Descriptions.Item>
                                                                        <Descriptions.Item label="Note" span={2}>{paymentStore.payment.noteForFull}</Descriptions.Item>
                                                                        <Descriptions.Item label="Invoice Document" span={1}>
                                                                            <PDFDownloadLink document={<RentFullInvoicePDF order={orderStore.order} payment={paymentStore.payment} paymentRecords={paymentStore.payment_records} />} fileName="INVOICE.pdf">
                                                                                <Button type="primary" icon={<DownloadOutlined />}>Download Invoice</Button>
                                                                            </PDFDownloadLink>
                                                                        </Descriptions.Item>
                                                                    </Descriptions>
                                                                </>
                                                            ):<></>}
                                                        </>
                                                    ):<></>}
                                                </>
                                            ):<></>
                                        ):<></>}
                                    </>
                                ): (
                                    orderStore.order.status === "PENDING" ? (
                                        <Descriptions bordered column={2}>
                                            <Descriptions.Item label="Status" span={1}>
                                                <Tag color="blue">PENDING</Tag>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Total Order Payment" span={1}>${parseFloat(orderStore.order.totalAmount).toFixed(2)}</Descriptions.Item>
                                        </Descriptions>
                                    ): (
                                        <>
                                            <Descriptions bordered column={2}>
                                                <Descriptions.Item label="Status" span={1}>
                                                    <Tag color="blue">PENDING</Tag>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Total Order Payment" span={1}>${parseFloat(orderStore.order.totalAmount).toFixed(2)}</Descriptions.Item>
                                            </Descriptions>
                                            <Divider/>
                                            <Descriptions bordered column={2}>
                                                <Descriptions.Item label="Partial Payment Order Payment" span={1}>${parseFloat(paymentStore.payment.totalPartialPayment).toFixed(2)}</Descriptions.Item>
                                                <Descriptions.Item label="Partial Payment Status" span={1}>
                                                    <Tag color="blue">UNPAID</Tag>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Partial Payment Date" span={1}>
                                                    -
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </>
                                    )
                                )}
                                {/* {(paymentStore.payment.status !== "PENDING" && orderStore.order.status !== "FOR_PAYMENT") ? (
                                    <>
                                        <Divider/>
                                        <Descriptions bordered column={3}>
                                            <Descriptions.Item label="Partial Payment Order Payment" span={1.5}>$200.00</Descriptions.Item>
                                            <Descriptions.Item label="Partial Payment Status" span={1.5}>
                                                <Tag color="blue">UNPAID</Tag>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Partial Payment Date" span={1.5}>
                                                -
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Download Partial Payment Invoice" span={1.5}>
                                                <Button type="primary" icon={<DownloadOutlined />}>Download</Button>
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider/>
                                        <Descriptions bordered column={3}>
                                            <Descriptions.Item label="Due Date" span={1.5}>July 15, 2023</Descriptions.Item>
                                            <Descriptions.Item label="Full Payment Status" span={3}>
                                                <Tag color="blue">UNPAID</Tag>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Total Over Due Months" span={1.5}>0</Descriptions.Item>
                                            <Descriptions.Item label="Total Over Due Amount" span={1.5}>$0.00</Descriptions.Item>
                                            <Descriptions.Item label="Total Damaged/Lost Products" span={1.5}>0</Descriptions.Item>
                                            <Descriptions.Item label="Total Damaged/Lost Products Amount" span={1.5}>$0.00</Descriptions.Item>
                                            <Descriptions.Item label="Total Extra charges" span={3}>$0.00</Descriptions.Item>
                                            <Descriptions.Item label="Balance" span={3}>$200.00</Descriptions.Item>
                                            <Descriptions.Item label="OverAll Total Payment" span={3}>$200.00</Descriptions.Item>
                                            <Descriptions.Item label="Full Payment Date" span={1.5}>
                                                -
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Download Full Payment Invoice" span={1.5}>
                                                <Button type="primary" icon={<DownloadOutlined />}>Download</Button>
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </>
                                ):<></>} */}
                                <Divider/>
                                <h4>Products</h4>
                                <Table
                                    columns={columns}
                                    // expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
                                    dataSource={cloneDeep(orderStore.order.products)}
                                    pagination={false}
                                />
                                <Divider/>
                                <h4>Customer Information</h4>
                                <Descriptions column={2} bordered>
                                    <Descriptions.Item label="Customer Number" span={2}>{paymentStore.payment.customer?.idNumber}</Descriptions.Item>
                                    <Descriptions.Item label="First Name" span={1}>{paymentStore.payment.customer?.firstName}</Descriptions.Item>
                                    <Descriptions.Item label="Middle Name" span={1}>{paymentStore.payment.customer?.middleName}</Descriptions.Item>
                                    <Descriptions.Item label="Last Name" span={1}>{paymentStore.payment.customer?.lastName}</Descriptions.Item>
                                    <Descriptions.Item label="Suffix" span={1}>{paymentStore.payment.customer?.suffix}</Descriptions.Item>
                                    <Descriptions.Item label="Contact Number" span={1}>{paymentStore.payment.customer?.contactNumber}</Descriptions.Item>
                                    <Descriptions.Item label="Email" span={1}>{paymentStore.payment.customer?.email}</Descriptions.Item>
                                </Descriptions>
                            </div>
                        ):<></>}
                        <Divider/>
                        <Row justify="end" gutter={10}>
                            <Col>
                                {paymentStore.payment.status === "PENDING" ? (
                                    <Button 
                                        type="primary" 
                                        onClick={() => setIsOpenModal(true)}
                                    >
                                        {orderStore.order.type === "BUY" ? "Mark as paid":  "Pay Partial Payment"}
                                    </Button>
                                ):<></>}
                                {paymentStore.payment.status === "FOR_FULL_PAYMENT" ? (
                                    <Button 
                                        type="primary" 
                                        onClick={() => setIsOpenModal(true)}
                                    >
                                        Complete Payment
                                    </Button>
                                ):<></>}
                                <Modal
                                    title="Mark as Paid" 
                                    visible={isModalOpen} 
                                    onCancel={() => setIsOpenModal(false)}
                                    onOk={() => form.submit()}
                                >
                                    <Form
                                        form={form}
                                        layout="vertical"
                                        onFinish={onFinish}
                                    >
                                        <Form.Item
                                            name={"invoiceNumber"}
                                            label="Invoice Number"
                                            rules={[{ required: true, message: 'Please enter invoice number!' }]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item
                                            name={"receiptNumber"}
                                            label="Receipt Number"
                                            rules={[{ required: true, message: 'Please enter receipt number!' }]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item
                                            name={"note"}
                                            label="Note"
                                        >
                                            <TextArea />
                                        </Form.Item>
                                    </Form>
                                </Modal>
                            </Col>
                            <Col>
                                <Button onClick={() => navigation.goBack()}> Back</Button>
                            </Col>
                        </Row>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject("orderStore","paymentStore")(observer(ViewPaymentDetails));