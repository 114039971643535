import React, { useEffect, useState } from "react";
import { Descriptions, Divider, Button, Image, Layout, Table, Tag, Timeline, Badge, List, Row, Col, Modal, Form, Input, message, notification } from "antd";
import {
    DownloadOutlined
} from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import moment from "moment-timezone";

import SideNav from "../../../../components/SideNav";
import Navbar from "../../../../components/Navbar";

import "./style.scss"

import { inject, observer } from "mobx-react";
import { cloneDeep } from 'lodash';
import { PDFDownloadLink } from "@react-pdf/renderer";
import RentInitialInvoicePDF from "../../../../components/RentInitialInvoicePDF";
import RentFullInvoicePDF from "../../../../components/RentFullInvoicePDF";

const ViewRentalDetails = ({orderStore, paymentStore}) => {
    const {id} = useParams();

    const { Content } = Layout;
    const { TextArea } = Input;
    const [form] = Form.useForm()
    const [formReplace] = Form.useForm()

    const [products, setProducts] = useState(false)
    const [requests, setRequests] = useState([])
    const [isRequestingToFix, setIsRequestingToFix] = useState(false)
    const [isRequestingForReplace, setIsRequestingForReplace] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState([])

    useEffect(() => {
        orderStore.isSuccess = false
        orderStore.getOrder(id)
        paymentStore.getPaymentByOrdeID(id)
        orderStore.getOrderRequests(id)
    }, [])

    useEffect(() => {
        let prods = []
        if(Object.keys(orderStore.order).length > 0){
            orderStore.order.products.forEach((product) => {
                product.key = product.id

                prods = [...prods, product]
            });

        }

        setProducts(prods)
    }, [orderStore.order])

    useEffect(() => {
        let requests = []
        if(orderStore.ordersRequests.length > 0){
            orderStore.ordersRequests.forEach((ordersRequest) => {
                ordersRequest.key = ordersRequest.id

                requests = [...requests, ordersRequest]
            });

        }

        setRequests(requests)
    }, [orderStore.ordersRequests])

    useEffect(() => {
        if(Object.keys(paymentStore.payment).length > 0) {
            paymentStore.getPaymentRecords(paymentStore.payment?.id)
        }
    }, [paymentStore.payment])

    useEffect(() => {
        if(orderStore.isSuccess){
            setIsRequestingToFix(false)
            setIsRequestingForReplace(false)

            notification.success({
                top: 120,
                message: 'Request Successfully Submitted!',
            });

            orderStore.getOrder(id)
            paymentStore.getPaymentByOrdeID(id)
            orderStore.getOrderRequests(id)
            
            setTimeout(() => {
                orderStore.isSuccess = false
            }, 1000)
        }
       
    }, [orderStore.isSuccess])

    // const onOk = () => {
    //     if(selectedProducts.length > 0) {
    //         form.submit()
    //     } else {
    //         message.error("Select at least 1 product!")
    //     }
    // }

    const onOk = () => {
        if(selectedProducts.length > 0) {
            formReplace.submit()
        } else {
            message.error("Select at least 1 product!")
        }
    }

    const onFinish = (values) => {
        const data = {
            type: "FIX",
            orderID: orderStore.order.id,
            products: selectedProducts,
            note: values.note ?? "",
        }

        orderStore.createOrderRequest(data)
    }

    const onFinishForReplacement = (values) => {
        const data = {
            type: "REPLACE",
            orderID: orderStore.order.id,
            products: selectedProducts,
            note: values.note ?? "",
        }

        orderStore.createOrderRequest(data)
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          setSelectedProducts(selectedRows)
        },
        getCheckboxProps: (record) => ({
          disabled: record.name === 'Disabled User',
          // Column configuration not to be checked
          name: record.name,
        }),
      };

    const columns = [
        {
            title: 'Product Image',
            dataIndex: 'productImage',
            key: 'productImage',
            render: (productImage) => (
                <Image className="product-image" src={`https://api.alwaysavailabledme.com/${productImage}`}/>
            )
        },
        {
            title: 'Product Name',
            dataIndex: 'productName',
            key: 'productName',
            render: (name, record) => (
                <span>
                    {name}
                    <br/>
                    {record.productSubVariantID != null ? (
                        <>
                            <Tag>{record.variant.name}</Tag>
                            <Tag>{record.subVariant.name}</Tag>
                        </>
                    ): record.productVariantID != null ? (
                        <Tag>{record.variant.name}</Tag>
                    ):<></>}
                </span>
            )
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Rental Months',
            dataIndex: 'noOfRentalDays',
            key: 'noOfRentalDays',
        },
        {
            title: 'Price',
            dataIndex: 'productAmount',
            key: 'productAmount',
            render: (price, record) => (
                <span>
                    {record.productSubVariantID != null ? (
                        `$${parseFloat(record.subVariant.price).toFixed(2)}`
                    ): record.productVariantID != null ? (
                        `$${parseFloat(record.variant.price).toFixed(2)}`
                    ):(`$${parseFloat(price).toFixed(2)}`)}
                </span>
            )
        },
    ];

    const columnsRequest = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => (
                <Tag
                    color={
                        status === "PENDING" 
                        ? "blue" 
                        : status === "ON_PROGRESS" 
                        ?  "orange"
                        : status === "DONE" 
                        ?  "green"
                        : "red"
                    }
                >
                    {status}
                </Tag>
            )
        },
        {
            title: 'Request Note',
            dataIndex: 'userNote',
            key: 'userNote',
        },
    ];

    const expandedRowRender = (row) => {
        const columns = [
            {
                title: 'Product Image',
                dataIndex: 'product',
                key: 'productImage',
                render: (product) => (
                    <Image className="product-image" src={`https://api.alwaysavailabledme.com/${product.productImage}`}/>
                )
            },
            {
                title: 'Product Name',
                dataIndex: 'product',
                key: 'productName',
                render: (product, record) => (
                    <span>
                        {product.productName}
                        <br/>
                        {record.productSubVariantID != null ? (
                            <>
                                <Tag>{record.product.variant.name}</Tag>
                                <Tag>{record.product.subVariant.name}</Tag>
                            </>
                        ): record.product.productVariantID != null ? (
                            <Tag>{record.product.variant.name}</Tag>
                        ):<></>}
                    </span>
                )
            },
        ];

        return <Table columns={columns} dataSource={cloneDeep(row.products)} pagination={false} />;
    };
    

    return (
        <Layout>
            <SideNav />
            <Layout>
                <Navbar />
                <Content>
                    <div className="member-orders-rental-details">
                        <h3>Rental Details</h3>
                        <Divider/>
                        {Object.keys(orderStore.order).length > 0 ? (
                            <div className="content-container">
                                <Descriptions column={3}>
                                    <Descriptions.Item label="Order Number" span={1}>{orderStore.order.orderNumber}</Descriptions.Item>
                                    <Descriptions.Item label="Date" span={1}>{moment.utc(orderStore.order.createdAt).format('LLL')}</Descriptions.Item>
                                    <Descriptions.Item label="Status" span={1}>
                                        <Tag 
                                            color={
                                                orderStore.order.status === "PENDING" 
                                                ? "blue" 
                                                : (orderStore.order.status === "FOR_PAYMENT" || orderStore.order.status === "FOR_FULL_PAYMENT")
                                                ?  "geekblue"
                                                : (orderStore.order.status === "PREPARING" || orderStore.order.status === "FOR_RETURN")
                                                ?  "orange"
                                                : (orderStore.order.status === "TO_SHIP" || orderStore.order.status === "FOR_PICK_UP") 
                                                ?  "lime"
                                                : orderStore.order.status === "OUT_FOR_DELIVERY" 
                                                ?  "cyan"
                                                : (orderStore.order.status === "DELIVERED" || orderStore.order.status === "COMPLETED") 
                                                ?  "green"
                                                : "red"
                                            }
                                        >{orderStore.order.status}</Tag>
                                    </Descriptions.Item>
                                </Descriptions>
                                <Divider/>
                                <h4>Order Tracker</h4>
                                <div className="order-tracker-container">
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={orderStore.order.orderTracker}
                                        renderItem={(item, index) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={index === (orderStore.order.orderTracker.length - 1) ? (item.status === "COMPLETED" ? <Badge status="success" /> : <Badge status="processing" />) : <Badge status="success" />}
                                                title={item.title}
                                                description={moment.utc(item.createdAt).format('LLL')}
                                            />
                                        </List.Item>
                                    )}
                                    />
                                </div>
                                <Divider/>
                                <h4>Shipping Information</h4>
                                <Descriptions column={1} layout="vertical" bordered>
                                    <Descriptions.Item label="Shipping Address">{`${orderStore.order.deliveryAddress} ${orderStore.order.deliveryCity} ${orderStore.order.deliveryState} ${orderStore.order.deliveryZipcode}`}</Descriptions.Item>
                                    <Descriptions.Item label="Note">{orderStore.order.note}</Descriptions.Item>
                                </Descriptions>
                                <Divider/>
                                <h4>Products Information</h4>
                                <Table
                                    columns={columns}
                                    // expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
                                    dataSource={cloneDeep(orderStore.order.products)}
                                    pagination={false}
                                />
                                <Divider/>
                                <h4>Payment Information</h4>
                                {(orderStore.order.status !== "PENDING" && orderStore.order.status !== "FOR_PAYMENT") ? (
                                    <>
                                        <Descriptions bordered column={2}>
                                            <Descriptions.Item label="Status" span={1}>
                                                <Tag 
                                                    color={
                                                        paymentStore.payment.status === "PENDING"
                                                        ? "blue"
                                                        : paymentStore.payment.status === "INITIAL_PAYMENT"
                                                        ? "lime"
                                                        : "green"
                                                }>{paymentStore.payment.status}</Tag>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Total Order Payment" span={1}>${parseFloat(orderStore.order.totalAmount).toFixed(2)}</Descriptions.Item>
                                        </Descriptions>
                                        {paymentStore.payment.status !== "PENDING" ? (
                                            paymentStore.payment_records.length > 0 ? (
                                                <>
                                                    <Divider/>
                                                    <Descriptions bordered column={2}>
                                                        <Descriptions.Item label="Accommodated By" span={2}>{`${paymentStore.payment_records[0].employee?.firstName} ${paymentStore.payment_records[0].employee?.lastName}`}</Descriptions.Item>
                                                        <Descriptions.Item label="Payment Date" span={1}>{moment.utc(paymentStore.payment_records[0].createdAt).format("LLL")}</Descriptions.Item>
                                                        <Descriptions.Item label="Payment Amount" span={1}>${parseFloat(paymentStore.payment.totalPartialPayment).toFixed(2)}</Descriptions.Item>
                                                        <Descriptions.Item label="Invoice Number" span={1}>{paymentStore.payment_records[0].invoiceNumber}</Descriptions.Item>
                                                        <Descriptions.Item label="Receipt Number" span={1}>{paymentStore.payment_records[0].receiptNumber}</Descriptions.Item>
                                                        <Descriptions.Item label="Note" span={2}>{paymentStore.payment.note}</Descriptions.Item>
                                                        <Descriptions.Item label="Invoice Document" span={1}>
                                                            <PDFDownloadLink document={<RentInitialInvoicePDF order={orderStore.order} payment={paymentStore.payment} paymentRecords={paymentStore.payment_records} />} fileName="INITIAL_PAYMENT_INVOICE.pdf">
                                                                <Button type="primary" icon={<DownloadOutlined />}>Download Invoice</Button>
                                                            </PDFDownloadLink>
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    {(paymentStore.payment.status === "FOR_FULL_PAYMENT" | paymentStore.payment.status === "PAID") ? (
                                                        <>
                                                            <Divider/>
                                                            <Descriptions bordered column={3}>
                                                                <Descriptions.Item label="Due Date" span={3}>{moment.utc(paymentStore.payment.dueDate).format("LL")}</Descriptions.Item>
                                                                <Descriptions.Item label="Total Extra charges" span={3}>${parseFloat(paymentStore.payment.extraCharges).toFixed(2)}</Descriptions.Item>
                                                                <Descriptions.Item label="OverAll Total Payment" span={3}>${parseFloat(paymentStore.payment.overallTotal).toFixed(2)}</Descriptions.Item>
                                                                <Descriptions.Item label="Balance" span={3}>${((parseFloat(paymentStore.payment.totalOrderPayment) - parseFloat(paymentStore.payment.totalPartialPayment)) + parseFloat(paymentStore.payment.extraCharges)).toFixed(2)}</Descriptions.Item>
                                                            </Descriptions>
                                                            {paymentStore.payment_records.length === 2 ? (
                                                                <>
                                                                    <Divider/>
                                                                    <Descriptions bordered column={2}>
                                                                    
                                                                        <Descriptions.Item label="Accommodated By" span={2}>{`${paymentStore.payment_records[1].employee?.firstName} ${paymentStore.payment_records[1].employee?.lastName}`}</Descriptions.Item>
                                                                        <Descriptions.Item label="Payment Date" span={1}>{moment.utc(paymentStore.payment_records[1].createdAt).format("LLL")}</Descriptions.Item>
                                                                        <Descriptions.Item label="Payment Amount" span={1}>${((parseFloat(paymentStore.payment.totalOrderPayment) - parseFloat(paymentStore.payment.totalPartialPayment)) + parseFloat(paymentStore.payment.extraCharges)).toFixed(2)}</Descriptions.Item>
                                                                        <Descriptions.Item label="Invoice Number" span={1}>{paymentStore.payment_records[1].invoiceNumber}</Descriptions.Item>
                                                                        <Descriptions.Item label="Receipt Number" span={1}>{paymentStore.payment_records[1].receiptNumber}</Descriptions.Item>
                                                                        <Descriptions.Item label="Note" span={2}>{paymentStore.payment.noteForFull}</Descriptions.Item>
                                                                        <Descriptions.Item label="Invoice Document" span={1}>
                                                                            <PDFDownloadLink document={<RentFullInvoicePDF order={orderStore.order} payment={paymentStore.payment} paymentRecords={paymentStore.payment_records} />} fileName="FULL_PAYMENT_INVOICE.pdf">
                                                                                <Button type="primary" icon={<DownloadOutlined />}>Download Invoice</Button>
                                                                            </PDFDownloadLink>
                                                                        </Descriptions.Item>
                                                                    </Descriptions>
                                                                </>
                                                            ):<></>}
                                                        </>
                                                    ):<></>}
                                                </>
                                            ):<></>
                                        ):<></>}
                                    </>
                                ): (
                                    orderStore.order.status === "PENDING" ? (
                                        <Descriptions bordered column={2}>
                                            <Descriptions.Item label="Status" span={1}>
                                                <Tag color="blue">PENDING</Tag>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Total Order Payment" span={1}>${parseFloat(orderStore.order.totalAmount).toFixed(2)}</Descriptions.Item>
                                        </Descriptions>
                                    ): (
                                        <>
                                            <Descriptions bordered column={2}>
                                                <Descriptions.Item label="Status" span={1}>
                                                    <Tag color="blue">PENDING</Tag>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Total Order Payment" span={1}>${parseFloat(orderStore.order.totalAmount).toFixed(2)}</Descriptions.Item>
                                            </Descriptions>
                                            <Divider/>
                                            <Descriptions bordered column={2}>
                                                <Descriptions.Item label="Partial Payment Order Payment" span={1}>${parseFloat(paymentStore.payment.totalPartialPayment).toFixed(2)}</Descriptions.Item>
                                                <Descriptions.Item label="Partial Payment Status" span={1}>
                                                    <Tag color="blue">UNPAID</Tag>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Partial Payment Date" span={1}>
                                                    -
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </>
                                    )
                                )}
                                {requests.length > 0 ? (
                                    <>
                                        <Divider/>
                                        <h4>Requests</h4>
                                        <Table 
                                            expandable={{
                                                expandedRowRender: (row) => expandedRowRender(row)
                                            }}
                                            columns={columnsRequest}
                                            dataSource={cloneDeep(requests)}
                                        />
                                        
                                    </>
                                ):<></>}
                                {orderStore.order.status === "ON_GOING" ? (
                                    <>
                                        <Divider/>
                                        <Row gutter={10} justify={"end"}>
                                            <Col>
                                                <Button type="primary" onClick={() => setIsRequestingToFix(true)}>Request For Fix Products</Button>
                                                <Modal
                                                    visible={isRequestingToFix}
                                                    title={`Create Request for Fixing`}
                                                    onCancel={() => setIsRequestingToFix(false)}
                                                    onOk={onOk}
                                                    width={1000}
                                                >
                                                    <Table 
                                                        rowSelection={{
                                                            type: "checkbox",
                                                            ...rowSelection,
                                                        }}
                                                        columns={columns}
                                                        dataSource={cloneDeep(products)}
                                                    />
                                                    <Divider/>
                                                    <Form 
                                                        layout="vertical"
                                                        form={form}
                                                        onFinish={onFinish}
                                                    >
                                                        <Form.Item 
                                                            name="note"
                                                            label="Add Note"
                                                        >
                                                            <TextArea
                                                                placeholder="Add Note for your request..."
                                                            />
                                                        </Form.Item>
                                                    </Form>
                                                </Modal>
                                            </Col>
                                            <Col>
                                                <Button type="primary" onClick={() => setIsRequestingForReplace(true)}>Request For Replacement Products</Button>
                                                <Modal
                                                    visible={isRequestingForReplace}
                                                    title={`Create Request for Replacement`}
                                                    onCancel={() => setIsRequestingForReplace(false)}
                                                    onOk={onOk}
                                                    width={1000}
                                                >
                                                    <Table 
                                                        rowSelection={{
                                                            type: "checkbox",
                                                            ...rowSelection,
                                                        }}
                                                        columns={columns}
                                                        dataSource={cloneDeep(products)}
                                                    />
                                                    <Divider/>
                                                    <Form 
                                                        layout="vertical"
                                                        form={formReplace}
                                                        onFinish={onFinishForReplacement}
                                                    >
                                                        <Form.Item 
                                                            name="note"
                                                            label="Add Note"
                                                        >
                                                            <TextArea
                                                                placeholder="Add Note for your request..."
                                                            />
                                                        </Form.Item>
                                                    </Form>
                                                </Modal>
                                            </Col>
                                        </Row>
                                    </>
                                ): <></>}
                                
                            </div>
                        ):<></>}
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default inject("orderStore", "paymentStore")(observer(ViewRentalDetails));